import { useEffect, useState } from 'react'
import App, { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { setCookie } from 'nookies'
import { Router, useRouter } from 'next/router'
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'swiper/swiper.scss'
import { GetUserQueryHookResult, SignInMutation } from '~/@types/schemas'
import { AppContextType, NextAppContext } from '~/@types/models'
import { api } from '~/utils/api'
import { parseJwt } from '~/utils/helpers'
import {
  addApolloState,
  APOLLO_STATE_PROP_NAME,
  useApollo,
} from '~/utils/api/apollo-client'
import { createTokenManager } from '~/utils/api/token-manager'
import { GlobalStyle } from '~/styles/global'
import { Notifications } from '~/components/notifications'
import { AppContextProvider } from '~/context/app-context'
import { Home } from '~/containers/auth'
import { config } from '~/utils/config'
import { GoogleTagManager, trackGAEvent } from '~/components/google-tag-manager'
import { VodContextProvider } from '~/context/vod-context'
import { ErrorBoundary } from '~/components/error-boundary'
import { TrueDarkMode } from '~/components/true-dark-mode'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', NProgress.start)
Router.events.on('routeChangeComplete', NProgress.done)
Router.events.on('routeChangeError', NProgress.done)

const MusicContextProvider = dynamic(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('~/context/music-context').then(mod => mod.MusicContextProvider),
  { ssr: false }
)

const AppVersion = dynamic(
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  () => import('~/components/app-version').then(mod => mod.AppVersion),
  { ssr: false }
)

type Props = {
  user: SignInMutation['signIn']['user'] | null
  error: string
  pathname: string
}

type AppApolloProviderProps = {
  children: React.ReactNode
  apolloCache?: NormalizedCacheObject
}

const AppApolloProvider = ({
  children,
  apolloCache = {},
}: AppApolloProviderProps) => {
  const client = useApollo(apolloCache, createTokenManager())

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const MyApp = (props: Props & AppProps) => {
  const [user, setUser] = useState<SignInMutation['signIn']['user']>(
    props.user ?? null
  )

  const [notifications, setNotifications] = useState<NotificationType[]>([])
  const router = useRouter()

  const updateUser = (result: SignInMutation['signIn']['user']) => {
    setUser(result)
  }

  const login = (result: SignInMutation['signIn']) => {
    const { accessToken, refreshToken } = result.session

    setCookie(null, 'accessToken', accessToken, config.cookieSettings)
    setCookie(null, 'refreshToken', refreshToken, config.cookieSettings)
    setUser(result.user)
    // Event name must match the event name used by MT:
    // https://docs.google.com/spreadsheets/d/1j2sKa47E-lBONWuQ4py-ssjHV1sRahaE80P-dI3FKpM/edit
    trackGAEvent('sign_up_login_success')
  }

  const logout = () => {
    setCookie(null, 'accessToken', '', { ...config.cookieSettings, maxAge: 0 })
    setCookie(null, 'refreshToken', '', { ...config.cookieSettings, maxAge: 0 })
    setUser(null)
  }

  const addNotification: AppContextType['addNotification'] = arg => {
    if (
      typeof arg === 'object' &&
      'type' in arg &&
      arg.type === 'debug' &&
      process.env.NODE_ENV !== 'development'
    ) {
      return
    }

    setNotifications(prevState => [
      ...prevState,
      {
        id: Date.now(),
        ...(typeof arg === 'string'
          ? { text: arg }
          : 'message' in arg
          ? { text: arg.message, type: 'error' }
          : arg),
      },
    ])
  }

  const removeNotification = (id: number) =>
    setNotifications(prevState => prevState.filter(i => i.id !== id))

  const fetchUserDetails = async () => {
    try {
      const u = await api(createTokenManager()).getUser(user.id)

      setUser(u)
    } catch (err) {
      console.log('error in fetchUserDetails', err)
    }
  }

  useEffect(() => {
    if (props.error) {
      addNotification(props.error)
    }

    if (user) {
      void fetchUserDetails()
    }
  }, [])

  const { Component, pageProps } = props

  return (
    <AppApolloProvider apolloCache={props[APOLLO_STATE_PROP_NAME]}>
      <GoogleTagManager>
        <AppContextProvider
          value={{
            user,
            notifications,
            updateUser,
            login,
            logout,
            addNotification,
            removeNotification,
          }}
        >
          <VodContextProvider>
            <MusicContextProvider>
              <GlobalStyle />
              <ErrorBoundary key={router.pathname}>
                {user || router.pathname.startsWith('/gympass') ? (
                  <Component {...pageProps} />
                ) : (
                  <Home />
                )}
              </ErrorBoundary>
              <TrueDarkMode />
              <Notifications />
              <AppVersion user={user} />
            </MusicContextProvider>
          </VodContextProvider>
        </AppContextProvider>
      </GoogleTagManager>
    </AppApolloProvider>
  )
}

MyApp.getInitialProps = async (appContext: NextAppContext) => {
  const { ctx } = appContext
  const tokenManager = createTokenManager(ctx)

  appContext.ctx.api = api(tokenManager)

  const incompleteProfilePath = '/onboarding'
  let user: GetUserQueryHookResult['data']['getUser'] = null
  let error: string

  const redirectToPath = (redirectLocation: string) => {
    if (typeof appContext.ctx.res?.writeHead === 'function') {
      appContext.ctx.res.writeHead(302, {
        Location: redirectLocation,
      })

      appContext.ctx.res.end()
    }
  }

  const handleInitialUserLogin = async () => {
    const { accessToken } = tokenManager.get()

    if (accessToken) {
      const { userID } = parseJwt(accessToken)

      if (appContext.router.pathname === '/') {
        redirectToPath('/dashboard')
      } else {
        user = await appContext.ctx.api.getUser(userID)

        if (
          !user.username &&
          appContext.router.pathname !== incompleteProfilePath
        ) {
          redirectToPath(incompleteProfilePath)
        } else if (
          user.username &&
          appContext.router.pathname === incompleteProfilePath
        ) {
          redirectToPath('/dashboard')
        }
      }
    } else if (
      appContext.router.pathname !== '/' &&
      !appContext.router.pathname.startsWith('/gympass')
    ) {
      redirectToPath('/')
    }
  }

  if (appContext.ctx.req) {
    try {
      await handleInitialUserLogin()
    } catch (err) {
      error = err.message
      tokenManager.destroyTokens()
      redirectToPath('/')
    }
  }

  const appProps = await App.getInitialProps(appContext)

  return addApolloState(appContext.ctx.api.apolloClient, {
    ...appProps,
    user,
    error,
  })
}

export default MyApp
