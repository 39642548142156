import { ChangeEvent, FC } from 'react'
import { Radio } from '~/components/form-elements/radio'
import { RadioList, Item, ItemContent } from './styled'

type Props = {
  label: string
  value: string
  name: string
  checked: boolean
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void
  description?: string
}

const ListItem: FC<Props> = ({
  label,
  description,
  value,
  name,
  checked,
  onChange,
}) => (
  <Item>
    <label>
      <ItemContent>
        <h3>{label}</h3>
        {!!description && <p>{description}</p>}
      </ItemContent>
      <Radio name={name} value={value} checked={checked} onChange={onChange} />
    </label>
  </Item>
)

export { ListItem, RadioList }
