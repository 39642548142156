import { FC, SVGAttributes } from 'react'
import { Svg } from '~/components/svg'

export const IconArrowLeft: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.41412 13.0001L12.707 18.293L11.2928 19.7072L4.2928 12.7072L3.58569 12.0001L4.2928 11.293L11.2928 4.29297L12.707 5.70718L7.41412 11.0001H18.9999V13.0001H7.41412Z"
      fill="currentColor"
    />
  </Svg>
)
