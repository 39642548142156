import { useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Portal } from '~/components/portal'
import { IconArrowLeft } from '~/components/icons/arrow-left'
import { ChevronRight } from '~/components/icons/chevron-right'
import { ListRegionsQuery } from '~/@types/schemas'
import { Overlay, CloseIcon } from '~/components/modal/styled'
import {
  Container,
  Header,
  Content,
  BackButton,
  CloseButton,
  List,
  ListButton,
  Sections,
  Section,
} from './styled'

type Props = {
  isOpen: boolean
  onClose: () => void
  onChange: (value: string) => void
  data: ListRegionsQuery['listRegions']
}

type Region = ListRegionsQuery['listRegions'][0]
type Country = Region['country']
type Studio = Region['locations'][0]

export const HomeStudioModal = ({ isOpen, onClose, onChange, data }: Props) => {
  const wrapperRef = useRef<HTMLDivElement>()
  const [country, setCountry] = useState<Country>()
  const [region, setRegion] = useState<Region>()
  const [screenIndex, setScreenIndex] = useState(0)

  const title = useMemo(() => {
    return ['Home studio', country?.name, region?.name][screenIndex]
  }, [country, region, screenIndex])

  const countries = useMemo(() => {
    const seen = {}

    return data
      .map(r => r.country)
      .filter(c => (seen[c.code] ? false : (seen[c.code] = true)))
  }, [data])

  const handleSelectStudio = (selected: Studio) => {
    onChange(selected.id)
  }

  const handleSelectCountry = (selected: Country) => {
    setCountry(selected)
    setScreenIndex(1)
  }

  const handleSelectRegion = (selected: Region) => {
    setRegion(selected)

    if (selected.locations.length === 1) {
      handleSelectStudio(selected.locations[0])
    } else {
      setScreenIndex(2)
    }
  }

  const handleBack = () => {
    setScreenIndex(r => r - 1)
  }

  useEffect(() => {
    if (isOpen) {
      setScreenIndex(0)
    }
  }, [isOpen])

  useEffect(() => {
    wrapperRef.current?.scrollIntoView()
  }, [screenIndex])

  return (
    <Portal>
      <CSSTransition timeout={600} in={isOpen} unmountOnExit>
        <Overlay />
      </CSSTransition>
      <CSSTransition timeout={400} in={isOpen} unmountOnExit>
        <Container role="dialog">
          <Header>
            <BackButton
              type="button"
              onClick={handleBack}
              disabled={screenIndex === 0}
            >
              <IconArrowLeft />
            </BackButton>

            <div>{title}</div>
            <CloseButton onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Content>
            <Sections screenIndex={screenIndex} ref={wrapperRef}>
              <Section>
                <List>
                  {countries.map(c => (
                    <li key={c.code}>
                      <ListButton onClick={() => handleSelectCountry(c)}>
                        {c.name}
                        <ChevronRight />
                      </ListButton>
                    </li>
                  ))}
                </List>
              </Section>
              <Section>
                {country && (
                  <List>
                    {data
                      .filter(
                        r =>
                          r.locations.length > 0 &&
                          r.country.code === country.code
                      )
                      .map(r => (
                        <li key={r.id}>
                          <ListButton onClick={() => handleSelectRegion(r)}>
                            {r.name}
                            {r.locations.length > 1 && <ChevronRight />}
                          </ListButton>
                        </li>
                      ))}
                  </List>
                )}
              </Section>
              <Section>
                {region && (
                  <List>
                    {region.locations.map(l => (
                      <li key={l.id}>
                        <ListButton onClick={() => handleSelectStudio(l)}>
                          {l.name}
                        </ListButton>
                      </li>
                    ))}
                  </List>
                )}
              </Section>
            </Sections>
          </Content>
        </Container>
      </CSSTransition>
    </Portal>
  )
}
