import { useState } from 'react'
import { ResetPasswordForm } from './reset-password-form'
import { ResetPasswordSuccess } from './reset-password-success'
import { FormWrapper } from '../styled'

type Props = {
  onLogin: () => void
  onClose: () => void
}

export const ForgotPasswordForm = ({ onLogin, onClose }: Props) => {
  const [submitted, setSubmitted] = useState(false)

  return (
    <FormWrapper>
      {submitted ? (
        <ResetPasswordSuccess onConfirm={onClose} />
      ) : (
        <ResetPasswordForm
          onCancel={onLogin}
          onSuccess={() => setSubmitted(true)}
        />
      )}
    </FormWrapper>
  )
}
