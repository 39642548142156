import { useForm, Controller } from 'react-hook-form'
import { Input } from '~/components/form-field/input'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Form, Heading, Title } from '../styled'
import { PersonalValues } from './types'
import { HomeStudioPicker } from './home-studio-picker'

type Props = {
  onSubmit: (values: PersonalValues) => void
  initialValues?: PersonalValues
}

export const PersonalForm = ({ onSubmit, initialValues }: Props) => {
  const { register, formState, handleSubmit, control } =
    useForm<PersonalValues>({
      mode: 'onChange',
      defaultValues: initialValues,
    })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Sign Up</Heading>
      <Title>Personal</Title>
      <Input
        label="First name"
        autoComplete="given-name"
        error={formState.errors.firstName?.message}
        {...register('firstName', { required: true })}
      />
      <Input
        label="Last name"
        autoComplete="family-name"
        error={formState.errors.lastName?.message}
        {...register('lastName', { required: true })}
      />
      <Input
        label="Phone number (optional)"
        autoComplete="tel"
        error={formState.errors.phoneNumber?.message}
        {...register('phoneNumber', {
          pattern: /^\+?\d+$/u,
        })}
      />
      <Input
        label="Birth date"
        type="date"
        autoComplete="bday"
        error={formState.errors.birthDate?.message}
        {...register('birthDate', { required: true })}
      />
      <Controller
        control={control}
        name="homeLocationId"
        rules={{ required: true }}
        render={({ field }) => {
          return <HomeStudioPicker {...field} />
        }}
      />
      <ButtonWithLoading
        inverted
        block
        isLoading={false}
        disabled={!formState.isValid}
      >
        Continue
      </ButtonWithLoading>
    </Form>
  )
}
