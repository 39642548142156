import { useAppContext } from '~/context/app-context'
import { NotificationItem } from './item'
import { Wrapper } from './styled'

export const Notifications = () => {
  const { notifications } = useAppContext()

  return (
    <Wrapper>
      <div>
        {notifications.map(n => (
          <NotificationItem key={n.id} {...n} />
        ))}
      </div>
    </Wrapper>
  )
}
