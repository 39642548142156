import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { config } from '~/utils/config'

type WindowWithDataLayer = Window &
  typeof globalThis & {
    dataLayer?: any[]
  }

export const trackGAEvent = (
  eventName: string,
  args: { [key: string]: string } = {}
) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line padding-line-between-statements
    ;(window as WindowWithDataLayer).dataLayer?.push({
      event: eventName,
      ...args,
    })
  }
}

const pageview = (url: string) => trackGAEvent('pageview', { page: url })

export const GoogleTagManager = ({ children }) => {
  const router = useRouter()

  useEffect(() => {
    if (config.googleTagManagerID) {
      router.events.on('routeChangeComplete', pageview)

      return () => {
        router.events.off('routeChangeComplete', pageview)
      }
    }
  }, [router.events])

  return children
}
