import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Container } from '~/components/container'
import { UserMenu } from './user-menu'
import { Logo, Header, Nav, MenuItem } from './styled'

type Props = {
  inverted?: boolean
  isMenuHidden?: boolean
}

const menuItems = [
  { label: 'Home', href: '/dashboard', exact: true },
  { label: 'On Demand', href: '/on-demand/for-you', as: '/on-demand' },
  { label: 'My Workouts', href: '/my-workouts', exact: true },
]

export const PageHeader: FC<Props> = ({ inverted, isMenuHidden }) => {
  const router = useRouter()

  return (
    <Header inverted={inverted}>
      <Container>
        <Link href="/dashboard" passHref legacyBehavior>
          <a>
            <Logo aria-label="Barry's X logo" />
          </a>
        </Link>

        <Nav>
          <ul>
            {!isMenuHidden &&
              menuItems.map(({ exact, label, ...item }) => (
                <MenuItem
                  key={label}
                  isActive={
                    exact
                      ? (item.as || item.href) === router.pathname
                      : router.pathname.startsWith(item.as || item.href)
                  }
                >
                  <Link {...item} legacyBehavior>
                    <a>{label}</a>
                  </Link>
                </MenuItem>
              ))}
          </ul>
          <UserMenu inverted={inverted} />
        </Nav>
      </Container>
    </Header>
  )
}
