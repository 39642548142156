import { Button } from '~/components/button'
import { config } from '~/utils/config'
import { strings } from '~/lang/en'
import { Header as StyledHeader, ExternalIcon, BarrysChevron } from './styled'
import { useAuthContext } from './auth-context'
import { FC } from 'react'

export const Header: FC<{ isGympass: boolean }> = ({ isGympass }) => {
  const { signup } = useAuthContext()

  return (
    <StyledHeader>
      <BarrysChevron />
      {!isGympass && (
        <>
          <Button
            as="a"
            invisible
            href={config.membershipsLink}
            target="_blank"
          >
            Memberships <ExternalIcon />
          </Button>
          <Button outline wide onClick={signup}>
            {strings.login.sign_up_button}
          </Button>
        </>
      )}
    </StyledHeader>
  )
}
