import styled from 'styled-components'
import { styleReset } from '~/styles/misc'
import { mq } from '~/styles/mq'
import { theme } from '~/styles/theme'
import { paragraph } from '~/styles/typography'

export const Container = styled.div`
  position: fixed;
  inset: 0;
  transition: all 0.4s ${theme.ease.out};
  background: ${theme.color.neutral[900]};
  display: block;
  margin: auto;
  height: 100%;
  width: 100%;
  z-index: 9500;
  overflow: hidden;

  &.enter {
    transform: translateY(100%);
    opacity: 0.5;
  }

  &.enter-active {
    transform: none;
    opacity: 1;
  }

  &.exit {
    transform: none;
    opacity: 1;
  }

  &.exit-active {
    transition-timing-function: ${theme.ease.in};
    transform: translateY(100%);
    opacity: 0.5;
  }

  ${mq.medium} {
    max-width: 50rem;
    max-height: 80%;
  }
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  text-align: center;
  padding: 2.4rem 2.4rem 0.8rem 2.4rem;
  ${paragraph.demi};
  font-weight: 500;
  margin-bottom: 1.6rem;
`

export const Content = styled.div`
  overflow-y: auto;
  height: calc(100% - 10rem);
`

export const BackButton = styled.button`
  ${styleReset};
  display: block;
  cursor: pointer;
  font-size: 3rem;
  transition: opacity 0.4s ${theme.ease.out};

  :disabled,
  [disabled] {
    opacity: 0;
  }
`

export const CloseButton = styled.button`
  ${styleReset};
  display: block;
  cursor: pointer;
`

export const List = styled.ul`
  li {
    transition: all 0.5s ${theme.ease.out};
    border-bottom: 0.1rem solid ${theme.color.neutral[800]};

    &:hover {
      background: ${theme.color.neutral[950]};
    }

    &:first-child {
      border-top: 0.1rem solid ${theme.color.neutral[800]};
    }
  }
`

export const ListButton = styled.button`
  ${styleReset};
  cursor: pointer;
  padding: 2.2rem 2.4rem;
  ${paragraph.normal};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    font-size: 2.5rem;
  }
`

export const Section = styled.section`
  width: 100%;
  flex-shrink: 0;
  transition: transform 0.4s;
`

export const Sections = styled.div<{ screenIndex?: number }>`
  display: flex;
  overflow: hidden;

  ${Section} {
    transform: translateX(
      ${props => (props.screenIndex > 0 ? props.screenIndex * -100 : 0)}%
    );
  }
`
