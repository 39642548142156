import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string
}

export type AccessToken = {
  __typename?: 'AccessToken'
  accessToken: Scalars['String']
  tokenType: Scalars['String']
  expiresIn: Scalars['Float']
  refreshToken: Scalars['String']
  scope: Scalars['String']
}

export type AccessTokenInput = {
  /** Code received from authorizaton call. Exemple: https://my-domain.com/callback?code=NApCCg..BkWtQ&state=34fFs29kd09 */
  code: Scalars['String']
  /** If no redirectUri is provided the default uri will be use. Default: com.barrys.barrysathome.ios.dev://spotify */
  redirectUri?: Maybe<Scalars['String']>
}

export type AdminCountLibraryItems = {
  __typename?: 'AdminCountLibraryItems'
  total: Scalars['Int']
  audio: Scalars['Int']
  previouslyLive: Scalars['Int']
  video: Scalars['Int']
}

export type AdminLibraryItemFilter = {
  title?: Maybe<Scalars['String']>
  fullTextSearch?: Maybe<Scalars['String']>
  bodyPartsFilters?: Maybe<Array<BodyPartFilterEnum>>
  equipmentFilters?: Maybe<Array<EquipmentFilterEnum>>
  equipmentFilters_v2?: Maybe<Array<EquipmentFilterEnum_V2>>
  parentCategories?: Maybe<Array<LibraryItemCategoryEnum>>
  instructorIDs?: Maybe<Array<Scalars['ID']>>
  durations?: Maybe<Array<Scalars['Int']>>
  /**
   * Possible values:
   * - true: returns only previously live content
   * - false: returns only non previously live content
   * - not set: returns all content no matter if it is previously live or not
   */
  previouslyLive?: Maybe<Scalars['Boolean']>
  types?: Maybe<Array<LibraryItemTypeEnum>>
  type?: Maybe<LibraryItemTypeEnum>
  statuses?: Maybe<Array<LibraryItemStatusEnum>>
  labels?: Maybe<Array<LibraryItemLabelEnum>>
}

export type AdminListClassSessionsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  minDateTime?: Maybe<Scalars['DateTime']>
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  maxDateTime?: Maybe<Scalars['DateTime']>
  classSessionTypeIDs?: Maybe<Array<Scalars['ID']>>
  instructorIDs?: Maybe<Array<Scalars['ID']>>
  /** Location ID of the class session (!) */
  locationIDs?: Maybe<Array<Scalars['ID']>>
  /** Region ID of the class session (!) */
  regionIDs?: Maybe<Array<Scalars['ID']>>
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>
  /** Region ID of the instructor (!) */
  instructorRegionIDs?: Maybe<Array<Scalars['ID']>>
  sort?: Maybe<SortInput>
  /** Class IDs of classes to retrieve */
  searchIDs?: Maybe<Array<Scalars['ID']>>
}

export type AdminListLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<AdminLibraryItemFilter>
}

export type AdminListStudiosInput = {
  sort?: Maybe<SortInput>
  filter?: Maybe<StudioFilter>
}

export type AdminListUserFilter = {
  /** Search users by email, username, first name, last name or marianatek ID */
  query: Scalars['String']
}

export type AdminListUsersInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<AdminListUserFilter>
}

export type AdminStudioResult = {
  __typename?: 'AdminStudioResult'
  id: Scalars['ID']
  name: Scalars['String']
  regionName: Scalars['String']
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
}

export type AdminUpdateClassSessionInput = {
  classSessionID: Scalars['ID']
  equipment?: Maybe<Array<EquipmentEnum>>
  playlistID?: Maybe<Scalars['ID']>
  spotifyPlaylistIDs?: Maybe<Array<SpotifyPlaylistInput>>
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>
}

export type AdminUpdateStudioInput = {
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
}

export type AttendanceEntry = {
  __typename?: 'AttendanceEntry'
  value: Scalars['String']
  count: Scalars['Int']
}

export type Auth = {
  __typename?: 'Auth'
  user: User
  session: SessionObject
}

export type BanUserFromSessionInput = {
  userID: Scalars['ID']
  roomID: Scalars['ID']
  value: Scalars['Boolean']
}

export type Banner = {
  __typename?: 'Banner'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  category: ListBannerCategory
  /** Call to action text */
  CTAText: Scalars['String']
  imageURL: Scalars['String']
  link: Scalars['String']
  order: Scalars['Int']
}

/** BannerCategoryEnum desc */
export enum BannerCategoryEnum {
  Shop = 'SHOP',
  Community = 'COMMUNITY',
  News = 'NEWS',
}

export type BannerFilter = {
  category?: Maybe<BannerCategoryEnum>
}

/** BodyPartEnum desc */
export enum BodyPartEnum {
  Abs = 'ABS',
  Ass = 'ASS',
  Arms = 'ARMS',
  Back = 'BACK',
  Bodyweight = 'BODYWEIGHT',
  Chest = 'CHEST',
  Legs = 'LEGS',
  LowerBody = 'LOWER_BODY',
  Neck = 'NECK',
  Shoulders = 'SHOULDERS',
  UpperBody = 'UPPER_BODY',
  TotalBody = 'TOTAL_BODY',
}

/** BodyPartEnum desc */
export enum BodyPartFilterEnum {
  ArmsAbs = 'ARMS_ABS',
  LowerFocus = 'LOWER_FOCUS',
  ChestBackAbs = 'CHEST_BACK_ABS',
  AssAbs = 'ASS_ABS',
  TotalBody = 'TOTAL_BODY',
  UpperFocus = 'UPPER_FOCUS',
}

export type BookingWindowEntity = {
  __typename?: 'BookingWindowEntity'
  bookingId?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  endDateTime: Scalars['DateTime']
}

export type Bookmark = {
  __typename?: 'Bookmark'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  /** Total of bookmarks */
  total?: Maybe<Scalars['Int']>
  libraryItem: Library
  /** @deprecated Not needed */
  collection?: Maybe<OnDemandCollectionEntity>
}

export type Campaign = {
  __typename?: 'Campaign'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  title: Scalars['String']
  body: Scalars['String']
  imageURL: Scalars['String']
  sent: Scalars['Boolean']
  sentDateTime?: Maybe<Scalars['DateTime']>
  scheduledDateTime: Scalars['DateTime']
}

export type CancelRequestResult = {
  __typename?: 'CancelRequestResult'
  success: Scalars['Boolean']
  friend: User
}

export type CancelUserMembershipInput = {
  membershipID: Scalars['ID']
  reason: MembershipCancellationReasonEnum
  note?: Maybe<Scalars['String']>
}

export type Catalogue = {
  __typename?: 'Catalogue'
  classes: ProductClass
  memberships: ProductMembership
}

export type Challenge = {
  __typename?: 'Challenge'
  classes?: Maybe<Scalars['Float']>
  classes_taken?: Maybe<Scalars['Float']>
  status?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['DateTime']>
  daysUntilEnd?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
  image?: Maybe<Scalars['String']>
  progress?: Maybe<Scalars['String']>
}

export type ChangeBannersOrderInput = {
  bannerID: Scalars['ID']
  order: Scalars['Int']
}

export type ChangeInstructorBannersOrderInput = {
  instructorBannerID: Scalars['ID']
  order: Scalars['Int']
}

export type ChangeOnDemandCollectionSectionsOrderInput = {
  sectionID: Scalars['ID']
  order: Scalars['Int']
}

export type ChangeOnDemandCollectionsOrderInput = {
  collectionID: Scalars['ID']
  order: Scalars['Int']
}

export type ChangeOnDemandSectionsOrderInput = {
  sectionID: Scalars['ID']
  order: Scalars['Int']
}

export type ChangeRoomInput = {
  classSessionID: Scalars['ID']
  reservationID: Scalars['ID']
  roomIndex: Scalars['Int']
}

export type ChangeRoomResult = {
  __typename?: 'ChangeRoomResult'
  success: Scalars['Boolean']
}

export type ChangeRoomStatusToActiveInput = {
  roomID: Scalars['ID']
}

export type ChangeRoomStatusToPreparationInput = {
  roomID: Scalars['ID']
}

export type ChangeVideoPreferenceInput = {
  roomID: Scalars['ID']
  value: VideoPreferenceEnum
}

export type ClassSession = {
  __typename?: 'ClassSession'
  /** MarianaTek ID */
  id: Scalars['ID']
  name: Scalars['String']
  startDateTime: Scalars['DateTime']
  endDateTime: Scalars['DateTime']
  /** Time when the Class Session room opens. */
  openingDateTime: Scalars['DateTime']
  /** The class is full when the field is 0. */
  spotsCount: Scalars['Int']
  duration: Scalars['Int']
  participantsCount?: Maybe<Scalars['Int']>
  hasReservation: Scalars['Boolean']
  hasWaitlist: Scalars['Boolean']
  waitlistRemainingCount?: Maybe<Scalars['Int']>
  instructors?: Maybe<Array<Instructor>>
  classroom?: Maybe<Classroom>
  region: Region
  activeReservationID?: Maybe<Scalars['String']>
  activeReservation?: Maybe<Reservation>
  location: Location
  /** Users with reservation */
  participants: Array<User>
  privateParticipantsCount: Scalars['Int']
  joinStatus: JoinStatusEnum
  rooms: Array<ClassSessionRoom>
  isDigital: Scalars['Boolean']
  playlistID?: Maybe<Scalars['String']>
  /** To get access to Spotify playlists IDs outside getClassSession and admin_listClassSessions queries, you need to use the spotifyPlaylist field. */
  spotifyPlaylistIDs?: Maybe<Array<SpotifyPlaylist>>
  /** To get access to Apple music playlists IDs outside getClassSession and admin_listClassSessions queries, you need to use the appleMusicPlaylist field. */
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>
  equipment?: Maybe<Array<ListEquipment>>
  workoutSummary?: Maybe<WorkoutSummary>
  description?: Maybe<Scalars['String']>
  isFreeClass: Scalars['Boolean']
  userRated: Scalars['Boolean']
  moderatorID?: Maybe<Scalars['ID']>
  /**
   * Warning: expensive, needs to call MarianaTek and 7Digital consecutively, fetch only when required and for single entity
   * @deprecated DEPRECATED: 7Digital is no longer used
   */
  playlist?: Maybe<PlaylistObject>
  /** Spotify playlist for class session */
  spotifyPlaylist?: Maybe<Array<SpotifyPlaylistObject>>
  /** Apple Music playlist for class session */
  appleMusicPlaylist?: Maybe<Array<Scalars['String']>>
}

export type ClassSessionParticipantsArgs = {
  input?: Maybe<PaginationInput>
}

export type ClassSessionRoom = {
  __typename?: 'ClassSessionRoom'
  name: Scalars['String']
  roomIndex: Scalars['Int']
  availableSpots: Array<Scalars['Int']>
  existingSpots: Array<Scalars['Int']>
  /** Number of total spots in the room */
  roomCapacity: Scalars['Int']
  /** Number of reserved spots in the room */
  reservedSpotsCount: Scalars['Int']
  /** Returns visible participants of the current class session room, sorted by friendship and username */
  participants: Array<User>
  /** Returns visible friends in the current class session room */
  friends: Array<User>
  /** Returns IDs of hidden participants of the current class session room */
  hiddenParticipantIDs: Array<Scalars['String']>
  /** Returns the count of hidden participants */
  hiddenParticipantsCount: Scalars['Int']
  hasReservation: Scalars['Boolean']
}

export type ClassSessionRoomParticipantsArgs = {
  input?: Maybe<PaginationInput>
}

export type ClassSessionRoomFriendsArgs = {
  input?: Maybe<PaginationInput>
}

export type ClassSessionRoomHiddenParticipantIDsArgs = {
  input?: Maybe<PaginationInput>
}

export type ClassSessionRoomHiddenParticipantsCountArgs = {
  input?: Maybe<PaginationInput>
}

export type ClassSessionType = {
  __typename?: 'ClassSessionType'
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** Number of classes with this type that will take place. */
  upcomingCount: Scalars['Int']
  /** Number of classes with this type that took place in the past. */
  pastCount: Scalars['Int']
}

export type Classroom = {
  __typename?: 'Classroom'
  /** MarianaTek Classroom ID */
  id: Scalars['ID']
  name: Scalars['String']
  isListed: Scalars['Boolean']
  region?: Maybe<Region>
  location?: Maybe<Location>
}

export type ConnectClientInput = {
  classSessionID: Scalars['ID']
  /** Optional. In case user is an instructor, select the room to connect. If not, it will be ignored */
  roomIndex?: Maybe<Scalars['Int']>
  /** Optional. This field will be used in the chat instead of real name of the moderator. This will be applied to all rooms */
  moderatorName?: Maybe<Scalars['String']>
}

export type CountVodInstructorsInput = {
  query?: Maybe<Scalars['String']>
  regionIDs?: Maybe<Array<Scalars['String']>>
}

export type Country = {
  __typename?: 'Country'
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  printableName?: Maybe<Scalars['String']>
}

export type CountryInput = {
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  printableName?: Maybe<Scalars['String']>
}

export type CreateAdminInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  note?: Maybe<Scalars['String']>
}

export type CreateBannerInput = {
  name: Scalars['String']
  /** Call to action text */
  CTAText: Scalars['String']
  category: BannerCategoryEnum
  link: Scalars['String']
  imageURL: Scalars['String']
}

export type CreateCampaignInput = {
  title: Scalars['String']
  body: Scalars['String']
  imageURL: Scalars['String']
  scheduledDateTime: Scalars['DateTime']
}

export type CreateFreeClassGuestReservationInput = {
  classSessionID: Scalars['ID']
  roomIndex: Scalars['Int']
  /** This field will be ignored if sent together with "guestID" */
  guestEmail?: Maybe<Scalars['String']>
  guestID?: Maybe<Scalars['ID']>
}

export type CreateFreeClassReservationInput = {
  classSessionID: Scalars['ID']
  roomIndex: Scalars['Int']
}

export type CreateGuestReservationInput = {
  classSessionID: Scalars['ID']
  roomIndex: Scalars['Int']
  paymentOptionID: Scalars['ID']
  /** This field will be ignored if sent together with "guestID" */
  guestEmail?: Maybe<Scalars['String']>
  guestID?: Maybe<Scalars['ID']>
}

export type CreateInstructorBannerInput = {
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** Call to action text */
  CTAText: Scalars['String']
  link: Scalars['String']
  imageURL: Scalars['String']
  regionID?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
}

export type CreateLibraryItemInput = {
  title: Scalars['String']
  description: Scalars['String']
  parentCategory?: Maybe<LibraryItemCategoryEnum>
  bodyParts: Array<BodyPartEnum>
  equipment: Array<EquipmentEnum>
  instructorID: Scalars['ID']
  labels?: Maybe<Array<LibraryItemLabelEnum>>
  duration: Scalars['Int']
  type: LibraryItemTypeEnum
  imageURL: Scalars['String']
  classID: Scalars['String']
  /** Video + music + instructors voice (video file). The data is linked to metadata */
  fileID: Scalars['String']
  /** Video + instructors voice (video file). The data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>
  playlistID?: Maybe<Scalars['String']>
  playlistTrackInfo?: Maybe<Scalars['String']>
  hasClosedCaptions?: Maybe<Scalars['Boolean']>
  status?: Maybe<LibraryItemStatusEnum>
  statusEffectiveSince?: Maybe<Scalars['DateTime']>
  statusComment?: Maybe<Scalars['String']>
  additionalInstructorID?: Maybe<Scalars['String']>
  classSessionID?: Maybe<Scalars['String']>
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylistInput>>
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>
}

export type CreateLiveSessionInput = {
  classSessionID: Scalars['String']
}

export type CreateOnDemandCollectionInput = {
  name: Scalars['String']
  sectionID: Scalars['ID']
  imageURL: Scalars['String']
  libraryItemIDs: Array<Scalars['ID']>
}

export type CreateOnDemandCollectionSectionInput = {
  name: Scalars['String']
}

export type CreateOnDemandSectionInput = {
  name: Scalars['String']
  category: OnDemandCategoryEnum
  libraryItemIDs: Array<Scalars['ID']>
}

export type CreateRatingForClassSessionInput = {
  ratingQ1: RatingLevel
  ratingQ2: RatingLevel
  additionalComments: Scalars['String']
  classSessionID: Scalars['ID']
}

export type CreateRatingForLibraryItemInput = {
  ratingQ1: RatingLevel
  ratingQ2: RatingLevel
  additionalComments: Scalars['String']
  libraryItemID: Scalars['ID']
}

export type CreateReportInput = {
  /** ID of the user being reported */
  reportedUserID: Scalars['ID']
  reason: ReportReasonEnum
}

export type CreateReportResult = {
  __typename?: 'CreateReportResult'
  /** Report id */
  id: Scalars['ID']
}

export type CreateReservationInput = {
  classSessionID: Scalars['ID']
  roomIndex: Scalars['Int']
  paymentOptionID: Scalars['ID']
}

export type CreateUserInput = {
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  phoneNumber?: Maybe<Scalars['String']>
  /** Min. length: 8 characters */
  password: Scalars['String']
  homeLocationId: Scalars['ID']
  isWaiverSigned: Scalars['Boolean']
  isMarketingOptIn: Scalars['Boolean']
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>
  deviceID?: Maybe<Scalars['String']>
  gympassUID?: Maybe<Scalars['String']>
}

export type CreateWorkoutSummaryInput = {
  classSessionID?: Maybe<Scalars['String']>
  libraryID?: Maybe<Scalars['String']>
  totalTime: Scalars['Float']
  activeCalories: Scalars['Int']
  totalCalories: Scalars['Int']
  heartRate: Scalars['Int']
  maxHeartRate: Scalars['Int']
  maxSpeed?: Maybe<Scalars['Float']>
  miles?: Maybe<Scalars['Float']>
  topMPH?: Maybe<Scalars['Float']>
  averageMPH?: Maybe<Scalars['Float']>
  averageBPM?: Maybe<Scalars['Int']>
  treadmillCalories?: Maybe<Scalars['Int']>
}

export type CreateWorkoutSummaryInput_V2 = {
  classSessionID?: Maybe<Scalars['String']>
  libraryID?: Maybe<Scalars['String']>
  totalTime: Scalars['Float']
  activeCalories?: Maybe<Scalars['Int']>
  totalCalories?: Maybe<Scalars['Int']>
  heartRate?: Maybe<Scalars['Int']>
  maxHeartRate?: Maybe<Scalars['Int']>
  miles?: Maybe<Scalars['Float']>
  topMPH?: Maybe<Scalars['Float']>
  averageBPM?: Maybe<Scalars['Int']>
  bpmChart?: Maybe<Array<WorkoutSummaryChartItemInput>>
}

export type Credit = {
  __typename?: 'Credit'
  id: Scalars['ID']
  /** Total number of credits */
  transactionAmount: Scalars['Int']
  transactionCurrency: Scalars['String']
  expirationDateTime?: Maybe<Scalars['DateTime']>
  creditName: Scalars['String']
  isExpired: Scalars['Boolean']
  /** Number of credits left */
  remainingCredits: Scalars['Int']
  expiredUsages: Scalars['Int']
}

export type DeleteAllInstructorLibraryItemsInput = {
  instructorMarianaTekID: Scalars['String']
}

export type DeleteBannerInput = {
  id: Scalars['ID']
}

export type DeleteBannerResult = {
  __typename?: 'DeleteBannerResult'
  success: Scalars['Boolean']
}

export type DeleteCampaignInput = {
  id: Scalars['ID']
}

export type DeleteCampaignResult = {
  __typename?: 'DeleteCampaignResult'
  success: Scalars['Boolean']
}

export type DeleteFeedResult = {
  __typename?: 'DeleteFeedResult'
  success: Scalars['Boolean']
}

export type DeleteInstructorBannerInput = {
  id: Scalars['ID']
}

export type DeleteInstructorBannerResult = {
  __typename?: 'DeleteInstructorBannerResult'
  success: Scalars['Boolean']
}

export type DeleteLibraryItemInput = {
  id: Scalars['ID']
}

export type DeleteLibraryItemResult = {
  __typename?: 'DeleteLibraryItemResult'
  success: Scalars['Boolean']
}

export type DeleteOnDemandCollectionInput = {
  id: Scalars['ID']
}

export type DeleteOnDemandCollectionResult = {
  __typename?: 'DeleteOnDemandCollectionResult'
  success: Scalars['Boolean']
}

export type DeleteOnDemandCollectionSectionInput = {
  id: Scalars['ID']
}

export type DeleteOnDemandCollectionSectionResult = {
  __typename?: 'DeleteOnDemandCollectionSectionResult'
  success: Scalars['Boolean']
}

export type DeleteOnDemandSectionInput = {
  id: Scalars['ID']
}

export type DeleteOnDemandSectionResult = {
  __typename?: 'DeleteOnDemandSectionResult'
  success: Scalars['Boolean']
}

export type DeletePictureForReportedUser = {
  userID: Scalars['ID']
}

export type DeletePictureForReportedUserResult = {
  __typename?: 'DeletePictureForReportedUserResult'
  success: Scalars['Boolean']
}

export type DeleteProfileResult = {
  __typename?: 'DeleteProfileResult'
  success: Scalars['Boolean']
}

export type Device = {
  __typename?: 'Device'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  token: Scalars['String']
}

export type DeviceInfo = {
  __typename?: 'DeviceInfo'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  deviceID: Scalars['String']
  deviceType: Scalars['String']
  appVersion: Scalars['String']
  browserType: Scalars['String']
  browserVersion: Scalars['String']
  osType: Scalars['String']
  osVersion: Scalars['String']
  locale: Scalars['String']
}

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type DisconnectClientInput = {
  roomID: Scalars['ID']
}

export type Employee = {
  __typename?: 'Employee'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  employeeID: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  location?: Maybe<AdminStudioResult>
  region?: Maybe<Region>
  regionName?: Maybe<Scalars['String']>
  isPublished: Scalars['Boolean']
  promoCode?: Maybe<Scalars['String']>
  instagramHandle?: Maybe<Scalars['String']>
  spotifyLink?: Maybe<Scalars['String']>
  tiktokUsername?: Maybe<Scalars['String']>
  linkedinUsername?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  isManager: Scalars['Boolean']
  isInstructor: Scalars['Boolean']
  emailAddress?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  regionID?: Maybe<Scalars['String']>
  shopifyPicksCount: Scalars['Int']
  pictureUrl?: Maybe<Scalars['String']>
  pictureUrlHq?: Maybe<Scalars['String']>
}

/** EquipmentEnum desc */
export enum EquipmentEnum {
  LightWeights_1 = 'LIGHT_WEIGHTS_1',
  LightWeights_2 = 'LIGHT_WEIGHTS_2',
  MediumWeights_1 = 'MEDIUM_WEIGHTS_1',
  MediumWeights_2 = 'MEDIUM_WEIGHTS_2',
  HeavyWeights_1 = 'HEAVY_WEIGHTS_1',
  HeavyWeights_2 = 'HEAVY_WEIGHTS_2',
  BootyBand = 'BOOTY_BAND',
  LongBand = 'LONG_BAND',
  MiniBand = 'MINI_BAND',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT',
  BodyWeight = 'BODY_WEIGHT',
}

/** EquipmentFilterEnum desc */
export enum EquipmentFilterEnum {
  Weights = 'WEIGHTS',
  Bands = 'BANDS',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT',
}

/** EquipmentFilterEnum_v2 desc */
export enum EquipmentFilterEnum_V2 {
  Weights = 'WEIGHTS',
  BodyWeight = 'BODY_WEIGHT',
  Bands = 'BANDS',
  Bike = 'BIKE',
  Treadmill = 'TREADMILL',
  FoamRoller = 'FOAM_ROLLER',
  Hypervolt = 'HYPERVOLT',
}

export type Feed = {
  __typename?: 'Feed'
  userIDs: Scalars['ID']
  users: Array<User>
  classSession: ClassSession
}

export type FetchMtDataInput = {
  count: Scalars['Float']
}

export type FileUrl = {
  __typename?: 'FileURL'
  /** Music + instructors voice. Could be video or audio. */
  main: Scalars['String']
  /** Instructors voice. Could be video or audio. */
  instructor?: Maybe<Scalars['String']>
  /** Music. Just audio file. */
  music?: Maybe<Scalars['String']>
}

export type FileUrl_V3 = {
  __typename?: 'FileURL_v3'
  /** stream with all audio tracks associated */
  main: Scalars['String']
  /** Name of the instructor track */
  instructor?: Maybe<Scalars['String']>
  /** URL of the pure audio track stream */
  music?: Maybe<Scalars['String']>
}

export type FinishSessionInput = {
  sessionID: Scalars['ID']
}

export type Fitfam = {
  __typename?: 'Fitfam'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  userID: Scalars['String']
  userFullName: Scalars['String']
  userPictureUrl?: Maybe<Scalars['String']>
  actionDateTime: Scalars['DateTime']
  actionType: FitfamItemType
  libraryItemID?: Maybe<Scalars['String']>
  libraryItemName?: Maybe<Scalars['String']>
  libraryItemImage?: Maybe<Scalars['String']>
  classSessionID?: Maybe<Scalars['String']>
  classSessionName?: Maybe<Scalars['String']>
  classSessionStartTime?: Maybe<Scalars['DateTime']>
  classSessionLocation?: Maybe<Scalars['String']>
  /** @deprecated Use classSessionInstructorNames field instead */
  classSessionInstructorName?: Maybe<Scalars['String']>
  /** @deprecated Use classSessionInstructorImages field instead */
  classSessionInstructorImage?: Maybe<Scalars['String']>
  milestoneType?: Maybe<Scalars['String']>
  milestoneCount?: Maybe<Scalars['String']>
  challengeName?: Maybe<Scalars['String']>
  challengeUrl?: Maybe<Scalars['String']>
  challengeImageUrl?: Maybe<Scalars['String']>
  challengeAction?: Maybe<Scalars['String']>
  loyaltyTier?: Maybe<LoyaltyTierType>
  workoutSummaryID?: Maybe<Scalars['String']>
  userFullNames?: Maybe<Array<Scalars['String']>>
  userPictureUrls?: Maybe<Array<Scalars['String']>>
  userIDs?: Maybe<Array<Scalars['String']>>
  workoutSummary?: Maybe<WorkoutSummary>
  reactions?: Maybe<Reactions>
  workoutSummaryClassName?: Maybe<Scalars['String']>
  libraryItemInstructorNames?: Maybe<Array<Scalars['String']>>
  libraryItemInstructorImages?: Maybe<Array<Scalars['String']>>
  workoutSummaryInstructorNames?: Maybe<Array<Scalars['String']>>
  workoutSummaryInstructorImages?: Maybe<Array<Scalars['String']>>
  classSessionParticipantPictures?: Maybe<Array<Scalars['String']>>
  classSessionParticipantsCount?: Maybe<Scalars['Int']>
  userLoyaltyTier?: Maybe<LoyaltyTierType>
  classSessionInstructorNames?: Maybe<Array<Scalars['String']>>
  classSessionInstructorImages?: Maybe<Array<Scalars['String']>>
}

export enum FitfamItemType {
  Challenge = 'CHALLENGE',
  Loyalty = 'LOYALTY',
  Milestones = 'MILESTONES',
  Reservation = 'RESERVATION',
  Vod = 'VOD',
  WorkoutSummary = 'WORKOUT_SUMMARY',
}

export type ForceSoundOffInput = {
  userID: Scalars['ID']
  roomID: Scalars['ID']
  value: Scalars['Boolean']
}

export type ForceVideoOffInput = {
  userID: Scalars['ID']
  roomID: Scalars['ID']
  value: Scalars['Boolean']
}

export type FriendAttendingClass = {
  __typename?: 'FriendAttendingClass'
  friend: User
  attending: Scalars['Boolean']
}

export type Friendship = {
  __typename?: 'Friendship'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  friend: User
  status: FriendshipStatusEnum
  requester: User
}

export type FriendshipFriendArgs = {
  input?: Maybe<GetFriendInput>
}

/** Friendship status */
export enum FriendshipStatusEnum {
  Requested = 'REQUESTED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export type FuelBar = {
  __typename?: 'FuelBar'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  marianaTekID: Scalars['String']
  name: Scalars['String']
  imageURL?: Maybe<Scalars['String']>
  locations: Array<LocationEntity>
}

export type FuelBarFilter = {
  name?: Maybe<Scalars['String']>
}

export type GetCreditTransactionPenaltyInput = {
  creditTransactionID: Scalars['ID']
}

export type GetCreditTransactionPenaltyResult = {
  __typename?: 'GetCreditTransactionPenaltyResult'
  message: Scalars['String']
  isPenalty: Scalars['Boolean']
  fee: Scalars['Float']
  currency: Scalars['String']
}

export type GetEmployeeResult = {
  __typename?: 'GetEmployeeResult'
  employeeID: Scalars['String']
  isManager: Scalars['Boolean']
  isInstructor: Scalars['Boolean']
  firstName: Scalars['String']
  lastName: Scalars['String']
  pictureUrl?: Maybe<Scalars['String']>
  pictureUrlHq?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  regionID?: Maybe<Scalars['String']>
  location?: Maybe<AdminStudioResult>
  region?: Maybe<Region>
  isPublished: Scalars['Boolean']
  promoCode?: Maybe<Scalars['String']>
  instagramHandle?: Maybe<Scalars['String']>
  spotifyLink?: Maybe<Scalars['String']>
  tiktokUsername?: Maybe<Scalars['String']>
  linkedinUsername?: Maybe<Scalars['String']>
  username: Scalars['String']
  shopifyPicks: Array<ShopifyPicksResult>
  emailAddress?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  upcomingClasses: Array<UpcomingClassesResult>
  globalBanners: Array<InstructorBanner>
  regionalBanners: Array<InstructorBanner>
  studioBanners: Array<InstructorBanner>
}

export type GetFileUrlInput = {
  libraryItemID: Scalars['ID']
  /** videoStreamType: { option: "hls" | "dash" }. Default option when the field is empty is hls */
  videoStreamType?: Maybe<Scalars['String']>
}

export type GetFileUrlInput_V2 = {
  libraryItemID: Scalars['ID']
  videoStream?: Maybe<LibraryVideoStreamType>
}

export type GetFileUrlInput_V3 = {
  libraryItemID: Scalars['ID']
  videoStream?: Maybe<LibraryVideoStreamType>
}

export type GetFriendInput = {
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>
}

export type GetLibraryItemCategoriesTotalInput = {
  previouslyLive?: Maybe<Scalars['Boolean']>
  types?: Maybe<Array<LibraryItemTypeEnum>>
}

export type GetLiveSessionInput = {
  id: Scalars['ID']
}

export type GetMembershipTransactionPenaltyInput = {
  membershipTransactionID: Scalars['ID']
}

export type GetMembershipTransactionPenaltyResult = {
  __typename?: 'GetMembershipTransactionPenaltyResult'
  message: Scalars['String']
  isPenalty: Scalars['Boolean']
  fee: Scalars['Float']
  currency: Scalars['String']
}

export type GetSignedUrlInput = {
  extension?: Maybe<Scalars['String']>
}

export type GetSignedUrlResult = {
  __typename?: 'GetSignedUrlResult'
  /** Signed url */
  url: Scalars['String']
  expiresAt: Scalars['Float']
  /** @deprecated Use 'application/octet-stream' instead */
  contentType: Scalars['String']
  fileName: Scalars['String']
  /** The public URL. If the upload is successful, this URL should be used. */
  publicUrl: Scalars['String']
}

export type GetSpotifyPlaylistInput = {
  playlistID: Scalars['String']
}

export type GetTwilioTokenInput = {
  roomID: Scalars['ID']
}

export type GetTwilioTokenResult = {
  __typename?: 'GetTwilioTokenResult'
  /** Null when the room is not created */
  accessToken?: Maybe<Scalars['String']>
  /** Twilio's room name to be connected to */
  twilioRoomName: Scalars['String']
}

export type GrantVodAccessResult = {
  __typename?: 'GrantVODAccessResult'
  success: Scalars['Boolean']
}

export type GrantVodAccessToUserInput = {
  userID: Scalars['ID']
  grantedVODAccessNote?: Maybe<Scalars['String']>
}

/** Events supported by the gympass events api. */
export enum GympassEventEnum {
  Signup = 'SIGNUP',
  Signin = 'SIGNIN',
  Signout = 'SIGNOUT',
  Audio = 'AUDIO',
  Video = 'VIDEO',
  Cancellation = 'CANCELLATION',
  Chat = 'CHAT',
  ContentView = 'CONTENT_VIEW',
  CreateRecord = 'CREATE_RECORD',
  NoShow = 'NO_SHOW',
  OneOnOneSession = 'ONE_ON_ONE_SESSION',
  Renew = 'RENEW',
  Other = 'OTHER',
}

export type GympassUser = {
  __typename?: 'GympassUser'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  gympassUID: Scalars['String']
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  origin?: Maybe<Scalars['String']>
  userStatus?: Maybe<Scalars['String']>
  countryCode?: Maybe<Scalars['String']>
}

export type Instructor = {
  __typename?: 'Instructor'
  /** MarianaTek Employee ID */
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName: Scalars['String']
  pictureUrl?: Maybe<Scalars['String']>
  pictureUrlHq?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  instagramHandle?: Maybe<Scalars['String']>
  lessonsCount?: Maybe<Scalars['Int']>
  /** Instructor's home location */
  location?: Maybe<Location>
  /** Instructor's home region */
  region?: Maybe<Region>
  libraryItems: Array<Library>
  fullName?: Maybe<Scalars['String']>
  hasVOD: Scalars['Boolean']
}

export type InstructorLibraryItemsArgs = {
  input: ListInstructorLibraryItemsInput
}

export type InstructorBanner = {
  __typename?: 'InstructorBanner'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** Call to action text */
  CTAText: Scalars['String']
  imageURL: Scalars['String']
  link: Scalars['String']
  regionID?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  order: Scalars['Int']
  region?: Maybe<Region>
  location?: Maybe<Location>
}

export type InstructorBannerFilter = {
  name?: Maybe<Scalars['String']>
  isRegional: Scalars['Boolean']
  isStudioLevel?: Maybe<Scalars['Boolean']>
  regionID?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
}

export type InstructorCache = {
  __typename?: 'InstructorCache'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  pictureUrlHq?: Maybe<Scalars['String']>
  instagramHandle?: Maybe<Scalars['String']>
}

/** InstructorClassType desc */
export enum InstructorClassType {
  LiveClass = 'LIVE_CLASS',
  Vod = 'VOD',
}

export type InstructorListItemsFilter = {
  types?: Maybe<Array<LibraryItemTypeEnum>>
  instructorIDs?: Maybe<Array<Scalars['String']>>
  previouslyLive?: Maybe<Scalars['Boolean']>
  classType?: Maybe<Array<LibraryItemClassTypeEnum>>
}

export type InviteToClassResult = {
  __typename?: 'InviteToClassResult'
  success: Scalars['Boolean']
}

/** JoinStatusEnum */
export enum JoinStatusEnum {
  JoinClass = 'JOIN_CLASS',
  JoinWaitlist = 'JOIN_WAITLIST',
  Full = 'FULL',
}

export type Library = {
  __typename?: 'Library'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  title: Scalars['String']
  description: Scalars['String']
  parentCategory?: Maybe<ListLibraryItemCategory>
  bodyParts: Array<ListBodyPart>
  equipment: Array<ListEquipment>
  instructorID: Scalars['ID']
  instructorCache: InstructorCache
  labels: Array<ListLibraryItemLabel>
  duration: ListLibraryItemDuration
  imageURL: Scalars['String']
  classID: Scalars['String']
  fileID: Scalars['String']
  type: LibraryItemTypeEnum
  /** Instructors voice (video | audio file). When is video, the data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>
  playlistID?: Maybe<Scalars['String']>
  viewed: Scalars['Boolean']
  /** Closed captions related to the fileID and classID */
  hasClosedCaptions: Scalars['Boolean']
  /** Closed captions related to the instructorFileID */
  hasClosedCaptionsInstructorFile: Scalars['Boolean']
  fileURL?: Maybe<Scalars['String']>
  deletedAt: Scalars['DateTime']
  status: LibraryItemStatusEnum
  statusEffectiveSince: Scalars['DateTime']
  statusComment?: Maybe<Scalars['String']>
  additionalInstructorID?: Maybe<Scalars['String']>
  classSessionID?: Maybe<Scalars['String']>
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylist>>
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>
  fileName: Scalars['String']
  bodyweight: Scalars['Boolean']
  equipment_v2: Array<ListEquipment>
  instructor: Instructor
  additionalInstructor?: Maybe<Instructor>
  /** @deprecated 7 digital service is no longer available. */
  playlist?: Maybe<PlaylistObject>
  userRated: Scalars['Boolean']
  isBookmarked: Scalars['Boolean']
  hasWorkoutSummary: Scalars['Boolean']
}

export type LibraryFileUrlArgs = {
  videoStreamType?: Maybe<VideoStreamType>
}

/** LibraryItemCategoryEnum desc */
export enum LibraryItemCategoryEnum {
  Cardio = 'CARDIO',
  Strength = 'STRENGTH',
  StrengthCardio = 'STRENGTH_CARDIO',
  Stretch = 'STRETCH',
}

/** LibraryItemClassTypeEnum desc */
export enum LibraryItemClassTypeEnum {
  Audio = 'AUDIO',
  Video = 'VIDEO',
  PreviouslyLive = 'PREVIOUSLY_LIVE',
}

export type LibraryItemFilter = {
  bodyParts?: Maybe<Array<BodyPartEnum>>
  bodyPartsFilters?: Maybe<Array<BodyPartFilterEnum>>
  equipmentFilters?: Maybe<Array<EquipmentFilterEnum>>
  equipmentFilters_v2?: Maybe<Array<EquipmentFilterEnum_V2>>
  equipment?: Maybe<Array<EquipmentEnum>>
  parentCategories?: Maybe<Array<LibraryItemCategoryEnum>>
  instructorIDs?: Maybe<Array<Scalars['ID']>>
  durations?: Maybe<Array<Scalars['Int']>>
  previouslyLive?: Maybe<Scalars['Boolean']>
  types?: Maybe<Array<LibraryItemTypeEnum>>
  classType?: Maybe<Array<LibraryItemClassTypeEnum>>
}

/** LibraryItemLabelEnum desc */
export enum LibraryItemLabelEnum {
  Hardcore = 'HARDCORE',
  Christmas = 'CHRISTMAS',
  FullBody = 'FULL_BODY',
}

/** LibraryItemStatusEnum desc */
export enum LibraryItemStatusEnum {
  Published = 'PUBLISHED',
  Scheduled = 'SCHEDULED',
  Hidden = 'HIDDEN',
}

/** LibraryItemTypeEnum desc */
export enum LibraryItemTypeEnum {
  Audio = 'AUDIO',
  Video = 'VIDEO',
  PreviouslyLive = 'PREVIOUSLY_LIVE',
}

export type LibrarySpotifyPlaylist = {
  __typename?: 'LibrarySpotifyPlaylist'
  playlistID: Scalars['String']
  imageURL: Scalars['String']
  title: Scalars['String']
}

export type LibrarySpotifyPlaylistInput = {
  playlistID: Scalars['String']
  imageURL: Scalars['String']
  title: Scalars['String']
}

/** The supported stream types. */
export enum LibraryVideoStreamType {
  /** The default type. */
  Hls = 'HLS',
  /** Type for devices that dont support hls. */
  Dash = 'DASH',
}

export type ListBannerCategory = {
  __typename?: 'ListBannerCategory'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListBannersInput = {
  sort?: Maybe<SortInput>
  filter?: Maybe<BannerFilter>
}

export type ListBodyPart = {
  __typename?: 'ListBodyPart'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListBodyPartFilter = {
  __typename?: 'ListBodyPartFilter'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListCampaignsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
}

export type ListClassSessionInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  minDateTime?: Maybe<Scalars['DateTime']>
  /** DateTime in UTC. Ex.: '2021-01-01T14:45:00Z' */
  maxDateTime?: Maybe<Scalars['DateTime']>
  classSessionTypeIDs?: Maybe<Array<Scalars['ID']>>
  instructorIDs?: Maybe<Array<Scalars['ID']>>
  /** Location ID of the class session (!) */
  locationIDs?: Maybe<Array<Scalars['ID']>>
  /** Region ID of the class session (!) */
  regionIDs?: Maybe<Array<Scalars['ID']>>
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>
  /** Region ID of the instructor (!) */
  instructorRegionIDs?: Maybe<Array<Scalars['ID']>>
}

export type ListClassSessionTypesInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  includeNonVirtualClasses: Scalars['Boolean']
}

export type ListDeviceInfoInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  userID?: Maybe<Scalars['String']>
}

export type ListEmployeesFilterInput = {
  isManager: Scalars['Boolean']
  query?: Maybe<Scalars['String']>
}

export type ListEmployeesFilter_V2 = {
  regionIDs?: Maybe<Array<Scalars['String']>>
  /**
   * Possible values:
   * - [VOD]: returns instructors with VOD content
   * - [LIVE_CLASS]: returns instructors with a live class scheduled
   */
  classType?: Maybe<Array<InstructorClassType>>
}

export type ListEmployeesInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  filter: ListEmployeesFilterInput
  sort?: Maybe<SortInput>
}

export type ListEmployeesPublicFilterInput = {
  query?: Maybe<Scalars['String']>
  isPublished?: Maybe<Scalars['Boolean']>
  isManager?: Maybe<Scalars['Boolean']>
}

export type ListEmployeesPublicInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  filter?: Maybe<ListEmployeesPublicFilterInput>
}

export type ListEquipment = {
  __typename?: 'ListEquipment'
  name: Scalars['String']
  type: Scalars['String']
  image: Scalars['String']
}

export type ListEquipmentFilter = {
  __typename?: 'ListEquipmentFilter'
  name: Scalars['String']
  type: Scalars['String']
  image: Scalars['String']
}

export type ListFeedInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
}

export type ListFriendshipAttendingClass = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  statuses?: Maybe<Array<FriendshipStatusEnum>>
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>
  classSessionId?: Maybe<Scalars['String']>
  query?: Maybe<Scalars['String']>
}

export type ListFriendshipInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  statuses?: Maybe<Array<FriendshipStatusEnum>>
  /** Optional. Takes current user if this parameter is not sent */
  userID?: Maybe<Scalars['ID']>
  query?: Maybe<Scalars['String']>
}

export type ListFuelBarItemsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  filter?: Maybe<FuelBarFilter>
}

export type ListGroupedBannersResult = {
  __typename?: 'ListGroupedBannersResult'
  category: Scalars['String']
  items: Array<Banner>
}

export type ListInstructorBannersInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter: InstructorBannerFilter
}

export type ListInstructorLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<InstructorListItemsFilter>
}

export type ListInstructorVodFilters = {
  __typename?: 'ListInstructorVodFilters'
  classTypeFilters: Array<LibraryItemClassTypeEnum>
}

export type ListInstructorVodFiltersInput = {
  instructorId: Scalars['String']
}

export type ListInstructorsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
  regionIDs?: Maybe<Array<Scalars['String']>>
  IDs?: Maybe<Array<Scalars['String']>>
  /**
   * Possible values:
   * - true: returns only instructors with VOD content
   * - false: returns only instructors without VOD content
   * - null/not set: returns all instructors no matter if VOD content is present or not
   */
  withVodContent?: Maybe<Scalars['Boolean']>
  /**
   * Possible values:
   * - true: returns only instructors with Previously Live content
   * - false: returns only instructors without Previously Live content
   * - null/not set: returns all instructors no matter if Previously Live content is present or not
   */
  withPreviouslyLive?: Maybe<Scalars['Boolean']>
  /**
   * Possible values:
   * - true: returns all instructors
   * - false: returns only instructors suggested for allowed regions
   */
  ignoreSuggestedRegionFilter?: Maybe<Scalars['Boolean']>
}

export type ListInstructorsInput_V2 = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  query?: Maybe<Scalars['String']>
  filter?: Maybe<ListEmployeesFilter_V2>
}

export type ListLibraryItemCategory = {
  __typename?: 'ListLibraryItemCategory'
  name: Scalars['String']
  type: Scalars['String']
  image: Scalars['String']
  verticalImage: Scalars['String']
  /** Total of workouts under this category */
  total?: Maybe<Scalars['Int']>
  /** Total of workouts under this category [v2] */
  total_v2?: Maybe<Scalars['Int']>
}

export type ListLibraryItemCategoryTotalArgs = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>
}

export type ListLibraryItemCategoryTotal_V2Args = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>
}

export type ListLibraryItemDuration = {
  __typename?: 'ListLibraryItemDuration'
  name: Scalars['String']
  type: Scalars['Int']
}

export type ListLibraryItemLabel = {
  __typename?: 'ListLibraryItemLabel'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListLibraryItemStatus = {
  __typename?: 'ListLibraryItemStatus'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListLibraryItemType = {
  __typename?: 'ListLibraryItemType'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListLibraryItemsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<LibraryItemFilter>
}

export type ListOnDemandCategory = {
  __typename?: 'ListOnDemandCategory'
  name: Scalars['String']
  type: Scalars['String']
}

export type ListOnDemandSectionInput = {
  sort?: Maybe<SortInput>
  filter?: Maybe<OnDemandSectionFilter>
}

export type ListPaymentOptionsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  classSessionID: Scalars['ID']
  /** When true, returns the payment options that allow inviting guests */
  reservedForGuest?: Maybe<Scalars['Boolean']>
}

export type ListProductsInput = {
  platform: PlatformEnum
}

export type ListRegionsInput = {
  /** Filter regions by instructors with some VOD content */
  withVodContent?: Maybe<Scalars['Boolean']>
  /** Include non Barrys X regions */
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>
  /** Returns only the regions of instructors that have upcoming live classes */
  fromSuggestedInstructors?: Maybe<Scalars['Boolean']>
}

export type ListRegionsResult = {
  __typename?: 'ListRegionsResult'
  id: Scalars['ID']
  name: Scalars['String']
  country: Country
  locations: Array<LocationObject>
}

export type ListReportReason = {
  __typename?: 'ListReportReason'
  name: Scalars['String']
  type: ReportReasonEnum
}

export type ListReportsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<ReportFilter>
}

export type ListReservationsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sortBy?: Maybe<ReservationSortingEnum>
  time?: Maybe<ReservationTimeEnum>
  status?: Maybe<Array<ReservationStatusEnum>>
  /** Optional. If omitted will send reservations for the user making the request. */
  userID?: Maybe<Scalars['ID']>
  classSessionID?: Maybe<Scalars['ID']>
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>
}

export type ListStudiosInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  UserID?: Maybe<Scalars['String']>
}

export type ListStudiosResult = {
  __typename?: 'ListStudiosResult'
  name: Scalars['String']
  regions: Array<ListRegionsResult>
}

export type ListUserBookmarksInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sort?: Maybe<SortInput>
  filter?: Maybe<UserBookmarkFilter>
}

export type ListUserClassSessionsInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  sortBy?: Maybe<ReservationSortingEnum>
  status?: Maybe<Array<ReservationStatusEnum>>
  time?: Maybe<ReservationTimeEnum>
  /** Optional. If omitted will send reservations for the user making the request. */
  userID?: Maybe<Scalars['ID']>
  includeNonVirtualClasses?: Maybe<Scalars['Boolean']>
}

export type ListUserInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  query: Scalars['String']
}

export type ListUserInputAttendingClass = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  query: Scalars['String']
  classSessionId: Scalars['String']
}

export type ListUserOrdersInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
}

export type ListUserPurchasesInput = {
  type: PurchaseTypeEnum
  platform?: Maybe<PlatformEnum>
}

export type ListVodFilters = {
  __typename?: 'ListVodFilters'
  bodyParts?: Maybe<Array<ListBodyPart>>
  bodyPartsFilters: Array<ListBodyPartFilter>
  /** @deprecated DEPRECATED: too granular, use equipmentFilters instead and do not even send this field back to backend */
  equipment?: Maybe<Array<ListEquipment>>
  equipmentFilters: Array<ListEquipmentFilter>
  equipmentFilters_v2: Array<ListEquipmentFilter>
  labels: Array<ListLibraryItemLabel>
  types: Array<ListLibraryItemType>
  durations: Array<ListLibraryItemDuration>
  statuses: Array<ListLibraryItemStatus>
  classTypeFilters: Array<LibraryItemClassTypeEnum>
}

export type ListVodFiltersInput = {
  category?: Maybe<LibraryItemCategoryEnum>
}

export type ListWorkoutSummaryInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  libraryID: Scalars['String']
}

export type LiveSession = {
  __typename?: 'LiveSession'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  /** @deprecated DEPRECATED: use roomID from rooms */
  roomID?: Maybe<Scalars['ID']>
  /** @deprecated DEPRECATED: use url from rooms */
  url?: Maybe<Scalars['String']>
  /** @deprecated Use LiveSessionRoom.status instead */
  status?: Maybe<LiveSessionStatusEnum>
  classSession?: Maybe<ClassSession>
  rooms: Array<LiveSessionRoom>
  reservedRoom?: Maybe<LiveSessionRoom>
}

export type LiveSessionConnectResult = {
  __typename?: 'LiveSessionConnectResult'
  /** Null when the room is not created */
  accessToken?: Maybe<Scalars['String']>
  /** Twilio's room name to be connected to */
  twilioRoomName: Scalars['String']
  liveSession: LiveSession
  /** Room information that user is supposed to connect */
  room: LiveSessionRoom
}

export type LiveSessionRoom = {
  __typename?: 'LiveSessionRoom'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  liveSessionID: Scalars['String']
  name: Scalars['String']
  roomIndex: Scalars['Int']
  /** Twilio room id */
  roomID?: Maybe<Scalars['ID']>
  /** Twilio room url */
  url?: Maybe<Scalars['String']>
  /** Unique name to create twilio room */
  uniqueName?: Maybe<Scalars['String']>
  liveSession: LiveSession
  status?: Maybe<LiveSessionRoomStatusEnum>
  /** Returns visible participants of the live session room, sorted by friendship and username */
  participants: Array<User>
  /** Returns IDs of hidden participants of the current class session room */
  hiddenParticipantIDs: Array<Scalars['String']>
  /** Returns the count of hidden participants */
  hiddenParticipantsCount: Scalars['Int']
}

export type LiveSessionRoomParticipantsArgs = {
  input?: Maybe<PaginationInput>
}

export type LiveSessionRoomHiddenParticipantIDsArgs = {
  input?: Maybe<PaginationInput>
}

export type LiveSessionRoomHiddenParticipantsCountArgs = {
  input?: Maybe<PaginationInput>
}

/** LiveSessionRoomStatusEnum description */
export enum LiveSessionRoomStatusEnum {
  WaitingRoom = 'WAITING_ROOM',
  PreparationRoom = 'PREPARATION_ROOM',
  Active = 'ACTIVE',
  Finished = 'FINISHED',
}

/** LiveSessionStatusEnum */
export enum LiveSessionStatusEnum {
  WaitingRoom = 'WAITING_ROOM',
  Active = 'ACTIVE',
  Finished = 'FINISHED',
}

export type Location = {
  __typename?: 'Location'
  id: Scalars['ID']
  name: Scalars['String']
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
}

export type LocationEntity = {
  __typename?: 'LocationEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  marianaTekID: Scalars['String']
  name: Scalars['String']
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
}

export type LocationObject = {
  __typename?: 'LocationObject'
  id: Scalars['ID']
  name: Scalars['String']
}

export enum LoyaltyTierType {
  Black = 'BLACK',
  Member = 'MEMBER',
  Red = 'RED',
  Silver = 'SILVER',
  OptedOut = 'OPTED_OUT',
}

export type Membership = {
  __typename?: 'Membership'
  id: Scalars['ID']
  membershipName: Scalars['String']
  renewalRate: Scalars['String']
  renewalCurrency: Scalars['String']
  startDate?: Maybe<Scalars['DateTime']>
  endDate?: Maybe<Scalars['DateTime']>
  purchaseDate: Scalars['DateTime']
  cancellationDate?: Maybe<Scalars['DateTime']>
  isCancellable: Scalars['Boolean']
  paymentInterval: Scalars['String']
  usageIntervalLimit?: Maybe<Scalars['Float']>
}

/** MembershipCancellationReasonEnum desc */
export enum MembershipCancellationReasonEnum {
  AnotherDigitalProduct = 'ANOTHER_DIGITAL_PRODUCT',
  IWentBackToTheStudio = 'I_WENT_BACK_TO_THE_STUDIO',
  Price = 'PRICE',
  MissingOnDemandContent = 'MISSING_ON_DEMAND_CONTENT',
  BadTiming = 'BAD_TIMING',
  InjurySurgery = 'INJURY_SURGERY',
  IPreferInPersonClasses = 'I_PREFER_IN_PERSON_CLASSES',
  NotWhatIExpected = 'NOT_WHAT_I_EXPECTED',
  Other = 'OTHER',
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Requests to be friends of another user.
   *     The Friendship is created with a REQUESTED state.
   */
  requestFriendship: Friendship
  /** Cancel the requests sent to another user. */
  cancelFriendshipRequest: CancelRequestResult
  /** Updates the Friendship to ACCEPTED status. The friendship must be in REQUESTED state. */
  acceptFriendship: Friendship
  /** Updates Friendship to DECLINED status. The friendship must be in REQUESTED state. */
  declineFriendship: Friendship
  /** Deletes a Friendship. */
  removeFriendship: Scalars['Boolean']
  /** Invite friend to class */
  inviteToClass: InviteToClassResult
  /** Updates a notification. Use this to set the `isUnread` status. */
  updateNotification: Notification
  /** Set all notifications which were `isUnread = true` for current user as read. */
  readAllNotifications: ReadAllNotificationsResult
  /** Mutation for testing notifications */
  sendNotification: Scalars['Boolean']
  /** Creates a new user (Sign up). */
  createUser: Auth
  /** Updates the current user annd returns the user object. */
  updateUser: UpdateUserResult
  /** Sets all unread friendships of current user to 0. */
  readAllFriendRequests: User
  /** Sets all unread friendships of current user to 0. */
  readAllFitfamActivities: Scalars['Boolean']
  /** Updates a reported user (Admin only). */
  admin_updateReportedUser: UpdateReportedUserResult
  /** Removes image for reported user (Admin only). */
  admin_deletePictureForReportedUser: DeletePictureForReportedUserResult
  /** Assigns ADMIN role to a user (Admin only). */
  admin_createAdmin: User
  /** Updates an admin (Admin only). */
  admin_updateAdmin: UpdateAdminResult
  /** Blocks the user from the application (Admin only). */
  admin_blockUser: User
  /** Unblocks the user from the application (Admin only). */
  admin_unblockUser: User
  /** Deactivate an admin user (Admin only). */
  admin_deactivateAdmin: User
  /** Active an admin user (Admin only). */
  admin_activateAdmin: User
  /** Deletes an admin user (Admin only). */
  admin_deleteAdmin: User
  /** Deletes an user. */
  deleteProfile: DeleteProfileResult
  /** Grand VOD access to selected user (Admin only). */
  admin_grantVODAccessToUser: GrantVodAccessResult
  /** Revoke VOD access from selected user (Admin only). */
  admin_revokeVODAccessFromUser: RevokeVodAccessResult
  /** Trigger the code for fetching data */
  triggerUsersMtFetch: Scalars['Boolean']
  /** Updates the current user section Personal, returns the user object. */
  updateUserPersonal: UpdateUserResult
  /** Updates the current user section Personal, returns the user object. */
  updateUserSocial: UpdateUserResult
  /** Updates the current user section Emergency, returns the user object. */
  updateUserEmergency: UpdateUserResult
  /** Updates the current user section Address, returns the user object. */
  updateUserAddress: UpdateUserResult
  /** Signs in the user with email and password */
  signIn: Auth
  /** Resets user password */
  resetPassword: PasswordResetResult
  /** Gets a new Authorization token from a Refresh token */
  refreshToken: Auth
  /** Creates a Report of an user. */
  createReport: CreateReportResult
  /** Marks a report as seen or resolved (Admin only). */
  admin_updateReport: Report
  /** Send email to a reported user (admin only) */
  admin_sendEmailToReportedUser: SendEmailToReportedUserResult
  /** Send email to a user (admin only) */
  admin_sendEmailToUser: SendEmailToUserResult
  /** Update notification setting for selected type for current user */
  updateNotificationSettings: Scalars['Boolean']
  /** Updates equipment and playlistID of library item associated with class session. */
  admin_updateClassSession: ClassSession
  /** Verify on AppStore if a receipt is valid */
  verifyPurchaseIOS: VerifyIosPurchaseObject
  /** Verify multiple transactions from AppStore */
  verifyPurchasesIOS: Array<VerifyIosPurchaseObject>
  /** Verify on Google Play if a purchase token is valid */
  verifyPurchaseAndroid: VerifyAndroidPurchaseObject
  /** Verify multiple purchases on Google Play at once */
  verifyPurchasesAndroid: Array<VerifyAndroidPurchaseObject>
  /**
   * Saves a device with its corresponding notification token.
   *     If the same token is sent with a different userID, the userID will be updated for that token.
   */
  registerDevice: Device
  /** Removes a device by token or device ID. */
  removeDevice: Scalars['Boolean']
  /** Store information about the current device for the current user. */
  storeDeviceInfo: DeviceInfo
  /** Report event to gympass. */
  reportGympassEvent: Scalars['Boolean']
  /** Create and plan new Campaign (admin only) */
  admin_createCampaign: Campaign
  /** Update selected Campaign (admin only) */
  admin_updateCampaign: Campaign
  /** Delete selected Campaign (admin only) */
  admin_deleteCampaign: DeleteCampaignResult
  admin_updateEmployee: Employee
  admin_updateEmployeeShopifyPicks: Array<ShopifyPicks>
  /** Get detail of selected studio */
  admin_updateStudio: AdminStudioResult
  /** Create new video or audio (admin only) */
  admin_createLibraryItem: Library
  /** Update the library item (admin only) */
  admin_updateLibraryItem: Library
  /** Delete a library item by its ID (admin only) */
  admin_deleteLibraryItem: DeleteLibraryItemResult
  /** Delete all library items related to selected instuctor */
  admin_deleteAllInstructorLibraryItems: DeleteLibraryItemResult
  /**
   * Unpublish all library items (admin only) or check if there are any library items that should be unpublished
   * @deprecated Use admin_unpublishLibraryItem instead
   */
  admin_unpublishLibraryIfNeeded: Array<Library>
  /** Unpublish a library item by its ID (admin only) */
  admin_unpublishLibraryItem: UnpublishLibraryItemResult
  /** Creates a new bookmark for a libraryItem. */
  createBookmarkForLibraryItem: Scalars['Boolean']
  /** Creates a new bookmark for a collection. */
  createBookmarkForCollection: Scalars['Boolean']
  /** Removes a bookmark by ID. */
  removeBookmark: Scalars['Boolean']
  /** Removes a bookmark by library item ID. */
  removeBookmarkByLibraryItemID: Scalars['Boolean']
  /** Create new on demand section for selected category (admin only) */
  admin_createOnDemandSection: OnDemandSectionEntity
  /** Update detail of selected on demand section (admin only) */
  admin_updateOnDemandSection: OnDemandSectionEntity
  /** Delete on demand section with all its items (admin only) */
  admin_deleteOnDemandSection: DeleteOnDemandSectionResult
  /** Change the order for on demand sections of the same category (admin only) */
  admin_changeOnDemandSectionsOrder: Array<OnDemandSectionEntity>
  /** Create new collection section (admin only) */
  admin_createOnDemandCollectionSection: OnDemandCollectionSectionEntity
  /** Update collection section detail (admin only) */
  admin_updateOnDemandCollectionSection: OnDemandCollectionSectionEntity
  /** Delete collection section with all its collections (admin only) */
  admin_deleteOnDemandCollectionSection: DeleteOnDemandCollectionSectionResult
  /** Change the order for collection sections (admin only) */
  admin_changeOnDemandCollectionSectionsOrder: Array<OnDemandCollectionSectionEntity>
  /** Create new collection for selected section (admin only) */
  admin_createOnDemandCollection: OnDemandCollectionEntity
  /** Update collection detail (admin only) */
  admin_updateOnDemandCollection: OnDemandCollectionEntity
  /** Remove collection and all its items (admin only) */
  admin_deleteOnDemandCollection: DeleteOnDemandCollectionResult
  /** Change the order of collections of the same section (admin only) */
  admin_changeOnDemandCollectionOrder: Array<OnDemandCollectionEntity>
  createRatingForClassSession: Scalars['Boolean']
  createRatingForLibraryItem: Scalars['Boolean']
  /** Trigger the code for generating instructor cache data */
  fillInstructorCache: Scalars['Boolean']
  /** Update data for single fuel bar item (admin only) */
  admin_updateFuelBarItem: FuelBar
  /** Trigger the code for generating fuel bar items to our database */
  triggerFillFuelBar: Scalars['Boolean']
  /** Creates a reservation (books a class). */
  createReservation: Reservation
  /** Creates a reservation (free class). */
  createReservationForFreeClass: Reservation
  /** Creates a reservation for a guest (books a class) */
  createGuestReservation: Reservation
  /** Creates a reservation for a guest (free class) */
  createGuestReservationForFreeClass: Reservation
  /** Change room for class session */
  changeRoom: ChangeRoomResult
  /** Cancel a reservation. */
  cancelReservation: Scalars['Boolean']
  /** Check in into a class. */
  checkInReservation: Reservation
  /** Check out from a class */
  checkOutReservation: Reservation
  /** Creates a new workout summary. */
  createWorkoutSummary: WorkoutSummary
  /** Creates a new workout summary [v2]. */
  createWorkoutSummary_v2: WorkoutSummary
  /** Shares a workout summary with fitfam. */
  shareWorkoutSummaryWithFitfam: ShareWorkoutSummaryResult
  /** Deletes a feed item. */
  deleteFeedItem: DeleteFeedResult
  /** Adds a reaction to a feed */
  addReactionToFeed: ReactionUpdateResponse
  /** Adds a reaction to a feed */
  removeReactionFromFeed: ReactionUpdateResponse
  /** Create new banner (admin only) */
  admin_createBanner: Banner
  /** Update data for single banner (admin only) */
  admin_updateBanner: Banner
  /** Remove banner (admin only) */
  admin_deleteBanner: DeleteBannerResult
  /** Change order for banners of same category (admin only) */
  admin_changeBannersOrder: Array<Banner>
  /** @deprecated use "saveVideoAnalyticsEventWithMetadata" query instead */
  saveVideoAnalyticsEvent: Scalars['Boolean']
  saveVideoAnalyticsWithMetadataEvent: SaveEventObject
  connectClient: LiveSessionConnectResult
  disconnectClient: Scalars['Boolean']
  /** Create new live session (Instructor only). */
  createLiveSession: LiveSession
  /**
   * Start showing song titles from playlist to clients (Instructor only).
   * @deprecated DEPRECATED: 7D service is no longer use.
   */
  startMusicPlayback: StartMusicPlaybackResult
  /**
   * Update song title times from playlist to clients (Instructor only).
   * @deprecated DEPRECATED: 7D service is no longer use.
   */
  updateMusicPlaybackTimes: StartMusicPlaybackResult
  /** @deprecated Use "sendRoomMessage" instead. */
  sendMessage: Scalars['Boolean']
  /** Finish selected session. (Instructor only) */
  finishSession: Scalars['Boolean']
  /** Force sound off/on for selected user in selected session. (Instructor only) */
  forceSoundOff: Scalars['Boolean']
  /** Force video off/on for selected user in selected session. (Instructor only) */
  forceVideoOff: Scalars['Boolean']
  /** Ban or unban selected user from session. (Instructor only) */
  banUserFromSession: Scalars['Boolean']
  /** Turn off/on red filter in session for current user */
  toggleRedFilter: Scalars['Boolean']
  /** Change who can see current user's video */
  changeVideoPreference: Scalars['Boolean']
  /** Force sound off/on for all participants in all rooms for selected session. (Instructor only) */
  muteAll: Scalars['Boolean']
  sendRoomMessage: Scalars['Boolean']
  /** Change status of selected room to "Preparation" */
  changeRoomStatusToPreparation: Scalars['Boolean']
  /** Start selected session room. (Instructor only) */
  changeRoomStatusToActive: Scalars['Boolean']
  /** Cancel membership for the current user */
  cancelUserMembership: Membership
  /** Cancel membership for the current user with specifying reason */
  cancelUserMembership_v2: Membership
  /** Create new instructor banner (admin only) */
  admin_createInstructorBanner: InstructorBanner
  /** Update data for single instructor banner (admin only) */
  admin_updateInstructorBanner: InstructorBanner
  /** Remove instructor banner (admin only) */
  admin_deleteInstructorBanner: DeleteInstructorBannerResult
  /** Change order for instructors banners for specific region or location (admin only) */
  admin_changeInstructorBannersOrder: Array<InstructorBanner>
}

export type MutationRequestFriendshipArgs = {
  input: RequestFriendshipInput
}

export type MutationCancelFriendshipRequestArgs = {
  input: RequestFriendshipInput
}

export type MutationAcceptFriendshipArgs = {
  friendshipID: Scalars['ID']
}

export type MutationDeclineFriendshipArgs = {
  friendshipID: Scalars['ID']
}

export type MutationRemoveFriendshipArgs = {
  friendshipID: Scalars['ID']
}

export type MutationInviteToClassArgs = {
  classSessionID: Scalars['ID']
  friendID: Scalars['ID']
}

export type MutationUpdateNotificationArgs = {
  input: UpdateNotificationInput
}

export type MutationSendNotificationArgs = {
  type: NotificationTypeEnum
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationAdmin_UpdateReportedUserArgs = {
  input: UpdateReportedUserInput
}

export type MutationAdmin_DeletePictureForReportedUserArgs = {
  input: DeletePictureForReportedUser
}

export type MutationAdmin_CreateAdminArgs = {
  input: CreateAdminInput
}

export type MutationAdmin_UpdateAdminArgs = {
  input: UpdateAdminInput
}

export type MutationAdmin_BlockUserArgs = {
  input: UserIdInput
}

export type MutationAdmin_UnblockUserArgs = {
  input: UserIdInput
}

export type MutationAdmin_DeactivateAdminArgs = {
  input: UserIdInput
}

export type MutationAdmin_ActivateAdminArgs = {
  input: UserIdInput
}

export type MutationAdmin_DeleteAdminArgs = {
  input: UserIdInput
}

export type MutationAdmin_GrantVodAccessToUserArgs = {
  input: GrantVodAccessToUserInput
}

export type MutationAdmin_RevokeVodAccessFromUserArgs = {
  input: RevokeVodAccessToUserInput
}

export type MutationTriggerUsersMtFetchArgs = {
  input: FetchMtDataInput
}

export type MutationUpdateUserPersonalArgs = {
  input: UpdateUserPersonalInput
}

export type MutationUpdateUserSocialArgs = {
  input: UpdateUserSocialInput
}

export type MutationUpdateUserEmergencyArgs = {
  input: UpdateUserEmergencyInput
}

export type MutationUpdateUserAddressArgs = {
  input: UpdateUserAddressInput
}

export type MutationSignInArgs = {
  input: SignInInput
}

export type MutationResetPasswordArgs = {
  input: PasswordResetInput
}

export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']
}

export type MutationCreateReportArgs = {
  input: CreateReportInput
}

export type MutationAdmin_UpdateReportArgs = {
  input: UpdateReportInput
}

export type MutationAdmin_SendEmailToReportedUserArgs = {
  input: SendEmailToReportedUserInput
}

export type MutationAdmin_SendEmailToUserArgs = {
  input: SendEmailToUserInput
}

export type MutationUpdateNotificationSettingsArgs = {
  input: UpdateSettingsInput
}

export type MutationAdmin_UpdateClassSessionArgs = {
  input: AdminUpdateClassSessionInput
}

export type MutationVerifyPurchaseIosArgs = {
  input: VerifyPurchaseIosInput
}

export type MutationVerifyPurchasesIosArgs = {
  input: VerifyPurchasesIosInput
}

export type MutationVerifyPurchaseAndroidArgs = {
  input: VerifyPurchaseAndroidInput
}

export type MutationVerifyPurchasesAndroidArgs = {
  input: Array<VerifyPurchaseAndroidInput>
}

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput
}

export type MutationRemoveDeviceArgs = {
  tokenOrDeviceID: Scalars['String']
}

export type MutationStoreDeviceInfoArgs = {
  input: StoreDeviceInfoInput
}

export type MutationReportGympassEventArgs = {
  eventType: GympassEventEnum
}

export type MutationAdmin_CreateCampaignArgs = {
  input: CreateCampaignInput
}

export type MutationAdmin_UpdateCampaignArgs = {
  input: UpdateCampaignInput
}

export type MutationAdmin_DeleteCampaignArgs = {
  input: DeleteCampaignInput
}

export type MutationAdmin_UpdateEmployeeArgs = {
  input: UpdateEmployeeInput
}

export type MutationAdmin_UpdateEmployeeShopifyPicksArgs = {
  input: UpdateEmployeeShopifyPicksInput
}

export type MutationAdmin_UpdateStudioArgs = {
  input: AdminUpdateStudioInput
}

export type MutationAdmin_CreateLibraryItemArgs = {
  input: CreateLibraryItemInput
}

export type MutationAdmin_UpdateLibraryItemArgs = {
  input: UpdateLibraryItemInput
}

export type MutationAdmin_DeleteLibraryItemArgs = {
  input: DeleteLibraryItemInput
}

export type MutationAdmin_DeleteAllInstructorLibraryItemsArgs = {
  input: DeleteAllInstructorLibraryItemsInput
}

export type MutationAdmin_UnpublishLibraryItemArgs = {
  input: UnpublishLibraryLibraryItemInput
}

export type MutationCreateBookmarkForLibraryItemArgs = {
  libraryItemID: Scalars['ID']
}

export type MutationCreateBookmarkForCollectionArgs = {
  collectionID: Scalars['ID']
}

export type MutationRemoveBookmarkArgs = {
  bookmarkID: Scalars['ID']
}

export type MutationRemoveBookmarkByLibraryItemIdArgs = {
  libraryItemID: Scalars['ID']
}

export type MutationAdmin_CreateOnDemandSectionArgs = {
  input: CreateOnDemandSectionInput
}

export type MutationAdmin_UpdateOnDemandSectionArgs = {
  input: UpdateOnDemandSectionInput
}

export type MutationAdmin_DeleteOnDemandSectionArgs = {
  input: DeleteOnDemandSectionInput
}

export type MutationAdmin_ChangeOnDemandSectionsOrderArgs = {
  input: Array<ChangeOnDemandSectionsOrderInput>
}

export type MutationAdmin_CreateOnDemandCollectionSectionArgs = {
  input: CreateOnDemandCollectionSectionInput
}

export type MutationAdmin_UpdateOnDemandCollectionSectionArgs = {
  input: UpdateOnDemandCollectionSectionInput
}

export type MutationAdmin_DeleteOnDemandCollectionSectionArgs = {
  input: DeleteOnDemandCollectionSectionInput
}

export type MutationAdmin_ChangeOnDemandCollectionSectionsOrderArgs = {
  input: Array<ChangeOnDemandCollectionSectionsOrderInput>
}

export type MutationAdmin_CreateOnDemandCollectionArgs = {
  input: CreateOnDemandCollectionInput
}

export type MutationAdmin_UpdateOnDemandCollectionArgs = {
  input: UpdateOnDemandCollectionInput
}

export type MutationAdmin_DeleteOnDemandCollectionArgs = {
  input: DeleteOnDemandCollectionInput
}

export type MutationAdmin_ChangeOnDemandCollectionOrderArgs = {
  input: Array<ChangeOnDemandCollectionsOrderInput>
}

export type MutationCreateRatingForClassSessionArgs = {
  input: CreateRatingForClassSessionInput
}

export type MutationCreateRatingForLibraryItemArgs = {
  input: CreateRatingForLibraryItemInput
}

export type MutationAdmin_UpdateFuelBarItemArgs = {
  input: UpdateFuelBarItemInput
}

export type MutationCreateReservationArgs = {
  input: CreateReservationInput
}

export type MutationCreateReservationForFreeClassArgs = {
  input: CreateFreeClassReservationInput
}

export type MutationCreateGuestReservationArgs = {
  input: CreateGuestReservationInput
}

export type MutationCreateGuestReservationForFreeClassArgs = {
  input: CreateFreeClassGuestReservationInput
}

export type MutationChangeRoomArgs = {
  input: ChangeRoomInput
}

export type MutationCancelReservationArgs = {
  reservationID: Scalars['ID']
}

export type MutationCheckInReservationArgs = {
  reservationID: Scalars['ID']
}

export type MutationCheckOutReservationArgs = {
  reservationID: Scalars['ID']
}

export type MutationCreateWorkoutSummaryArgs = {
  input: CreateWorkoutSummaryInput
}

export type MutationCreateWorkoutSummary_V2Args = {
  input: CreateWorkoutSummaryInput_V2
}

export type MutationShareWorkoutSummaryWithFitfamArgs = {
  input: ShareWorkoutSummaryInput
}

export type MutationDeleteFeedItemArgs = {
  feedID: Scalars['String']
}

export type MutationAddReactionToFeedArgs = {
  input?: Maybe<ReactionInput>
}

export type MutationRemoveReactionFromFeedArgs = {
  input?: Maybe<ReactionInput>
}

export type MutationAdmin_CreateBannerArgs = {
  input: CreateBannerInput
}

export type MutationAdmin_UpdateBannerArgs = {
  input: UpdateBannerInput
}

export type MutationAdmin_DeleteBannerArgs = {
  input: DeleteBannerInput
}

export type MutationAdmin_ChangeBannersOrderArgs = {
  input: Array<ChangeBannersOrderInput>
}

export type MutationSaveVideoAnalyticsEventArgs = {
  input: SaveEventInput
}

export type MutationSaveVideoAnalyticsWithMetadataEventArgs = {
  input: SaveEventInput
}

export type MutationConnectClientArgs = {
  input: ConnectClientInput
}

export type MutationDisconnectClientArgs = {
  input: DisconnectClientInput
}

export type MutationCreateLiveSessionArgs = {
  input: CreateLiveSessionInput
}

export type MutationStartMusicPlaybackArgs = {
  input: StartMusicPlaybackInput
}

export type MutationUpdateMusicPlaybackTimesArgs = {
  input: UpdateMusicPlaybackInput
}

export type MutationSendMessageArgs = {
  input: SendMessageInput
}

export type MutationFinishSessionArgs = {
  input: FinishSessionInput
}

export type MutationForceSoundOffArgs = {
  input: ForceSoundOffInput
}

export type MutationForceVideoOffArgs = {
  input: ForceVideoOffInput
}

export type MutationBanUserFromSessionArgs = {
  input: BanUserFromSessionInput
}

export type MutationToggleRedFilterArgs = {
  input: ToggleRedFilterInput
}

export type MutationChangeVideoPreferenceArgs = {
  input: ChangeVideoPreferenceInput
}

export type MutationMuteAllArgs = {
  input: MuteAllInput
}

export type MutationSendRoomMessageArgs = {
  input: SendRoomMessageInput
}

export type MutationChangeRoomStatusToPreparationArgs = {
  input: ChangeRoomStatusToPreparationInput
}

export type MutationChangeRoomStatusToActiveArgs = {
  input: ChangeRoomStatusToActiveInput
}

export type MutationCancelUserMembershipArgs = {
  membershipID: Scalars['ID']
}

export type MutationCancelUserMembership_V2Args = {
  input: CancelUserMembershipInput
}

export type MutationAdmin_CreateInstructorBannerArgs = {
  input: CreateInstructorBannerInput
}

export type MutationAdmin_UpdateInstructorBannerArgs = {
  input: UpdateInstructorBannerInput
}

export type MutationAdmin_DeleteInstructorBannerArgs = {
  input: DeleteInstructorBannerInput
}

export type MutationAdmin_ChangeInstructorBannersOrderArgs = {
  input: Array<ChangeInstructorBannersOrderInput>
}

export type MuteAllInput = {
  liveSessionID: Scalars['ID']
  value: Scalars['Boolean']
}

export type Notification = {
  __typename?: 'Notification'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  type: NotificationTypeEnum
  user: User
  title: Scalars['String']
  body: Scalars['String']
  isUnread: Scalars['Boolean']
  /** User on notification context */
  friend?: Maybe<User>
  /** Class Session on notification context */
  classSession?: Maybe<ClassSession>
  /** Campaign on notification context */
  campaign?: Maybe<Campaign>
}

/** NotificationSettingTypeEnum desc */
export enum NotificationSettingTypeEnum {
  NewFriendRequest = 'NEW_FRIEND_REQUEST',
  FriendRequestAccepted = 'FRIEND_REQUEST_ACCEPTED',
  ClassInvitationFromFriend = 'CLASS_INVITATION_FROM_FRIEND',
  ClassPurchasedFromFriend = 'CLASS_PURCHASED_FROM_FRIEND',
  FriendAttendingClass = 'FRIEND_ATTENDING_CLASS',
  UpcomingClass = 'UPCOMING_CLASS',
}

/** NotificationTypeEnum desc */
export enum NotificationTypeEnum {
  FriendshipRequested = 'FRIENDSHIP_REQUESTED',
  CancelFriendshipRequest = 'CANCEL_FRIENDSHIP_REQUEST',
  FriendshipAccepted = 'FRIENDSHIP_ACCEPTED',
  FriendInvitedClass = 'FRIEND_INVITED_CLASS',
  FriendPurchasedClass = 'FRIEND_PURCHASED_CLASS',
  FriendJoinedClass = 'FRIEND_JOINED_CLASS',
  ClassStarting = 'CLASS_STARTING',
  ProfileImageRemoved = 'PROFILE_IMAGE_REMOVED',
  Campaign = 'CAMPAIGN',
  UserCreditsUpdated = 'USER_CREDITS_UPDATED',
  JoinChallenge = 'JOIN_CHALLENGE',
  MilestoneReached = 'MILESTONE_REACHED',
  ClassMilestone = 'CLASS_MILESTONE',
  LoyaltyTierChanged = 'LOYALTY_TIER_CHANGED',
  Test = 'TEST',
}

/** OnDemandCategoryEnum desc */
export enum OnDemandCategoryEnum {
  ForYou = 'FOR_YOU',
  Audio = 'AUDIO',
}

export type OnDemandCollectionEntity = {
  __typename?: 'OnDemandCollectionEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  section: OnDemandCollectionSectionEntity
  imageURL: Scalars['String']
  order: Scalars['Int']
  collectionItems: Array<OnDemandCollectionItemEntity>
  collectionItemsCount: Scalars['Int']
}

export type OnDemandCollectionEntityCollectionItemsArgs = {
  input?: Maybe<PaginationInput>
}

export type OnDemandCollectionItemEntity = {
  __typename?: 'OnDemandCollectionItemEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  collection: OnDemandCollectionEntity
  libraryItem: Library
  order: Scalars['Int']
}

export type OnDemandCollectionSectionEntity = {
  __typename?: 'OnDemandCollectionSectionEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  order: Scalars['Int']
  collections: Array<OnDemandCollectionEntity>
}

export type OnDemandCollectionSectionEntityCollectionsArgs = {
  input?: Maybe<PaginationInput>
}

export type OnDemandSectionEntity = {
  __typename?: 'OnDemandSectionEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  category: ListOnDemandCategory
  order: Scalars['Int']
  items: Array<OnDemandSectionItemEntity>
}

export type OnDemandSectionEntityItemsArgs = {
  input?: Maybe<PaginationInput>
}

export type OnDemandSectionFilter = {
  category?: Maybe<OnDemandCategoryEnum>
}

export type OnDemandSectionItemEntity = {
  __typename?: 'OnDemandSectionItemEntity'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  section: OnDemandSectionEntity
  libraryItem: Library
  order: Scalars['Int']
  deletedAt: Scalars['DateTime']
}

export type PaginationInput = {
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
}

export type PasswordResetInput = {
  email: Scalars['String']
}

export type PasswordResetResult = {
  __typename?: 'PasswordResetResult'
  email: Scalars['String']
}

export type PaymentOption = {
  __typename?: 'PaymentOption'
  id: Scalars['ID']
  name: Scalars['String']
  description: Scalars['String']
  count: Scalars['Int']
  expirationDateTime: Scalars['DateTime']
  isActive: Scalars['Boolean']
}

/** Platform enum */
export enum PlatformEnum {
  Android = 'ANDROID',
  Ios = 'IOS',
}

export type PlaylistObject = {
  __typename?: 'PlaylistObject'
  id: Scalars['String']
  name: Scalars['String']
  tracks: Array<TrackObject>
}

/** User profile privacy setting */
export enum PrivacyEnum {
  Public = 'PUBLIC',
  Friends = 'FRIENDS',
  Private = 'PRIVATE',
}

export type ProductClass = {
  __typename?: 'ProductClass'
  name: Scalars['String']
  description: Scalars['String']
  items: Array<ProductClassItem>
}

export type ProductClassItem = {
  __typename?: 'ProductClassItem'
  id: Scalars['ID']
  description: Scalars['String']
  allowGuests: Scalars['Boolean']
  expirationInterval: TimePeriodEnum
  expirationLength: Scalars['Int']
}

export type ProductMembership = {
  __typename?: 'ProductMembership'
  name: Scalars['String']
  description: Scalars['String']
  items: Array<ProductMembershipItem>
}

export type ProductMembershipItem = {
  __typename?: 'ProductMembershipItem'
  id: Scalars['ID']
  description: Scalars['String']
  allowGuests: Scalars['Boolean']
  expirationInterval: TimePeriodEnum
  expirationLength: Scalars['Int']
}

export type Purchase = {
  __typename?: 'Purchase'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  platform: PlatformEnum
  productID: Scalars['String']
  expirationDate?: Maybe<Scalars['DateTime']>
  trialEndsAt?: Maybe<Scalars['DateTime']>
  usageIntervalLimit?: Maybe<Scalars['Int']>
  hasVODAccess: Scalars['Boolean']
}

export type PurchaseHistoryResult = {
  __typename?: 'PurchaseHistoryResult'
  orderNumber: Scalars['String']
  amount: Scalars['Float']
  currency: Scalars['String']
  date: Scalars['DateTime']
  summary: Array<Scalars['String']>
}

export type PurchaseIapHistoryResult = {
  __typename?: 'PurchaseIAPHistoryResult'
  orderNumber?: Maybe<Scalars['String']>
  amount: Scalars['Float']
  currency: Scalars['String']
  date: Scalars['DateTime']
  summary: Array<Scalars['String']>
}

export enum PurchaseTypeEnum {
  Consumable = 'CONSUMABLE',
  Subscription = 'SUBSCRIPTION',
}

export type Query = {
  __typename?: 'Query'
  health: Scalars['String']
  currentTime: Scalars['DateTime']
  /**
   * Lists all friendships of the user.
   * @deprecated Removed to allow for sorting by firstName and lastName
   */
  listFriendships: Array<Friendship>
  /** Lists all friendships of the user. */
  listFriendships_v2: Array<Friendship>
  /** Lists all friendships of the user showing who's already attending the classe of the classSessionId provided on input. */
  listFriendshipsAttendingClass: Array<FriendAttendingClass>
  listNotifications: Array<Notification>
  getNotification: Notification
  /** Get the number of unread notifications for the current user. */
  getUnreadNotificationsCount: Scalars['Int']
  /** Get information about the current user. */
  getUser: User
  /** Search users. */
  listUsers: Array<User>
  /** Search users and show if they are attending the class. */
  listUsersAttendingClass: Array<UserAttendingClass>
  /** Checks if a username is available or not. */
  usernameExists: Scalars['Boolean']
  /** Lists all admin users (admin only) */
  admin_listAdmins: Array<User>
  /** Get information about selected user (admin only) */
  admin_getUser: User
  /** List all users (admin only) */
  admin_listUsers: Array<User>
  /** Return history of all purchases directly in MT API for current user */
  listUserOrders: Array<PurchaseHistoryResult>
  /** Return history of all in-app purchases for current user */
  listUserIAPOrders: Array<PurchaseIapHistoryResult>
  hasUserUnlimitedMembership: Scalars['Boolean']
  /** Get information about the current user, section Personal. */
  getUserPersonal: UserPersonal
  /** Get information about the current user, section Social section. */
  getUserSocial: UserSocial
  /** Get information about the current user, section Emergency. */
  getUserEmergency: UserEmergency
  /** Get information about the current user, section Address section. */
  getUserAddress: UserAddress
  /** Lists all available Report reasons for creating a report. */
  listReportReasons: Array<ListReportReason>
  /** Lists all reports matching the filter (Admin only). */
  admin_listReports: Array<Report>
  admin_getReport: Report
  /** Lists the user's favorite Instructors, sorted by attendance in classes. */
  listFavoriteInstructors: Array<Instructor>
  /** Get details about a single Instructor. */
  getInstructor: Instructor
  /** Lists all active instructors. */
  listInstructors: Array<Instructor>
  /** [v2] Lists all active instructors. */
  listInstructors_v2: Array<Instructor>
  /** Count all VOD instructors. */
  countVODInstructors: Scalars['Int']
  /** Return settings entity for current user */
  getSettingsForUser: Setting
  /** List all Classes (Class Sessions). */
  listClassSessions: Array<ClassSession>
  /** List all Classes for admin (Class Sessions). */
  admin_listClassSessions: Array<ClassSession>
  /** List Classes reserved by the user */
  listUserClassSessions: Array<ClassSession>
  /** Gets information about a single Class */
  getClassSession: ClassSession
  /** Check if classSessionID is valid and class session for the ID exists */
  validateClassSessionID: ValidateClassSessionIdResult
  /** List all available equipments for library items */
  listEquipmentForClassSession: Array<ListEquipment>
  /** List purchases of a user filtered by type */
  listUserPurchases: Array<Purchase>
  /** List all devices of the user */
  listDevices: Array<Device>
  /** List the device information stored in the db. */
  listDeviceInfos: Array<DeviceInfo>
  /** Fetch gympass user info. */
  getGympassUserData: GympassUser
  /** Get detail about single Campaign */
  getCampaign: Campaign
  /** list all Campaigns (admin only) */
  admin_listCampaigns: Array<Campaign>
  /** Get detail about single Campaign (admin only) */
  admin_getCampaign: Campaign
  /** List available products (credits, memberships, etc.) */
  listProducts: Catalogue
  /** Get details about a single employee. */
  admin_getEmployee: Employee
  /** Lists all employees */
  admin_listEmployees: Array<Employee>
  /** Lists shopify picks for employee */
  admin_listEmployeeShopifyPicks: Array<ShopifyPicks>
  /** Get public profile for selected employee */
  getEmployee: GetEmployeeResult
  /** List employees */
  listEmployees: Array<Employee>
  /** List all studios matching the filters */
  admin_listStudios: Array<AdminStudioResult>
  /** Get detail of selected studio */
  admin_getStudio: AdminStudioResult
  /** List all available body parts for library items */
  listBodyParts: Array<ListBodyPart>
  /** List all available body part filters for library items */
  listBodyPartsFilters: Array<ListBodyPartFilter>
  /** List all available equipments for library items */
  listEquipment: Array<ListEquipment>
  /** List all available equipment filters for library items */
  listEquipmentFilters: Array<ListEquipmentFilter>
  /** List all available equipment filters for library items */
  listEquipmentFilters_v2: Array<ListEquipmentFilter>
  /** List all available library item labels */
  listLibraryItemLabels: Array<ListLibraryItemLabel>
  /** List all available library item types */
  listLibraryItemTypes: Array<ListLibraryItemType>
  /** List all available library item durations */
  listLibraryItemDurations: Array<ListLibraryItemDuration>
  /** List all available filters for library items */
  listVodFilters: ListVodFilters
  /** List all available filters for a particular instructor */
  listInstructorVodFilters: ListInstructorVodFilters
  /** List all library items matching selected filters */
  listLibraryItems: Array<Library>
  /** Get detail about selected library item (admin only) */
  admin_getLibraryItem: Library
  /** Get detail about selected library item */
  getLibraryItem: Library
  /** List all library items matching selected filters (admin only) */
  admin_listLibraryItems: Array<Library>
  /** Count all library items matching selected filters */
  countLibraryItems: Scalars['Int']
  /** Count all library items matching selected filters */
  admin_countLibraryItems: AdminCountLibraryItems
  /** @deprecated Use getFileURL_v2 instead to be able to select dual audio or mono audio option and also improve selection of stream types */
  getFileURL: Scalars['String']
  getFileURL_v2: FileUrl
  getFileURL_v3: FileUrl_V3
  /** List all available library item categories */
  listLibraryItemCategories: Array<ListLibraryItemCategory>
  /** Fetch user bookmarks. */
  listBookmarks: Array<Bookmark>
  /** List all on demand section matching the filter (admin only) */
  admin_listOnDemandSections: Array<OnDemandSectionEntity>
  /** List all on demand section matching the filter */
  listOnDemandSections: Array<OnDemandSectionEntity>
  /** Get detail of selected section (admin only) */
  admin_getOnDemandSection: OnDemandSectionEntity
  /** Get detail of selected section */
  getOnDemandSection: OnDemandSectionEntity
  /** List all possible categories for on demand sections */
  listOnDemandSectionCategories: Array<ListOnDemandCategory>
  /** List all available collection sections with their collections (admin only) */
  admin_listOnDemandCollectionSections: Array<OnDemandCollectionSectionEntity>
  /** List all available collection sections with their collections */
  listOnDemandCollectionSections: Array<OnDemandCollectionSectionEntity>
  /** Return detail of selected collection (admin only) */
  admin_getOnDemandCollection: OnDemandCollectionEntity
  /** Return detail of selected collection */
  getOnDemandCollection: OnDemandCollectionEntity
  /** Gets Signed URL for uploading public files. */
  getSignedUrl: GetSignedUrlResult
  /** Gets Signed URL for uploading private files. */
  admin_getPrivateSignedUrl: GetSignedUrlResult
  /** Ordered list of rating questions */
  listQuestions: Array<Scalars['String']>
  /** Lists all available Regions */
  listRegions: Array<ListRegionsResult>
  /** Lists all available studios with hierarchy (Country - Region - Studio) */
  listStudios: Array<ListStudiosResult>
  /** List all fuel bar items matching the filters (admin only) */
  admin_listFuelBarItems: Array<FuelBar>
  /** Get detail about single fuel bar item (admin only) */
  admin_getFuelBarItem: FuelBar
  /** Lists Reservations (booked classes) of a user. */
  listReservations: Array<Reservation>
  /** Lists all VOD workout summaries. */
  listWorkoutSummaries: Array<WorkoutSummary>
  /**
   * Lists all the entries of the Fitfam feed.
   * @deprecated To be able to show different types of content use listFeed_v2 instead
   */
  listFeed: Array<Feed>
  /** Lists all the entries of the Fitfam feed. */
  listFeed_v2: Array<Fitfam>
  /** Get all users that have reacted to a feed */
  getUsersForReaction: Array<User>
  /** List all banners matching the filters (admin only) */
  admin_listBanners: Array<Banner>
  /** List all banners grouped by category */
  listGroupedBanners: Array<ListGroupedBannersResult>
  /** Get detail about single banner (admin only) */
  admin_getBanner: Banner
  /** List all available banner categories (admin only) */
  admin_listBannerCategories: Array<ListBannerCategory>
  /** Get Statistical data of a user for the Stats screen. */
  getUserStats: UserStatsResult
  /** Gets the attendance of classes of a user in a chart-friendly format. */
  getUserAttendance: Array<UserAttendanceResult>
  /** List of visited studios. */
  listVisitedStudios: Array<Studio>
  getLiveSession: LiveSession
  getTwilioToken: GetTwilioTokenResult
  /** List all the active Class Session types. */
  listClassSessionTypes: Array<ClassSessionType>
  /** List Payment Options for a given class */
  listPaymentOptions: Array<PaymentOption>
  /** Returns the remaining amount of credits for the current user */
  getUserCreditsCount: Scalars['Int']
  /** List the credit packages the user has purchased */
  listUserCredits: Array<Credit>
  /** Get the penalty for canceling credit transaction */
  getCreditTransactionPenalty: GetCreditTransactionPenaltyResult
  /** List active memberships from the current user */
  listUserMemberships: Array<Membership>
  /** Get the penalty for canceling membership transaction */
  getMembershipTransactionPenalty: GetMembershipTransactionPenaltyResult
  /** Returns the remaining amount of membership usages for the current user */
  getUserMembershipUsageCount: Scalars['Int']
  /** Returns the default booking window */
  getDefaultBookingWindow: BookingWindowEntity
  /** Lists all available Countries */
  listCountries: Array<Country>
  /** List all instructor banners matching the filters (admin only) */
  admin_listInstructorBanners: Array<InstructorBanner>
  /** Get detail about single instructor banner (admin only) */
  admin_getInstructorBanner: InstructorBanner
  /** Lists all frequency challenges */
  listFrequencyChallenges: Array<Challenge>
  /** Lists current frequency challenges */
  listCurrentFrequencyChallenges: Array<Challenge>
  /** Lists past frequency challenges */
  listPastFrequencyChallenges: Array<Challenge>
  /** Spotify: Get access token with duration of 1 hour to spotify api requests. */
  getSpotifyAccessToken: SpotifyAccessToken
  /** Spotify: Access tokens are deliberately set to expire after a short time, after which new tokens may be granted by supplying the refresh token originally obtained during the authorization code exchange. */
  getSpotifyRefreshToken: SpotifyRefreshToken
  /** Spotify: get playlist information from spotify api. */
  getSpotifyPlaylist: SpotifyPlaylistResponse
  /** Apple music jwt token. */
  getAppleMusicToken: Token
}

export type QueryListFriendshipsArgs = {
  input?: Maybe<ListFriendshipInput>
}

export type QueryListFriendships_V2Args = {
  input?: Maybe<ListFriendshipInput>
}

export type QueryListFriendshipsAttendingClassArgs = {
  input?: Maybe<ListFriendshipAttendingClass>
}

export type QueryListNotificationsArgs = {
  input: PaginationInput
}

export type QueryGetNotificationArgs = {
  notificationID: Scalars['ID']
}

export type QueryGetUserArgs = {
  id: Scalars['ID']
}

export type QueryListUsersArgs = {
  input: ListUserInput
}

export type QueryListUsersAttendingClassArgs = {
  input: ListUserInputAttendingClass
}

export type QueryUsernameExistsArgs = {
  username: Scalars['String']
}

export type QueryAdmin_GetUserArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_ListUsersArgs = {
  input: AdminListUsersInput
}

export type QueryListUserOrdersArgs = {
  input: ListUserOrdersInput
}

export type QueryListUserIapOrdersArgs = {
  input: ListUserOrdersInput
}

export type QueryGetUserPersonalArgs = {
  id: Scalars['ID']
}

export type QueryGetUserSocialArgs = {
  id: Scalars['ID']
}

export type QueryGetUserEmergencyArgs = {
  id: Scalars['ID']
}

export type QueryGetUserAddressArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_ListReportsArgs = {
  input?: Maybe<ListReportsInput>
}

export type QueryAdmin_GetReportArgs = {
  id: Scalars['ID']
}

export type QueryListFavoriteInstructorsArgs = {
  userID?: Maybe<Scalars['ID']>
}

export type QueryGetInstructorArgs = {
  id: Scalars['ID']
}

export type QueryListInstructorsArgs = {
  input: ListInstructorsInput
}

export type QueryListInstructors_V2Args = {
  input: ListInstructorsInput_V2
}

export type QueryCountVodInstructorsArgs = {
  input: CountVodInstructorsInput
}

export type QueryListClassSessionsArgs = {
  input: ListClassSessionInput
}

export type QueryAdmin_ListClassSessionsArgs = {
  input: AdminListClassSessionsInput
}

export type QueryListUserClassSessionsArgs = {
  input: ListUserClassSessionsInput
}

export type QueryGetClassSessionArgs = {
  id: Scalars['ID']
}

export type QueryValidateClassSessionIdArgs = {
  classSessionID: Scalars['ID']
}

export type QueryListUserPurchasesArgs = {
  input: ListUserPurchasesInput
}

export type QueryListDevicesArgs = {
  userID: Scalars['ID']
}

export type QueryListDeviceInfosArgs = {
  input?: Maybe<ListDeviceInfoInput>
}

export type QueryGetGympassUserDataArgs = {
  gympassUID: Scalars['String']
}

export type QueryGetCampaignArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_ListCampaignsArgs = {
  input?: Maybe<ListCampaignsInput>
}

export type QueryAdmin_GetCampaignArgs = {
  id: Scalars['ID']
}

export type QueryListProductsArgs = {
  input: ListProductsInput
}

export type QueryAdmin_GetEmployeeArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_ListEmployeesArgs = {
  input: ListEmployeesInput
}

export type QueryAdmin_ListEmployeeShopifyPicksArgs = {
  employeeID: Scalars['ID']
}

export type QueryGetEmployeeArgs = {
  username: Scalars['String']
}

export type QueryListEmployeesArgs = {
  input: ListEmployeesPublicInput
}

export type QueryAdmin_ListStudiosArgs = {
  input?: Maybe<AdminListStudiosInput>
}

export type QueryAdmin_GetStudioArgs = {
  id: Scalars['ID']
}

export type QueryListBodyPartsFiltersArgs = {
  input?: Maybe<ListVodFiltersInput>
}

export type QueryListEquipmentArgs = {
  input?: Maybe<ListVodFiltersInput>
}

export type QueryListEquipmentFiltersArgs = {
  input?: Maybe<ListVodFiltersInput>
}

export type QueryListEquipmentFilters_V2Args = {
  input?: Maybe<ListVodFiltersInput>
}

export type QueryListVodFiltersArgs = {
  input: ListVodFiltersInput
}

export type QueryListInstructorVodFiltersArgs = {
  input: ListInstructorVodFiltersInput
}

export type QueryListLibraryItemsArgs = {
  input?: Maybe<ListLibraryItemsInput>
}

export type QueryAdmin_GetLibraryItemArgs = {
  id: Scalars['ID']
}

export type QueryGetLibraryItemArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_ListLibraryItemsArgs = {
  input?: Maybe<AdminListLibraryItemsInput>
}

export type QueryCountLibraryItemsArgs = {
  input?: Maybe<ListLibraryItemsInput>
}

export type QueryAdmin_CountLibraryItemsArgs = {
  input?: Maybe<AdminListLibraryItemsInput>
}

export type QueryGetFileUrlArgs = {
  input: GetFileUrlInput
}

export type QueryGetFileUrl_V2Args = {
  input: GetFileUrlInput_V2
}

export type QueryGetFileUrl_V3Args = {
  input: GetFileUrlInput_V3
}

export type QueryListLibraryItemCategoriesArgs = {
  input?: Maybe<GetLibraryItemCategoriesTotalInput>
}

export type QueryListBookmarksArgs = {
  input?: Maybe<ListUserBookmarksInput>
}

export type QueryAdmin_ListOnDemandSectionsArgs = {
  input?: Maybe<ListOnDemandSectionInput>
}

export type QueryListOnDemandSectionsArgs = {
  input?: Maybe<ListOnDemandSectionInput>
}

export type QueryAdmin_GetOnDemandSectionArgs = {
  id: Scalars['ID']
}

export type QueryGetOnDemandSectionArgs = {
  id: Scalars['ID']
}

export type QueryAdmin_GetOnDemandCollectionArgs = {
  id: Scalars['ID']
}

export type QueryGetOnDemandCollectionArgs = {
  id: Scalars['ID']
}

export type QueryGetSignedUrlArgs = {
  options: GetSignedUrlInput
}

export type QueryAdmin_GetPrivateSignedUrlArgs = {
  input: GetSignedUrlInput
}

export type QueryListRegionsArgs = {
  input?: Maybe<ListRegionsInput>
}

export type QueryAdmin_ListFuelBarItemsArgs = {
  input?: Maybe<ListFuelBarItemsInput>
}

export type QueryAdmin_GetFuelBarItemArgs = {
  id: Scalars['ID']
}

export type QueryListReservationsArgs = {
  input: ListReservationsInput
}

export type QueryListWorkoutSummariesArgs = {
  input: ListWorkoutSummaryInput
}

export type QueryListFeedArgs = {
  input?: Maybe<ListFeedInput>
}

export type QueryListFeed_V2Args = {
  input?: Maybe<ListFeedInput>
}

export type QueryGetUsersForReactionArgs = {
  input?: Maybe<ReactionInput>
}

export type QueryAdmin_ListBannersArgs = {
  input?: Maybe<ListBannersInput>
}

export type QueryAdmin_GetBannerArgs = {
  id: Scalars['ID']
}

export type QueryGetUserStatsArgs = {
  userID?: Maybe<Scalars['ID']>
}

export type QueryGetUserAttendanceArgs = {
  userID: Scalars['ID']
  format: Scalars['String']
}

export type QueryListVisitedStudiosArgs = {
  input?: Maybe<ListStudiosInput>
}

export type QueryGetLiveSessionArgs = {
  input: GetLiveSessionInput
}

export type QueryGetTwilioTokenArgs = {
  input: GetTwilioTokenInput
}

export type QueryListClassSessionTypesArgs = {
  input?: Maybe<ListClassSessionTypesInput>
}

export type QueryListPaymentOptionsArgs = {
  input: ListPaymentOptionsInput
}

export type QueryListUserCreditsArgs = {
  input: PaginationInput
}

export type QueryGetCreditTransactionPenaltyArgs = {
  input: GetCreditTransactionPenaltyInput
}

export type QueryListUserMembershipsArgs = {
  input: PaginationInput
}

export type QueryGetMembershipTransactionPenaltyArgs = {
  input: GetMembershipTransactionPenaltyInput
}

export type QueryAdmin_ListInstructorBannersArgs = {
  input?: Maybe<ListInstructorBannersInput>
}

export type QueryAdmin_GetInstructorBannerArgs = {
  id: Scalars['ID']
}

export type QueryGetSpotifyAccessTokenArgs = {
  input: AccessTokenInput
}

export type QueryGetSpotifyRefreshTokenArgs = {
  input: RefreshTokenInput
}

export type QueryGetSpotifyPlaylistArgs = {
  input: GetSpotifyPlaylistInput
}

export enum RatingLevel {
  Bad = 'BAD',
  Meh = 'MEH',
  Cool = 'COOL',
  Good = 'GOOD',
  Great = 'GREAT',
}

/** Reactions for fitfam items */
export enum Reaction {
  Dead = 'DEAD',
  Fire = 'FIRE',
  Heart = 'HEART',
  Muscle = 'MUSCLE',
}

export type ReactionInput = {
  type: Reaction
  feedID: Scalars['String']
}

export type ReactionResponse = {
  __typename?: 'ReactionResponse'
  count: Scalars['Float']
  included: Scalars['Boolean']
}

export type ReactionUpdateResponse = {
  __typename?: 'ReactionUpdateResponse'
  success: Scalars['Boolean']
}

export type Reactions = {
  __typename?: 'Reactions'
  DEAD: ReactionResponse
  FIRE: ReactionResponse
  HEART: ReactionResponse
  MUSCLE: ReactionResponse
}

export type ReadAllNotificationsResult = {
  __typename?: 'ReadAllNotificationsResult'
  success: Scalars['Boolean']
}

export type RefreshToken = {
  __typename?: 'RefreshToken'
  accessToken: Scalars['String']
  tokenType: Scalars['String']
  expiresIn: Scalars['Float']
  scope: Scalars['String']
}

export type RefreshTokenInput = {
  refreshToken: Scalars['String']
}

export type Region = {
  __typename?: 'Region'
  /** MarianaTek Region ID */
  id: Scalars['ID']
  name: Scalars['String']
  country: Country
  locations?: Maybe<Array<Location>>
}

export type RegisterDeviceInput = {
  token: Scalars['String']
  deviceID?: Maybe<Scalars['ID']>
  platform?: Maybe<PlatformEnum>
}

export type Report = {
  __typename?: 'Report'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  reporting: User
  reported: User
  seen: Scalars['Boolean']
  resolved: Scalars['Boolean']
  reason: ReportReason
}

export type ReportFilter = {
  reported?: Maybe<Scalars['String']>
  resolved?: Maybe<Scalars['Boolean']>
}

export type ReportReason = {
  __typename?: 'ReportReason'
  name: Scalars['String']
  type: Scalars['String']
}

/** Report type */
export enum ReportReasonEnum {
  InappropriatePicture = 'INAPPROPRIATE_PICTURE',
  InappropriateProfile = 'INAPPROPRIATE_PROFILE',
  InappropriateBehavior = 'INAPPROPRIATE_BEHAVIOR',
  FakeProfile = 'FAKE_PROFILE',
}

export type RequestFriendshipInput = {
  /** ID of the user being added as friend */
  userID: Scalars['ID']
}

export type Reservation = {
  __typename?: 'Reservation'
  id: Scalars['ID']
  streamURL?: Maybe<Scalars['String']>
  streamWindow?: Maybe<Scalars['Float']>
  reservationType: Scalars['String']
  status: ReservationStatusEnum
  type: ReservationTypeEnum
  checkInDate?: Maybe<Scalars['String']>
  creationDate: Scalars['String']
  classSession: ClassSession
  /** ID of reserved spot. Null if user is on "waiting list" */
  spotID?: Maybe<Scalars['String']>
  /** Name of reserved spot */
  spotName?: Maybe<Scalars['String']>
  membershipTransactions: Array<Transaction>
  creditTransactions: Array<Transaction>
  roomIndex?: Maybe<Scalars['Int']>
}

/** Reservation Sorting */
export enum ReservationSortingEnum {
  ClassSessionStartDatetimeAsc = 'CLASS_SESSION_START_DATETIME_ASC',
  ClassSessionStartDatetimeDesc = 'CLASS_SESSION_START_DATETIME_DESC',
}

/** ReservationStatusEnum desc */
export enum ReservationStatusEnum {
  Pending = 'PENDING',
  CheckIn = 'CHECK_IN',
  StandardCancel = 'STANDARD_CANCEL',
  PenaltyCancel = 'PENALTY_CANCEL',
  GracedCancel = 'GRACED_CANCEL',
  PenaltyNoShow = 'PENALTY_NO_SHOW',
  GracedNoShow = 'GRACED_NO_SHOW',
  Removed = 'REMOVED',
  Penalty = 'PENALTY',
  ClassCancelled = 'CLASS_CANCELLED',
  Upcoming = 'UPCOMING',
  Inactive = 'INACTIVE',
}

/** ReservationTimeEnum desc */
export enum ReservationTimeEnum {
  Upcoming = 'UPCOMING',
  Past = 'PAST',
}

/** ReservationTypeEnum desc */
export enum ReservationTypeEnum {
  Standard = 'STANDARD',
  Standby = 'STANDBY',
  Waitlist = 'WAITLIST',
}

export type RevokeVodAccessResult = {
  __typename?: 'RevokeVODAccessResult'
  success: Scalars['Boolean']
}

export type RevokeVodAccessToUserInput = {
  userID: Scalars['ID']
}

/** RoleEnum desc */
export enum RoleEnum {
  User = 'USER',
  Moderator = 'MODERATOR',
  Admin = 'ADMIN',
}

export type SaveEventInput = {
  idempotencyKey: Scalars['String']
  playedTime: Scalars['Float']
  libraryItemID: Scalars['ID']
}

export type SaveEventObject = {
  __typename?: 'SaveEventObject'
  result: Scalars['String']
  watched: Scalars['Boolean']
}

export type SendEmailToReportedUserInput = {
  userToID: Scalars['ID']
  message: Scalars['String']
}

export type SendEmailToReportedUserResult = {
  __typename?: 'SendEmailToReportedUserResult'
  success: Scalars['Boolean']
}

export type SendEmailToUserInput = {
  email: Scalars['String']
  message: Scalars['String']
}

export type SendEmailToUserResult = {
  __typename?: 'SendEmailToUserResult'
  success: Scalars['Boolean']
}

export type SendMessageInput = {
  id: Scalars['ID']
  message: Scalars['String']
}

export type SendRoomMessageInput = {
  /** Live session room ID */
  id: Scalars['ID']
  message: Scalars['String']
  /** User ID who should receive the message */
  recipientID?: Maybe<Scalars['ID']>
}

export type SessionObject = {
  __typename?: 'SessionObject'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
  /** Ex.: 2020-10-21T12:27:36.826Z */
  expiresAt: Scalars['String']
}

export type Setting = {
  __typename?: 'Setting'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  user: User
  newFriendRequest: Scalars['Boolean']
  friendRequestAccepted: Scalars['Boolean']
  classInvitationFromFriend: Scalars['Boolean']
  classPurchasedFromFriend: Scalars['Boolean']
  friendAttendingClass: Scalars['Boolean']
  upcomingClass: Scalars['Boolean']
}

export type ShareWorkoutSummaryInput = {
  workoutSummaryID: Scalars['String']
}

export type ShareWorkoutSummaryResult = {
  __typename?: 'ShareWorkoutSummaryResult'
  success: Scalars['Boolean']
}

export type ShopifyPickInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  url: Scalars['String']
  pictureUrl: Scalars['String']
}

export type ShopifyPicks = {
  __typename?: 'ShopifyPicks'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  name: Scalars['String']
  url: Scalars['String']
  pictureUrl: Scalars['String']
}

export type ShopifyPicksResult = {
  __typename?: 'ShopifyPicksResult'
  name: Scalars['String']
  url: Scalars['String']
  pictureUrl: Scalars['String']
}

export type SignInInput = {
  username: Scalars['String']
  password: Scalars['String']
}

export type SortInput = {
  column: Scalars['String']
  direction?: Maybe<Direction>
}

export type SpotifyAccessToken = {
  __typename?: 'SpotifyAccessToken'
  spotifyAccessToken: AccessToken
}

export type SpotifyExternalUrl = {
  __typename?: 'SpotifyExternalURL'
  spotify?: Maybe<Scalars['String']>
}

export type SpotifyImages = {
  __typename?: 'SpotifyImages'
  height?: Maybe<Scalars['Float']>
  width?: Maybe<Scalars['Float']>
  url?: Maybe<Scalars['String']>
}

export type SpotifyPlaylist = {
  __typename?: 'SpotifyPlaylist'
  playlistID: Scalars['String']
  imageURL: Scalars['String']
  title: Scalars['String']
}

export type SpotifyPlaylistInput = {
  playlistID: Scalars['String']
  imageURL: Scalars['String']
  title: Scalars['String']
}

export type SpotifyPlaylistObject = {
  __typename?: 'SpotifyPlaylistObject'
  playlistID: Scalars['String']
  imageURL: Scalars['String']
  title: Scalars['String']
}

export type SpotifyPlaylistResponse = {
  __typename?: 'SpotifyPlaylistResponse'
  id?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  href?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  uri?: Maybe<Scalars['String']>
  external_urls?: Maybe<SpotifyExternalUrl>
  images?: Maybe<Array<SpotifyImages>>
  public?: Maybe<Scalars['Boolean']>
  tracks?: Maybe<SpotifyTracks>
}

export type SpotifyRefreshToken = {
  __typename?: 'SpotifyRefreshToken'
  spotifyRefreshToken: RefreshToken
}

export type SpotifyTracks = {
  __typename?: 'SpotifyTracks'
  total?: Maybe<Scalars['Float']>
  offset?: Maybe<Scalars['Float']>
  limit?: Maybe<Scalars['String']>
  href?: Maybe<Scalars['String']>
  next?: Maybe<Scalars['String']>
  previous?: Maybe<Scalars['String']>
}

export type StartMusicPlaybackInput = {
  classSessionID: Scalars['String']
}

export type StartMusicPlaybackResult = {
  __typename?: 'StartMusicPlaybackResult'
  isSuccessful: Scalars['Boolean']
  message: Scalars['String']
}

export type StoreDeviceInfoInput = {
  deviceID: Scalars['String']
  deviceType: Scalars['String']
  appVersion?: Maybe<Scalars['String']>
  browserType?: Maybe<Scalars['String']>
  browserVersion?: Maybe<Scalars['String']>
  osType: Scalars['String']
  osVersion: Scalars['String']
  locale?: Maybe<Scalars['String']>
}

export type Studio = {
  __typename?: 'Studio'
  numVisited: Scalars['Int']
  region: StudioRegion
}

export type StudioFilter = {
  name?: Maybe<Scalars['String']>
}

export type StudioLocation = {
  __typename?: 'StudioLocation'
  name: Scalars['String']
}

export type StudioRegion = {
  __typename?: 'StudioRegion'
  name: Scalars['String']
  location: StudioLocation
}

export enum TimePeriodEnum {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export type ToggleRedFilterInput = {
  roomID: Scalars['ID']
  value: Scalars['Boolean']
}

export type TrackObject = {
  __typename?: 'TrackObject'
  title: Scalars['String']
  artistName: Scalars['String']
  artistImageURL?: Maybe<Scalars['String']>
  trackImageURL?: Maybe<Scalars['String']>
  /** Full duration of the track in seconds */
  duration: Scalars['Int']
  startSecond?: Maybe<Scalars['Float']>
  isrc: Scalars['String']
  labelName: Scalars['String']
  licensorName: Scalars['String']
}

export type Transaction = {
  __typename?: 'Transaction'
  type: Scalars['String']
  id: Scalars['String']
}

export type UnpublishLibraryItemResult = {
  __typename?: 'UnpublishLibraryItemResult'
  success: Scalars['Boolean']
}

export type UnpublishLibraryLibraryItemInput = {
  libraryItemID: Scalars['ID']
}

export type UpcomingClassesResult = {
  __typename?: 'UpcomingClassesResult'
  name: Scalars['String']
  startDateTime: Scalars['DateTime']
  endDateTime: Scalars['DateTime']
  region: Region
  location: Location
}

export type UpdateAdminInput = {
  id: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type UpdateAdminResult = {
  __typename?: 'UpdateAdminResult'
  id: Scalars['ID']
}

export type UpdateBannerInput = {
  id: Scalars['ID']
  name: Scalars['String']
  /** Call to action text */
  CTAText: Scalars['String']
  link: Scalars['String']
  imageURL: Scalars['String']
}

export type UpdateCampaignInput = {
  id: Scalars['ID']
  title: Scalars['String']
  body: Scalars['String']
  imageURL: Scalars['String']
  scheduledDateTime: Scalars['DateTime']
}

export type UpdateEmployeeInput = {
  id: Scalars['String']
  regionID?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
  promoCode?: Maybe<Scalars['String']>
  tiktokUsername?: Maybe<Scalars['String']>
  linkedinUsername?: Maybe<Scalars['String']>
  instagramHandle?: Maybe<Scalars['String']>
  regionName?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  isPublished: Scalars['Boolean']
  emailAddress?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberForTexting?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type UpdateEmployeeShopifyPicksInput = {
  employeeID: Scalars['String']
  shopifyPicks: Array<ShopifyPickInput>
}

export type UpdateFuelBarItemInput = {
  id: Scalars['ID']
  imageURL?: Maybe<Scalars['String']>
}

export type UpdateInstructorBannerInput = {
  id: Scalars['ID']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  /** Call to action text */
  CTAText: Scalars['String']
  link: Scalars['String']
  imageURL: Scalars['String']
  regionID?: Maybe<Scalars['String']>
  locationID?: Maybe<Scalars['String']>
}

export type UpdateLibraryItemInput = {
  id: Scalars['ID']
  title: Scalars['String']
  description: Scalars['String']
  parentCategory?: Maybe<LibraryItemCategoryEnum>
  bodyParts: Array<BodyPartEnum>
  equipment: Array<EquipmentEnum>
  instructorID: Scalars['ID']
  labels?: Maybe<Array<LibraryItemLabelEnum>>
  duration: Scalars['Int']
  type?: Maybe<LibraryItemTypeEnum>
  imageURL: Scalars['String']
  classID: Scalars['String']
  /** Video + music + instructors voice (video file). The data is linked to metadata */
  fileID: Scalars['String']
  /** Video + instructors voice (video file). The data is linked to metadataInstructorFile */
  instructorFileID?: Maybe<Scalars['String']>
  /** Music (audio file) */
  audioID?: Maybe<Scalars['String']>
  playlistID?: Maybe<Scalars['String']>
  playlistTrackInfo?: Maybe<Scalars['String']>
  hasClosedCaptions?: Maybe<Scalars['Boolean']>
  hasClosedCaptionsInstructorFile?: Maybe<Scalars['Boolean']>
  status?: Maybe<LibraryItemStatusEnum>
  statusEffectiveSince?: Maybe<Scalars['DateTime']>
  statusComment?: Maybe<Scalars['String']>
  additionalInstructorID?: Maybe<Scalars['String']>
  classSessionID?: Maybe<Scalars['String']>
  spotifyPlaylistIDs?: Maybe<Array<LibrarySpotifyPlaylistInput>>
  appleMusicPlaylistIDs?: Maybe<Array<Scalars['String']>>
}

export type UpdateMusicPlaybackInput = {
  classSessionID: Scalars['String']
  /** The isrc of the track that the playlist was skipped to. */
  currentIsrc: Scalars['String']
}

export type UpdateNotificationInput = {
  notificationID: Scalars['ID']
  isUnread?: Maybe<Scalars['Boolean']>
}

export type UpdateOnDemandCollectionInput = {
  id: Scalars['ID']
  name: Scalars['String']
  imageURL: Scalars['String']
  libraryItemIDs: Array<Scalars['ID']>
}

export type UpdateOnDemandCollectionSectionInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type UpdateOnDemandSectionInput = {
  id: Scalars['ID']
  name: Scalars['String']
  libraryItemIDs: Array<Scalars['ID']>
}

export type UpdateReportInput = {
  id: Scalars['ID']
  seen: Scalars['Boolean']
  resolved: Scalars['Boolean']
}

export type UpdateReportedUserInput = {
  id: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
}

export type UpdateReportedUserResult = {
  __typename?: 'UpdateReportedUserResult'
  id: Scalars['ID']
}

export type UpdateSettingsInput = {
  type: NotificationSettingTypeEnum
  value: Scalars['Boolean']
}

export type UpdateUserAddressInput = {
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  stateProvince?: Maybe<Scalars['String']>
  country?: Maybe<CountryInput>
  postalCode?: Maybe<Scalars['String']>
}

export type UpdateUserEmergencyInput = {
  emergencyContactName?: Maybe<Scalars['String']>
  emergencyContactRelationship?: Maybe<Scalars['String']>
  /** Ex.: 2010-01-01 */
  emergencyContactEmail?: Maybe<Scalars['String']>
  /** +2348012345678 */
  emergencyContactPhone?: Maybe<Scalars['String']>
}

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>
  /** Validation Regex can be found on Confluence. */
  username?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  /** +2348012345678 */
  phoneNumber?: Maybe<Scalars['String']>
  homeLocationId?: Maybe<Scalars['ID']>
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  privacy?: Maybe<PrivacyEnum>
  isOnboarded?: Maybe<Scalars['Boolean']>
  emergencyContactName?: Maybe<Scalars['String']>
  emergencyContactRelationship?: Maybe<Scalars['String']>
  /** Ex.: 2010-01-01 */
  emergencyContactEmail?: Maybe<Scalars['String']>
  /** +2348012345678 */
  emergencyContactPhone?: Maybe<Scalars['String']>
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  stateProvince?: Maybe<Scalars['String']>
  country?: Maybe<CountryInput>
  postalCode?: Maybe<Scalars['String']>
}

export type UpdateUserPersonalInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  /** Ex.: 2010-01-01 */
  birthDate?: Maybe<Scalars['String']>
  /** +2348012345678 */
  phoneNumber?: Maybe<Scalars['String']>
}

export type UpdateUserResult = {
  __typename?: 'UpdateUserResult'
  id: Scalars['ID']
  user: User
}

export type UpdateUserSocialInput = {
  /** Validation Regex can be found on Confluence. */
  username?: Maybe<Scalars['String']>
  homeLocationId?: Maybe<Scalars['ID']>
  bio?: Maybe<Scalars['String']>
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  marianaTekID: Scalars['String']
  email?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  isOnboarded: Scalars['Boolean']
  role: RoleEnum
  privacy: PrivacyEnum
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  lastMtFetch?: Maybe<Scalars['DateTime']>
  emergencyContactEmail?: Maybe<Scalars['String']>
  emergencyContactName?: Maybe<Scalars['String']>
  emergencyContactPhone?: Maybe<Scalars['String']>
  emergencyContactRelationship?: Maybe<Scalars['String']>
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  stateProvince?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  homeLocation?: Maybe<Location>
  birthDate?: Maybe<Scalars['String']>
  isWaiverSigned?: Maybe<Scalars['Boolean']>
  isMarketingOptIn?: Maybe<Scalars['Boolean']>
  memberSince?: Maybe<Scalars['DateTime']>
  reports: Array<Report>
  /** If `null`, users are not friends. */
  friendship?: Maybe<Friendship>
  isBlocked: Scalars['Boolean']
  isActiveAdmin: Scalars['Boolean']
  note?: Maybe<Scalars['String']>
  instructor?: Maybe<Instructor>
  unseenFriendRequestCount: Scalars['Int']
  hasGrantedVODAccess: Scalars['Boolean']
  grantedVODAccessNote: Scalars['String']
  speedRecord: Scalars['Float']
  loyaltyTier?: Maybe<LoyaltyTierType>
  gympassUID?: Maybe<Scalars['String']>
  unseenFitfamActivityCount: Scalars['Int']
  country?: Maybe<Country>
  hasVODAccess?: Maybe<Scalars['Boolean']>
  friendsCount: Scalars['Int']
  geozone: Scalars['String']
  isInRestrictedArea: Scalars['Boolean']
}

export type UserAddress = {
  __typename?: 'UserAddress'
  addressLine1?: Maybe<Scalars['String']>
  addressLine2?: Maybe<Scalars['String']>
  addressLine3?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  stateProvince?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
  country?: Maybe<Country>
}

export type UserAttendanceResult = {
  __typename?: 'UserAttendanceResult'
  value: Scalars['String']
  type: Scalars['String']
  entries: Array<AttendanceEntry>
}

export type UserAttendingClass = {
  __typename?: 'UserAttendingClass'
  user: User
  attending: Scalars['Boolean']
}

export type UserBookmarkFilter = {
  classType?: Maybe<Array<LibraryItemClassTypeEnum>>
}

export type UserEmergency = {
  __typename?: 'UserEmergency'
  emergencyContactName?: Maybe<Scalars['String']>
  emergencyContactRelationship?: Maybe<Scalars['String']>
  emergencyContactPhone?: Maybe<Scalars['String']>
  emergencyContactEmail?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
}

export type UserIdInput = {
  id: Scalars['ID']
}

export type UserPersonal = {
  __typename?: 'UserPersonal'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  birthDate?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
}

export type UserSocial = {
  __typename?: 'UserSocial'
  username?: Maybe<Scalars['String']>
  homeLocation?: Maybe<Location>
  bio?: Maybe<Scalars['String']>
  pictureUrl?: Maybe<Scalars['String']>
}

export type UserStatsResult = {
  __typename?: 'UserStatsResult'
  totalClasses?: Maybe<Scalars['Int']>
  spentOnFloor?: Maybe<Scalars['Int']>
  spentOnTread?: Maybe<Scalars['Int']>
  spentAtHome?: Maybe<Scalars['Int']>
  upcomingClasses?: Maybe<Scalars['Int']>
  /** @deprecated Use digitalClasses instead. */
  atHomeClasses?: Maybe<Scalars['Int']>
  digitalClasses?: Maybe<Scalars['Int']>
  redRoomClasses?: Maybe<Scalars['Int']>
  studiosVisited?: Maybe<Array<Studio>>
  favStudio?: Maybe<Studio>
  longestStreak?: Maybe<Scalars['Int']>
  bestWeek?: Maybe<Scalars['Int']>
  favoriteDay?: Maybe<Scalars['String']>
  favoriteTime?: Maybe<Scalars['String']>
  vodClasses?: Maybe<Scalars['Int']>
  cardioVODClasses?: Maybe<Scalars['Int']>
  strengthVODClasses?: Maybe<Scalars['Int']>
  stretchVODClasses?: Maybe<Scalars['Int']>
  strengthCardioVODClasses?: Maybe<Scalars['Int']>
  workouts: Array<WorkoutStats>
  instructors: Array<Instructor>
}

export type UserStatsResultStudiosVisitedArgs = {
  input?: Maybe<PaginationInput>
}

export type UserStatsResultInstructorsArgs = {
  input?: Maybe<PaginationInput>
}

export type ValidateClassSessionIdResult = {
  __typename?: 'ValidateClassSessionIDResult'
  valid: Scalars['Boolean']
}

export type VerifyAndroidPurchaseObject = {
  __typename?: 'VerifyAndroidPurchaseObject'
  isActive: Scalars['Boolean']
  expirationDate?: Maybe<Scalars['DateTime']>
  productID: Scalars['String']
  hasVODAccess?: Maybe<Scalars['Boolean']>
}

export type VerifyIosPurchaseObject = {
  __typename?: 'VerifyIOSPurchaseObject'
  isActive: Scalars['Boolean']
  expirationDate?: Maybe<Scalars['DateTime']>
  transactionID: Scalars['String']
  productID?: Maybe<Scalars['String']>
  hasVODAccess?: Maybe<Scalars['Boolean']>
}

export type VerifyPurchaseAndroidInput = {
  packageName: Scalars['String']
  /** Ex.: com.barrys.membership.8 */
  productID: Scalars['String']
  purchaseToken: Scalars['String']
}

export type VerifyPurchaseIosInput = {
  receiptData: Scalars['String']
  /** Ex.: com.barrys.barrysathome.ios.membership.8 */
  productID?: Maybe<Scalars['String']>
  transactionID: Scalars['String']
}

export type VerifyPurchasesIosInput = {
  receiptData: Scalars['String']
  transactionIDs: Array<Scalars['String']>
}

/** VideoPreferenceEnum desc */
export enum VideoPreferenceEnum {
  Off = 'OFF',
  Instructor = 'INSTRUCTOR',
  InstructorAndFriends = 'INSTRUCTOR_AND_FRIENDS',
  All = 'ALL',
}

/** videoStreamType: { option: "hls" | "dash" }. Default option when the field is empty is hls */
export type VideoStreamType = {
  option?: Maybe<Scalars['String']>
}

export type WorkoutStats = {
  __typename?: 'WorkoutStats'
  name: Scalars['String']
  count: Scalars['Int']
}

export type WorkoutSummary = {
  __typename?: 'WorkoutSummary'
  id: Scalars['ID']
  createdAt: Scalars['DateTime']
  classSessionID?: Maybe<Scalars['String']>
  userID: Scalars['String']
  libraryID?: Maybe<Scalars['String']>
  totalTime: Scalars['Float']
  activeCalories?: Maybe<Scalars['Float']>
  totalCalories?: Maybe<Scalars['Float']>
  heartRate?: Maybe<Scalars['Float']>
  maxHeartRate?: Maybe<Scalars['Float']>
  miles?: Maybe<Scalars['Float']>
  topMPH?: Maybe<Scalars['Float']>
  averageBPM?: Maybe<Scalars['Float']>
  sharedWithFitfam: Scalars['Boolean']
  lastTopMPH?: Maybe<Scalars['Float']>
  newTopMPH?: Maybe<Scalars['Float']>
  bpmChart?: Maybe<Array<WorkoutSummaryChartItem>>
}

export type WorkoutSummaryChartItem = {
  __typename?: 'WorkoutSummaryChartItem'
  timestamp: Scalars['DateTime']
  value: Scalars['Int']
}

export type WorkoutSummaryChartItemInput = {
  timestamp: Scalars['DateTime']
  value: Scalars['Int']
}

export type Token = {
  __typename?: 'token'
  token: Scalars['String']
}

export type InstructorFieldsFragment = { __typename?: 'Instructor' } & Pick<
  Instructor,
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'pictureUrl'
  | 'pictureUrlHq'
  | 'hasVOD'
  | 'bio'
  | 'lessonsCount'
> & {
    region?: Maybe<
      { __typename?: 'Region' } & Pick<Region, 'id' | 'name'> & {
          country: { __typename?: 'Country' } & Pick<Country, 'code' | 'name'>
        }
    >
  }

export type UserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'email'
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'pictureUrl'
  | 'hasVODAccess'
  | 'isInRestrictedArea'
  | 'privacy'
  | 'loyaltyTier'
  | 'gympassUID'
> & {
    friendship?: Maybe<
      { __typename?: 'Friendship' } & Pick<Friendship, 'id' | 'status'>
    >
    instructor?: Maybe<{ __typename?: 'Instructor' } & Pick<Instructor, 'id'>>
  }

export type MembershipFieldsFragment = { __typename?: 'Membership' } & Pick<
  Membership,
  | 'id'
  | 'membershipName'
  | 'renewalRate'
  | 'renewalCurrency'
  | 'startDate'
  | 'endDate'
  | 'purchaseDate'
  | 'cancellationDate'
  | 'isCancellable'
  | 'paymentInterval'
  | 'usageIntervalLimit'
>

export type SessionFieldsFragment = { __typename?: 'SessionObject' } & Pick<
  SessionObject,
  'accessToken' | 'refreshToken'
>

export type LiveSessionUserFieldsFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'username' | 'pictureUrl'
>

export type LiveSessionRoomFieldsFragment = {
  __typename?: 'LiveSessionRoom'
} & Pick<
  LiveSessionRoom,
  'id' | 'name' | 'roomIndex' | 'roomID' | 'hiddenParticipantIDs'
>

export type ClassSessionFragment = { __typename?: 'ClassSession' } & Pick<
  ClassSession,
  | 'id'
  | 'name'
  | 'description'
  | 'startDateTime'
  | 'endDateTime'
  | 'openingDateTime'
  | 'spotsCount'
  | 'duration'
  | 'participantsCount'
  | 'hasReservation'
  | 'userRated'
> & {
    classroom?: Maybe<
      { __typename?: 'Classroom' } & Pick<Classroom, 'id' | 'name'>
    >
    region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>
    location: { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
    instructors?: Maybe<
      Array<{ __typename?: 'Instructor' } & InstructorFieldsFragment>
    >
    participants: Array<{ __typename?: 'User' } & UserFieldsFragment>
    activeReservation?: Maybe<
      { __typename?: 'Reservation' } & Pick<Reservation, 'reservationType'>
    >
    rooms: Array<
      { __typename?: 'ClassSessionRoom' } & Pick<
        ClassSessionRoom,
        'name' | 'roomIndex'
      >
    >
  }

export type LibraryItemsFragment = { __typename?: 'Library' } & Pick<
  Library,
  | 'id'
  | 'title'
  | 'description'
  | 'imageURL'
  | 'viewed'
  | 'bodyweight'
  | 'userRated'
> & {
    duration: { __typename?: 'ListLibraryItemDuration' } & Pick<
      ListLibraryItemDuration,
      'name' | 'type'
    >
    parentCategory?: Maybe<
      { __typename?: 'ListLibraryItemCategory' } & Pick<
        ListLibraryItemCategory,
        'name' | 'type'
      >
    >
    equipment: Array<
      { __typename?: 'ListEquipment' } & Pick<ListEquipment, 'name' | 'type'>
    >
    labels: Array<
      { __typename?: 'ListLibraryItemLabel' } & Pick<
        ListLibraryItemLabel,
        'name' | 'type'
      >
    >
    instructor: { __typename?: 'Instructor' } & Pick<
      Instructor,
      'firstName' | 'lastName' | 'pictureUrl'
    >
    additionalInstructor?: Maybe<
      { __typename?: 'Instructor' } & Pick<
        Instructor,
        'firstName' | 'lastName' | 'pictureUrl'
      >
    >
  }

export type FriendshipFieldsFragment = { __typename?: 'Friendship' } & Pick<
  Friendship,
  'id' | 'status'
> & {
    requester: { __typename?: 'User' } & Pick<User, 'id'>
    friend: { __typename?: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'lastName' | 'username' | 'pictureUrl'
    > & {
        friendship?: Maybe<
          { __typename?: 'Friendship' } & Pick<Friendship, 'id' | 'status'> & {
              requester: { __typename?: 'User' } & Pick<User, 'id'>
            }
        >
      }
  }

export type SignInMutationVariables = Exact<{
  username: Scalars['String']
  password: Scalars['String']
}>

export type SignInMutation = { __typename?: 'Mutation' } & {
  signIn: { __typename?: 'Auth' } & {
    user: { __typename?: 'User' } & UserFieldsFragment
    session: { __typename?: 'SessionObject' } & SessionFieldsFragment
  }
}

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type RefreshTokenMutation = { __typename?: 'Mutation' } & {
  refreshToken: { __typename?: 'Auth' } & {
    user: { __typename?: 'User' } & UserFieldsFragment
    session: { __typename?: 'SessionObject' } & SessionFieldsFragment
  }
}

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String']
}>

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  resetPassword: { __typename?: 'PasswordResetResult' } & Pick<
    PasswordResetResult,
    'email'
  >
}

export type ConnectClientMutationVariables = Exact<{
  input: ConnectClientInput
}>

export type ConnectClientMutation = { __typename?: 'Mutation' } & {
  connectClient: { __typename?: 'LiveSessionConnectResult' } & Pick<
    LiveSessionConnectResult,
    'accessToken' | 'twilioRoomName'
  > & {
      liveSession: { __typename?: 'LiveSession' } & Pick<
        LiveSession,
        'id' | 'url' | 'status'
      > & {
          rooms: Array<
            { __typename?: 'LiveSessionRoom' } & Pick<
              LiveSessionRoom,
              'name' | 'roomIndex'
            >
          >
        }
      room: { __typename?: 'LiveSessionRoom' } & LiveSessionRoomFieldsFragment
    }
}

export type SendRoomMessageMutationVariables = Exact<{
  input: SendRoomMessageInput
}>

export type SendRoomMessageMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'sendRoomMessage'
>

export type CreateRatingForClassSessionMutationVariables = Exact<{
  ratingQ1: RatingLevel
  ratingQ2: RatingLevel
  additionalComments: Scalars['String']
  classSessionID: Scalars['ID']
}>

export type CreateRatingForClassSessionMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'createRatingForClassSession'>

export type CreateRatingForLibraryItemMutationVariables = Exact<{
  ratingQ1: RatingLevel
  ratingQ2: RatingLevel
  additionalComments: Scalars['String']
  libraryItemID: Scalars['ID']
}>

export type CreateRatingForLibraryItemMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'createRatingForLibraryItem'>

export type FinishSessionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type FinishSessionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'finishSession'
>

export type CreateBookmarkForLibraryItemMutationVariables = Exact<{
  libraryItemID: Scalars['ID']
}>

export type CreateBookmarkForLibraryItemMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'createBookmarkForLibraryItem'>

export type RemoveBookmarkByLibraryItemIdMutationVariables = Exact<{
  libraryItemID: Scalars['ID']
}>

export type RemoveBookmarkByLibraryItemIdMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'removeBookmarkByLibraryItemID'>

export type ToggleRedFilterMutationVariables = Exact<{
  roomID: Scalars['ID']
  value: Scalars['Boolean']
}>

export type ToggleRedFilterMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'toggleRedFilter'
>

export type ForceVideoOffMutationVariables = Exact<{
  roomID: Scalars['ID']
  userID: Scalars['ID']
  value: Scalars['Boolean']
}>

export type ForceVideoOffMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forceVideoOff'
>

export type ForceSoundOffMutationVariables = Exact<{
  roomID: Scalars['ID']
  userID: Scalars['ID']
  value: Scalars['Boolean']
}>

export type ForceSoundOffMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forceSoundOff'
>

export type BanUserFromSessionMutationVariables = Exact<{
  roomID: Scalars['ID']
  userID: Scalars['ID']
  value: Scalars['Boolean']
}>

export type BanUserFromSessionMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'banUserFromSession'
>

export type ChangeVideoPreferenceMutationVariables = Exact<{
  roomID: Scalars['ID']
  value: VideoPreferenceEnum
}>

export type ChangeVideoPreferenceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'changeVideoPreference'
>

export type SaveVideoAnalyticsEventMutationVariables = Exact<{
  idempotencyKey: Scalars['String']
  playedTime: Scalars['Float']
  libraryItemID: Scalars['ID']
}>

export type SaveVideoAnalyticsEventMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'saveVideoAnalyticsEvent'>

export type ChangeRoomStatusToPreparationMutationVariables = Exact<{
  roomID: Scalars['ID']
}>

export type ChangeRoomStatusToPreparationMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'changeRoomStatusToPreparation'>

export type ChangeRoomStatusToActiveMutationVariables = Exact<{
  roomID: Scalars['ID']
}>

export type ChangeRoomStatusToActiveMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'changeRoomStatusToActive'>

export type DisconnectClientMutationVariables = Exact<{
  roomID: Scalars['ID']
}>

export type DisconnectClientMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'disconnectClient'
>

export type CreateLiveSessionMutationVariables = Exact<{
  classSessionID: Scalars['String']
}>

export type CreateLiveSessionMutation = { __typename?: 'Mutation' } & {
  createLiveSession: { __typename?: 'LiveSession' } & Pick<LiveSession, 'id'>
}

export type CancelUserMembershipMutationVariables = Exact<{
  input: CancelUserMembershipInput
}>

export type CancelUserMembershipMutation = { __typename?: 'Mutation' } & {
  cancelUserMembership: { __typename?: 'Membership' } & MembershipFieldsFragment
}

export type MuteAllMutationVariables = Exact<{
  input: MuteAllInput
}>

export type MuteAllMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'muteAll'
>

export type StartMusicPlaybackMutationVariables = Exact<{
  input: StartMusicPlaybackInput
}>

export type StartMusicPlaybackMutation = { __typename?: 'Mutation' } & {
  startMusicPlayback: { __typename?: 'StartMusicPlaybackResult' } & Pick<
    StartMusicPlaybackResult,
    'isSuccessful' | 'message'
  >
}

export type UpdateMusicPlaybackTimesMutationVariables = Exact<{
  input: UpdateMusicPlaybackInput
}>

export type UpdateMusicPlaybackTimesMutation = { __typename?: 'Mutation' } & {
  updateMusicPlaybackTimes: { __typename?: 'StartMusicPlaybackResult' } & Pick<
    StartMusicPlaybackResult,
    'isSuccessful'
  >
}

export type StoreDeviceInfoMutationVariables = Exact<{
  input: StoreDeviceInfoInput
}>

export type StoreDeviceInfoMutation = { __typename?: 'Mutation' } & {
  storeDeviceInfo: { __typename?: 'DeviceInfo' } & Pick<DeviceInfo, 'id'>
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = { __typename?: 'Mutation' } & {
  createUser: { __typename?: 'Auth' } & {
    user: { __typename?: 'User' } & UserFieldsFragment
    session: { __typename?: 'SessionObject' } & SessionFieldsFragment
  }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'UpdateUserResult' } & Pick<
    UpdateUserResult,
    'id'
  > & { user: { __typename?: 'User' } & UserFieldsFragment }
}

export type ReportGympassEventMutationVariables = Exact<{
  eventType: GympassEventEnum
}>

export type ReportGympassEventMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'reportGympassEvent'
>

export type AcceptFriendshipMutationVariables = Exact<{
  friendshipId: Scalars['ID']
}>

export type AcceptFriendshipMutation = { __typename?: 'Mutation' } & {
  acceptFriendship: { __typename?: 'Friendship' } & FriendshipFieldsFragment
}

export type DeclineFriendshipMutationVariables = Exact<{
  friendshipId: Scalars['ID']
}>

export type DeclineFriendshipMutation = { __typename?: 'Mutation' } & {
  declineFriendship: { __typename?: 'Friendship' } & FriendshipFieldsFragment
}

export type RequestFriendshipMutationVariables = Exact<{
  input: RequestFriendshipInput
}>

export type RequestFriendshipMutation = { __typename?: 'Mutation' } & {
  requestFriendship: { __typename?: 'Friendship' } & FriendshipFieldsFragment
}

export type RemoveFriendshipMutationVariables = Exact<{
  friendshipId: Scalars['ID']
}>

export type RemoveFriendshipMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'removeFriendship'
>

export type CancelFriendshipRequestMutationVariables = Exact<{
  input: RequestFriendshipInput
}>

export type CancelFriendshipRequestMutation = { __typename?: 'Mutation' } & {
  cancelFriendshipRequest: { __typename?: 'CancelRequestResult' } & {
    friend: { __typename?: 'User' } & Pick<
      User,
      'id' | 'firstName' | 'lastName' | 'username' | 'pictureUrl'
    > & {
        friendship?: Maybe<
          { __typename?: 'Friendship' } & Pick<Friendship, 'id' | 'status'> & {
              requester: { __typename?: 'User' } & Pick<User, 'id'>
            }
        >
      }
  }
}

export type CreateReportMutationVariables = Exact<{
  input: CreateReportInput
}>

export type CreateReportMutation = { __typename?: 'Mutation' } & {
  createReport: { __typename?: 'CreateReportResult' } & Pick<
    CreateReportResult,
    'id'
  >
}

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserQuery = { __typename?: 'Query' } & {
  getUser: { __typename?: 'User' } & UserFieldsFragment
}

export type GetUserStatsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetUserStatsQuery = { __typename?: 'Query' } & {
  getUserStats: { __typename?: 'UserStatsResult' } & Pick<
    UserStatsResult,
    'totalClasses' | 'upcomingClasses'
  >
}

export type ListClassSessionsQueryVariables = Exact<{
  input: ListClassSessionInput
}>

export type ListClassSessionsQuery = { __typename?: 'Query' } & {
  listClassSessions: Array<
    { __typename?: 'ClassSession' } & Pick<
      ClassSession,
      | 'id'
      | 'name'
      | 'startDateTime'
      | 'openingDateTime'
      | 'duration'
      | 'participantsCount'
      | 'hasReservation'
    > & {
        participants: Array<
          { __typename?: 'User' } & Pick<User, 'id' | 'pictureUrl'>
        >
        instructors?: Maybe<
          Array<
            { __typename?: 'Instructor' } & Pick<
              Instructor,
              'id' | 'pictureUrl' | 'firstName' | 'lastName'
            >
          >
        >
        location: { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
      }
  >
}

export type ListWorkoutSummariesQueryVariables = Exact<{
  input: ListWorkoutSummaryInput
}>

export type ListWorkoutSummariesQuery = { __typename?: 'Query' } & {
  listWorkoutSummaries: Array<
    { __typename?: 'WorkoutSummary' } & Pick<
      WorkoutSummary,
      | 'id'
      | 'createdAt'
      | 'totalTime'
      | 'activeCalories'
      | 'totalCalories'
      | 'heartRate'
      | 'maxHeartRate'
      | 'miles'
      | 'topMPH'
      | 'averageBPM'
    > & {
        bpmChart?: Maybe<
          Array<
            { __typename?: 'WorkoutSummaryChartItem' } & Pick<
              WorkoutSummaryChartItem,
              'timestamp' | 'value'
            >
          >
        >
      }
  >
}

export type ListUserMembershipsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
}>

export type ListUserMembershipsQuery = { __typename?: 'Query' } & {
  listUserMemberships: Array<
    { __typename?: 'Membership' } & MembershipFieldsFragment
  >
}

export type ListUserPurchasesQueryVariables = Exact<{
  type?: PurchaseTypeEnum
  platform?: Maybe<PlatformEnum>
}>

export type ListUserPurchasesQuery = { __typename?: 'Query' } & {
  listUserPurchases: Array<
    { __typename?: 'Purchase' } & Pick<Purchase, 'platform'>
  >
}

export type ListUserClassSessionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>
  size?: Maybe<Scalars['Int']>
  status?: Maybe<Array<ReservationStatusEnum> | ReservationStatusEnum>
  sortBy?: ReservationSortingEnum
}>

export type ListUserClassSessionsQuery = { __typename?: 'Query' } & {
  listUserClassSessions: Array<
    { __typename?: 'ClassSession' } & Pick<
      ClassSession,
      | 'id'
      | 'name'
      | 'isDigital'
      | 'startDateTime'
      | 'openingDateTime'
      | 'duration'
      | 'participantsCount'
      | 'hasReservation'
    > & {
        activeReservation?: Maybe<
          { __typename?: 'Reservation' } & Pick<Reservation, 'roomIndex'>
        >
        participants: Array<
          { __typename?: 'User' } & Pick<User, 'id' | 'pictureUrl'>
        >
        instructors?: Maybe<
          Array<
            { __typename?: 'Instructor' } & Pick<
              Instructor,
              'id' | 'pictureUrl' | 'firstName' | 'lastName'
            > & {
                region?: Maybe<
                  { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>
                >
              }
          >
        >
        location: { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
      }
  >
}

export type ListInstructorsQueryVariables = Exact<{
  input: ListInstructorsInput_V2
}>

export type ListInstructorsQuery = { __typename?: 'Query' } & {
  listInstructors: Array<
    { __typename?: 'Instructor' } & InstructorFieldsFragment
  >
}

export type GetInstructorDetailQueryVariables = Exact<{
  id: Scalars['ID']
  input: ListInstructorVodFiltersInput
}>

export type GetInstructorDetailQuery = { __typename?: 'Query' } & {
  getInstructor: { __typename?: 'Instructor' } & InstructorFieldsFragment
  listInstructorVodFilters: { __typename?: 'ListInstructorVodFilters' } & Pick<
    ListInstructorVodFilters,
    'classTypeFilters'
  >
}

export type GetLiveSessionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetLiveSessionQuery = { __typename?: 'Query' } & {
  getLiveSession: { __typename?: 'LiveSession' } & Pick<
    LiveSession,
    'id' | 'url' | 'status'
  > & {
      rooms: Array<
        { __typename?: 'LiveSessionRoom' } & Pick<
          LiveSessionRoom,
          'name' | 'roomIndex'
        >
      >
      classSession?: Maybe<
        { __typename?: 'ClassSession' } & Pick<ClassSession, 'id'>
      >
    }
}

export type GetClassSessionQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetClassSessionQuery = { __typename?: 'Query' } & {
  getClassSession: { __typename?: 'ClassSession' } & Pick<
    ClassSession,
    | 'id'
    | 'name'
    | 'startDateTime'
    | 'endDateTime'
    | 'spotsCount'
    | 'duration'
    | 'participantsCount'
    | 'hasReservation'
    | 'userRated'
    | 'activeReservationID'
    | 'moderatorID'
    | 'appleMusicPlaylistIDs'
  > & {
      classroom?: Maybe<
        { __typename?: 'Classroom' } & Pick<Classroom, 'id' | 'name'>
      >
      region: { __typename?: 'Region' } & Pick<Region, 'id' | 'name'>
      location: { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
      instructors?: Maybe<
        Array<{ __typename?: 'Instructor' } & InstructorFieldsFragment>
      >
      participants: Array<{ __typename?: 'User' } & UserFieldsFragment>
      rooms: Array<
        { __typename?: 'ClassSessionRoom' } & Pick<
          ClassSessionRoom,
          'name' | 'roomIndex'
        > & { participants: Array<{ __typename?: 'User' } & Pick<User, 'id'>> }
      >
      spotifyPlaylistIDs?: Maybe<
        Array<
          { __typename?: 'SpotifyPlaylist' } & Pick<
            SpotifyPlaylist,
            'playlistID' | 'imageURL' | 'title'
          >
        >
      >
    }
}

export type ListVodFiltersQueryVariables = Exact<{
  category: LibraryItemCategoryEnum
}>

export type ListVodFiltersQuery = { __typename?: 'Query' } & {
  listVodFilters: { __typename?: 'ListVodFilters' } & Pick<
    ListVodFilters,
    'classTypeFilters'
  > & {
      bodyParts?: Maybe<
        Array<
          { __typename?: 'ListBodyPart' } & Pick<ListBodyPart, 'name' | 'type'>
        >
      >
      bodyPartsFilters: Array<
        { __typename?: 'ListBodyPartFilter' } & Pick<
          ListBodyPartFilter,
          'name' | 'type'
        >
      >
      equipment?: Maybe<
        Array<
          { __typename?: 'ListEquipment' } & Pick<
            ListEquipment,
            'name' | 'type' | 'image'
          >
        >
      >
      equipmentFilters_v2: Array<
        { __typename?: 'ListEquipmentFilter' } & Pick<
          ListEquipmentFilter,
          'name' | 'type' | 'image'
        >
      >
      labels: Array<
        { __typename?: 'ListLibraryItemLabel' } & Pick<
          ListLibraryItemLabel,
          'name' | 'type'
        >
      >
      types: Array<
        { __typename?: 'ListLibraryItemType' } & Pick<
          ListLibraryItemType,
          'name' | 'type'
        >
      >
      durations: Array<
        { __typename?: 'ListLibraryItemDuration' } & Pick<
          ListLibraryItemDuration,
          'name' | 'type'
        >
      >
      statuses: Array<
        { __typename?: 'ListLibraryItemStatus' } & Pick<
          ListLibraryItemStatus,
          'name' | 'type'
        >
      >
    }
}

export type CountLibraryItemsQueryVariables = Exact<{
  input?: Maybe<ListLibraryItemsInput>
}>

export type CountLibraryItemsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'countLibraryItems'
>

export type ListLibraryItemsQueryVariables = Exact<{
  input?: Maybe<ListLibraryItemsInput>
}>

export type ListLibraryItemsQuery = { __typename?: 'Query' } & {
  listLibraryItems: Array<
    { __typename?: 'Library' } & {
      instructor: { __typename?: 'Instructor' } & Pick<
        Instructor,
        'id' | 'firstName' | 'lastName' | 'pictureUrl'
      >
    } & LibraryItemsFragment
  >
}

export type ListLibraryItemCategoriesQueryVariables = Exact<{
  input?: Maybe<GetLibraryItemCategoriesTotalInput>
  totalInput?: Maybe<GetLibraryItemCategoriesTotalInput>
}>

export type ListLibraryItemCategoriesQuery = { __typename?: 'Query' } & {
  listLibraryItemCategories: Array<
    { __typename?: 'ListLibraryItemCategory' } & Pick<
      ListLibraryItemCategory,
      'name' | 'type' | 'verticalImage'
    > & { total: ListLibraryItemCategory['total_v2'] }
  >
}

export type GetLibraryItemQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetLibraryItemQuery = { __typename?: 'Query' } & {
  getLibraryItem: { __typename?: 'Library' } & Pick<
    Library,
    | 'id'
    | 'createdAt'
    | 'title'
    | 'description'
    | 'imageURL'
    | 'type'
    | 'isBookmarked'
    | 'appleMusicPlaylistIDs'
    | 'viewed'
    | 'bodyweight'
    | 'userRated'
    | 'hasWorkoutSummary'
  > & {
      equipment: Array<
        { __typename?: 'ListEquipment' } & Pick<
          ListEquipment,
          'name' | 'type' | 'image'
        >
      >
      labels: Array<
        { __typename?: 'ListLibraryItemLabel' } & Pick<
          ListLibraryItemLabel,
          'name' | 'type'
        >
      >
      duration: { __typename?: 'ListLibraryItemDuration' } & Pick<
        ListLibraryItemDuration,
        'name' | 'type'
      >
      instructor: { __typename?: 'Instructor' } & InstructorFieldsFragment
      additionalInstructor?: Maybe<
        { __typename?: 'Instructor' } & InstructorFieldsFragment
      >
      spotifyPlaylistIDs?: Maybe<
        Array<
          { __typename?: 'LibrarySpotifyPlaylist' } & Pick<
            LibrarySpotifyPlaylist,
            'playlistID' | 'imageURL' | 'title'
          >
        >
      >
    }
}

export type GetFileUrlQueryVariables = Exact<{
  input: GetFileUrlInput_V3
}>

export type GetFileUrlQuery = { __typename?: 'Query' } & {
  getFileURL_v3: { __typename?: 'FileURL_v3' } & Pick<
    FileUrl_V3,
    'main' | 'instructor' | 'music'
  >
}

export type ListOnDemandCollectionSectionsQueryVariables = Exact<{
  input?: Maybe<PaginationInput>
}>

export type ListOnDemandCollectionSectionsQuery = { __typename?: 'Query' } & {
  listOnDemandCollectionSections: Array<
    { __typename?: 'OnDemandCollectionSectionEntity' } & Pick<
      OnDemandCollectionSectionEntity,
      'name' | 'order'
    > & {
        collections: Array<
          { __typename?: 'OnDemandCollectionEntity' } & Pick<
            OnDemandCollectionEntity,
            'id' | 'name' | 'imageURL' | 'collectionItemsCount'
          >
        >
      }
  >
}

export type GetOnDemandCollectionQueryVariables = Exact<{
  id: Scalars['ID']
  input?: Maybe<PaginationInput>
}>

export type GetOnDemandCollectionQuery = { __typename?: 'Query' } & {
  getOnDemandCollection: { __typename?: 'OnDemandCollectionEntity' } & Pick<
    OnDemandCollectionEntity,
    'id' | 'name' | 'collectionItemsCount'
  > & {
      collectionItems: Array<
        { __typename?: 'OnDemandCollectionItemEntity' } & {
          libraryItem: { __typename?: 'Library' } & LibraryItemsFragment
        }
      >
    }
}

export type ListRegionsQueryVariables = Exact<{
  input?: Maybe<ListRegionsInput>
}>

export type ListRegionsQuery = { __typename?: 'Query' } & {
  listRegions: Array<
    { __typename?: 'ListRegionsResult' } & Pick<
      ListRegionsResult,
      'id' | 'name'
    > & {
        country: { __typename?: 'Country' } & Pick<Country, 'code' | 'name'>
        locations: Array<
          { __typename?: 'LocationObject' } & Pick<
            LocationObject,
            'id' | 'name'
          >
        >
      }
  >
}

export type ListOnDemandSectionsQueryVariables = Exact<{
  filter?: Maybe<OnDemandSectionFilter>
  input?: Maybe<PaginationInput>
}>

export type ListOnDemandSectionsQuery = { __typename?: 'Query' } & {
  listOnDemandSections: Array<
    { __typename?: 'OnDemandSectionEntity' } & Pick<
      OnDemandSectionEntity,
      'id' | 'name'
    > & {
        category: { __typename?: 'ListOnDemandCategory' } & Pick<
          ListOnDemandCategory,
          'name' | 'type'
        >
        items: Array<
          { __typename?: 'OnDemandSectionItemEntity' } & Pick<
            OnDemandSectionItemEntity,
            'id'
          > & { libraryItem: { __typename?: 'Library' } & LibraryItemsFragment }
        >
      }
  >
}

export type ListBookmarksQueryVariables = Exact<{
  input?: Maybe<ListUserBookmarksInput>
}>

export type ListBookmarksQuery = { __typename?: 'Query' } & {
  listBookmarks: Array<
    { __typename?: 'Bookmark' } & Pick<Bookmark, 'id' | 'createdAt'> & {
        libraryItem: { __typename?: 'Library' } & LibraryItemsFragment
        collection?: Maybe<
          { __typename?: 'OnDemandCollectionEntity' } & Pick<
            OnDemandCollectionEntity,
            'id' | 'name'
          >
        >
      }
  >
}

export type HasUserUnlimitedMembershipQueryVariables = Exact<{
  [key: string]: never
}>

export type HasUserUnlimitedMembershipQuery = { __typename?: 'Query' } & Pick<
  Query,
  'hasUserUnlimitedMembership'
>

export type UsernameExistsQueryVariables = Exact<{
  username: Scalars['String']
}>

export type UsernameExistsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'usernameExists'
>

export type GetSignedUrlQueryVariables = Exact<{
  options: GetSignedUrlInput
}>

export type GetSignedUrlQuery = { __typename?: 'Query' } & {
  getSignedUrl: { __typename?: 'GetSignedUrlResult' } & Pick<
    GetSignedUrlResult,
    'url' | 'publicUrl' | 'expiresAt'
  >
}

export type GetGympassUserDataQueryVariables = Exact<{
  gympassUID: Scalars['String']
}>

export type GetGympassUserDataQuery = { __typename?: 'Query' } & {
  getGympassUserData: { __typename?: 'GympassUser' } & Pick<
    GympassUser,
    | 'id'
    | 'createdAt'
    | 'gympassUID'
    | 'email'
    | 'firstName'
    | 'lastName'
    | 'origin'
    | 'userStatus'
    | 'countryCode'
  >
}

export type GetUserAttendanceQueryVariables = Exact<{
  userID: Scalars['ID']
  format: Scalars['String']
}>

export type GetUserAttendanceQuery = { __typename?: 'Query' } & {
  getUserAttendance: Array<
    { __typename?: 'UserAttendanceResult' } & Pick<
      UserAttendanceResult,
      'type' | 'value'
    > & {
        entries: Array<
          { __typename?: 'AttendanceEntry' } & Pick<
            AttendanceEntry,
            'value' | 'count'
          >
        >
      }
  >
}

export type GetProfileUserDataQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetProfileUserDataQuery = { __typename?: 'Query' } & {
  getUser: { __typename?: 'User' } & Pick<
    User,
    | 'id'
    | 'firstName'
    | 'lastName'
    | 'username'
    | 'pictureUrl'
    | 'loyaltyTier'
    | 'bio'
    | 'friendsCount'
    | 'memberSince'
  > & {
      friendship?: Maybe<
        { __typename?: 'Friendship' } & FriendshipFieldsFragment
      >
      homeLocation?: Maybe<
        { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
      >
    }
  getUserStats: { __typename?: 'UserStatsResult' } & Pick<
    UserStatsResult,
    | 'spentAtHome'
    | 'totalClasses'
    | 'upcomingClasses'
    | 'digitalClasses'
    | 'redRoomClasses'
    | 'longestStreak'
    | 'bestWeek'
    | 'favoriteDay'
    | 'favoriteTime'
    | 'vodClasses'
    | 'cardioVODClasses'
    | 'strengthVODClasses'
    | 'stretchVODClasses'
    | 'strengthCardioVODClasses'
  > & {
      studiosVisited?: Maybe<
        Array<
          { __typename?: 'Studio' } & Pick<Studio, 'numVisited'> & {
              region: { __typename?: 'StudioRegion' } & Pick<
                StudioRegion,
                'name'
              > & {
                  location: { __typename?: 'StudioLocation' } & Pick<
                    StudioLocation,
                    'name'
                  >
                }
            }
        >
      >
      favStudio?: Maybe<
        { __typename?: 'Studio' } & Pick<Studio, 'numVisited'> & {
            region: { __typename?: 'StudioRegion' } & Pick<
              StudioRegion,
              'name'
            > & {
                location: { __typename?: 'StudioLocation' } & Pick<
                  StudioLocation,
                  'name'
                >
              }
          }
      >
      workouts: Array<
        { __typename?: 'WorkoutStats' } & Pick<WorkoutStats, 'name' | 'count'>
      >
      instructors: Array<
        { __typename?: 'Instructor' } & Pick<
          Instructor,
          'id' | 'firstName' | 'lastName' | 'pictureUrl' | 'lessonsCount'
        > & {
            location?: Maybe<
              { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
            >
          }
      >
    }
  getUserAttendance: Array<
    { __typename?: 'UserAttendanceResult' } & Pick<
      UserAttendanceResult,
      'type' | 'value'
    > & {
        entries: Array<
          { __typename?: 'AttendanceEntry' } & Pick<
            AttendanceEntry,
            'value' | 'count'
          >
        >
      }
  >
}

export type ListFriendshipsQueryVariables = Exact<{
  input?: Maybe<ListFriendshipInput>
}>

export type ListFriendshipsQuery = { __typename?: 'Query' } & {
  listFriendships_v2: Array<
    { __typename?: 'Friendship' } & FriendshipFieldsFragment
  >
}

export const MembershipFieldsFragmentDoc = gql`
  fragment MembershipFields on Membership {
    id
    membershipName
    renewalRate
    renewalCurrency
    startDate
    endDate
    purchaseDate
    cancellationDate
    isCancellable
    paymentInterval
    usageIntervalLimit
  }
`
export const SessionFieldsFragmentDoc = gql`
  fragment SessionFields on SessionObject {
    accessToken
    refreshToken
  }
`
export const LiveSessionUserFieldsFragmentDoc = gql`
  fragment LiveSessionUserFields on User {
    id
    firstName
    lastName
    username
    pictureUrl
  }
`
export const LiveSessionRoomFieldsFragmentDoc = gql`
  fragment LiveSessionRoomFields on LiveSessionRoom {
    id
    name
    roomIndex
    roomID
    hiddenParticipantIDs
  }
`
export const InstructorFieldsFragmentDoc = gql`
  fragment InstructorFields on Instructor {
    id
    firstName
    lastName
    pictureUrl
    pictureUrlHq
    hasVOD
    bio
    region {
      id
      name
      country {
        code
        name
      }
    }
    lessonsCount
  }
`
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    email
    username
    firstName
    lastName
    pictureUrl
    hasVODAccess
    isInRestrictedArea
    privacy
    loyaltyTier
    gympassUID
    friendship {
      id
      status
    }
    instructor {
      id
    }
  }
`
export const ClassSessionFragmentDoc = gql`
  fragment ClassSession on ClassSession {
    id
    name
    description
    startDateTime
    endDateTime
    openingDateTime
    spotsCount
    duration
    participantsCount
    hasReservation
    userRated
    classroom {
      id
      name
    }
    region {
      id
      name
    }
    location {
      id
      name
    }
    instructors {
      ...InstructorFields
    }
    participants {
      ...UserFields
    }
    activeReservation {
      reservationType
    }
    rooms {
      name
      roomIndex
    }
  }
  ${InstructorFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`
export const LibraryItemsFragmentDoc = gql`
  fragment LibraryItems on Library {
    id
    title
    description
    duration {
      name
      type
    }
    imageURL
    parentCategory {
      name
      type
    }
    equipment: equipment_v2 {
      name
      type
    }
    labels {
      name
      type
    }
    instructor {
      firstName
      lastName
      pictureUrl
    }
    additionalInstructor {
      firstName
      lastName
      pictureUrl
    }
    viewed
    bodyweight
    userRated
  }
`
export const FriendshipFieldsFragmentDoc = gql`
  fragment FriendshipFields on Friendship {
    id
    status
    requester {
      id
    }
    friend {
      id
      firstName
      lastName
      username
      pictureUrl
      friendship {
        id
        status
        requester {
          id
        }
      }
    }
  }
`
export const SignInDocument = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(input: { username: $username, password: $password }) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${SessionFieldsFragmentDoc}
`
export type SignInMutationFn = Apollo.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options
  )
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${SessionFieldsFragmentDoc}
`
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options)
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(input: { email: $email }) {
      email
    }
  }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const ConnectClientDocument = gql`
  mutation ConnectClient($input: ConnectClientInput!) {
    connectClient(input: $input) {
      accessToken
      twilioRoomName
      liveSession {
        id
        url
        status
        rooms {
          name
          roomIndex
        }
      }
      room {
        ...LiveSessionRoomFields
      }
    }
  }
  ${LiveSessionRoomFieldsFragmentDoc}
`
export type ConnectClientMutationFn = Apollo.MutationFunction<
  ConnectClientMutation,
  ConnectClientMutationVariables
>

/**
 * __useConnectClientMutation__
 *
 * To run a mutation, you first call `useConnectClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectClientMutation, { data, loading, error }] = useConnectClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectClientMutation,
    ConnectClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConnectClientMutation,
    ConnectClientMutationVariables
  >(ConnectClientDocument, options)
}
export type ConnectClientMutationHookResult = ReturnType<
  typeof useConnectClientMutation
>
export type ConnectClientMutationOptions = Apollo.BaseMutationOptions<
  ConnectClientMutation,
  ConnectClientMutationVariables
>
export const SendRoomMessageDocument = gql`
  mutation SendRoomMessage($input: SendRoomMessageInput!) {
    sendRoomMessage(input: $input)
  }
`
export type SendRoomMessageMutationFn = Apollo.MutationFunction<
  SendRoomMessageMutation,
  SendRoomMessageMutationVariables
>

/**
 * __useSendRoomMessageMutation__
 *
 * To run a mutation, you first call `useSendRoomMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRoomMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRoomMessageMutation, { data, loading, error }] = useSendRoomMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendRoomMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRoomMessageMutation,
    SendRoomMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendRoomMessageMutation,
    SendRoomMessageMutationVariables
  >(SendRoomMessageDocument, options)
}
export type SendRoomMessageMutationHookResult = ReturnType<
  typeof useSendRoomMessageMutation
>
export type SendRoomMessageMutationOptions = Apollo.BaseMutationOptions<
  SendRoomMessageMutation,
  SendRoomMessageMutationVariables
>
export const CreateRatingForClassSessionDocument = gql`
  mutation CreateRatingForClassSession(
    $ratingQ1: RatingLevel!
    $ratingQ2: RatingLevel!
    $additionalComments: String!
    $classSessionID: ID!
  ) {
    createRatingForClassSession(
      input: {
        ratingQ1: $ratingQ1
        ratingQ2: $ratingQ2
        additionalComments: $additionalComments
        classSessionID: $classSessionID
      }
    )
  }
`
export type CreateRatingForClassSessionMutationFn = Apollo.MutationFunction<
  CreateRatingForClassSessionMutation,
  CreateRatingForClassSessionMutationVariables
>

/**
 * __useCreateRatingForClassSessionMutation__
 *
 * To run a mutation, you first call `useCreateRatingForClassSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingForClassSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingForClassSessionMutation, { data, loading, error }] = useCreateRatingForClassSessionMutation({
 *   variables: {
 *      ratingQ1: // value for 'ratingQ1'
 *      ratingQ2: // value for 'ratingQ2'
 *      additionalComments: // value for 'additionalComments'
 *      classSessionID: // value for 'classSessionID'
 *   },
 * });
 */
export function useCreateRatingForClassSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRatingForClassSessionMutation,
    CreateRatingForClassSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRatingForClassSessionMutation,
    CreateRatingForClassSessionMutationVariables
  >(CreateRatingForClassSessionDocument, options)
}
export type CreateRatingForClassSessionMutationHookResult = ReturnType<
  typeof useCreateRatingForClassSessionMutation
>
export type CreateRatingForClassSessionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRatingForClassSessionMutation,
    CreateRatingForClassSessionMutationVariables
  >
export const CreateRatingForLibraryItemDocument = gql`
  mutation CreateRatingForLibraryItem(
    $ratingQ1: RatingLevel!
    $ratingQ2: RatingLevel!
    $additionalComments: String!
    $libraryItemID: ID!
  ) {
    createRatingForLibraryItem(
      input: {
        ratingQ1: $ratingQ1
        ratingQ2: $ratingQ2
        additionalComments: $additionalComments
        libraryItemID: $libraryItemID
      }
    )
  }
`
export type CreateRatingForLibraryItemMutationFn = Apollo.MutationFunction<
  CreateRatingForLibraryItemMutation,
  CreateRatingForLibraryItemMutationVariables
>

/**
 * __useCreateRatingForLibraryItemMutation__
 *
 * To run a mutation, you first call `useCreateRatingForLibraryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRatingForLibraryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRatingForLibraryItemMutation, { data, loading, error }] = useCreateRatingForLibraryItemMutation({
 *   variables: {
 *      ratingQ1: // value for 'ratingQ1'
 *      ratingQ2: // value for 'ratingQ2'
 *      additionalComments: // value for 'additionalComments'
 *      libraryItemID: // value for 'libraryItemID'
 *   },
 * });
 */
export function useCreateRatingForLibraryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRatingForLibraryItemMutation,
    CreateRatingForLibraryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateRatingForLibraryItemMutation,
    CreateRatingForLibraryItemMutationVariables
  >(CreateRatingForLibraryItemDocument, options)
}
export type CreateRatingForLibraryItemMutationHookResult = ReturnType<
  typeof useCreateRatingForLibraryItemMutation
>
export type CreateRatingForLibraryItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRatingForLibraryItemMutation,
    CreateRatingForLibraryItemMutationVariables
  >
export const FinishSessionDocument = gql`
  mutation FinishSession($id: ID!) {
    finishSession(input: { sessionID: $id })
  }
`
export type FinishSessionMutationFn = Apollo.MutationFunction<
  FinishSessionMutation,
  FinishSessionMutationVariables
>

/**
 * __useFinishSessionMutation__
 *
 * To run a mutation, you first call `useFinishSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSessionMutation, { data, loading, error }] = useFinishSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinishSessionMutation,
    FinishSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    FinishSessionMutation,
    FinishSessionMutationVariables
  >(FinishSessionDocument, options)
}
export type FinishSessionMutationHookResult = ReturnType<
  typeof useFinishSessionMutation
>
export type FinishSessionMutationOptions = Apollo.BaseMutationOptions<
  FinishSessionMutation,
  FinishSessionMutationVariables
>
export const CreateBookmarkForLibraryItemDocument = gql`
  mutation CreateBookmarkForLibraryItem($libraryItemID: ID!) {
    createBookmarkForLibraryItem(libraryItemID: $libraryItemID)
  }
`
export type CreateBookmarkForLibraryItemMutationFn = Apollo.MutationFunction<
  CreateBookmarkForLibraryItemMutation,
  CreateBookmarkForLibraryItemMutationVariables
>

/**
 * __useCreateBookmarkForLibraryItemMutation__
 *
 * To run a mutation, you first call `useCreateBookmarkForLibraryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookmarkForLibraryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookmarkForLibraryItemMutation, { data, loading, error }] = useCreateBookmarkForLibraryItemMutation({
 *   variables: {
 *      libraryItemID: // value for 'libraryItemID'
 *   },
 * });
 */
export function useCreateBookmarkForLibraryItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookmarkForLibraryItemMutation,
    CreateBookmarkForLibraryItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBookmarkForLibraryItemMutation,
    CreateBookmarkForLibraryItemMutationVariables
  >(CreateBookmarkForLibraryItemDocument, options)
}
export type CreateBookmarkForLibraryItemMutationHookResult = ReturnType<
  typeof useCreateBookmarkForLibraryItemMutation
>
export type CreateBookmarkForLibraryItemMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBookmarkForLibraryItemMutation,
    CreateBookmarkForLibraryItemMutationVariables
  >
export const RemoveBookmarkByLibraryItemIdDocument = gql`
  mutation RemoveBookmarkByLibraryItemID($libraryItemID: ID!) {
    removeBookmarkByLibraryItemID(libraryItemID: $libraryItemID)
  }
`
export type RemoveBookmarkByLibraryItemIdMutationFn = Apollo.MutationFunction<
  RemoveBookmarkByLibraryItemIdMutation,
  RemoveBookmarkByLibraryItemIdMutationVariables
>

/**
 * __useRemoveBookmarkByLibraryItemIdMutation__
 *
 * To run a mutation, you first call `useRemoveBookmarkByLibraryItemIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBookmarkByLibraryItemIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBookmarkByLibraryItemIdMutation, { data, loading, error }] = useRemoveBookmarkByLibraryItemIdMutation({
 *   variables: {
 *      libraryItemID: // value for 'libraryItemID'
 *   },
 * });
 */
export function useRemoveBookmarkByLibraryItemIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBookmarkByLibraryItemIdMutation,
    RemoveBookmarkByLibraryItemIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveBookmarkByLibraryItemIdMutation,
    RemoveBookmarkByLibraryItemIdMutationVariables
  >(RemoveBookmarkByLibraryItemIdDocument, options)
}
export type RemoveBookmarkByLibraryItemIdMutationHookResult = ReturnType<
  typeof useRemoveBookmarkByLibraryItemIdMutation
>
export type RemoveBookmarkByLibraryItemIdMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveBookmarkByLibraryItemIdMutation,
    RemoveBookmarkByLibraryItemIdMutationVariables
  >
export const ToggleRedFilterDocument = gql`
  mutation ToggleRedFilter($roomID: ID!, $value: Boolean!) {
    toggleRedFilter(input: { roomID: $roomID, value: $value })
  }
`
export type ToggleRedFilterMutationFn = Apollo.MutationFunction<
  ToggleRedFilterMutation,
  ToggleRedFilterMutationVariables
>

/**
 * __useToggleRedFilterMutation__
 *
 * To run a mutation, you first call `useToggleRedFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleRedFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleRedFilterMutation, { data, loading, error }] = useToggleRedFilterMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useToggleRedFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleRedFilterMutation,
    ToggleRedFilterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleRedFilterMutation,
    ToggleRedFilterMutationVariables
  >(ToggleRedFilterDocument, options)
}
export type ToggleRedFilterMutationHookResult = ReturnType<
  typeof useToggleRedFilterMutation
>
export type ToggleRedFilterMutationOptions = Apollo.BaseMutationOptions<
  ToggleRedFilterMutation,
  ToggleRedFilterMutationVariables
>
export const ForceVideoOffDocument = gql`
  mutation ForceVideoOff($roomID: ID!, $userID: ID!, $value: Boolean!) {
    forceVideoOff(input: { roomID: $roomID, userID: $userID, value: $value })
  }
`
export type ForceVideoOffMutationFn = Apollo.MutationFunction<
  ForceVideoOffMutation,
  ForceVideoOffMutationVariables
>

/**
 * __useForceVideoOffMutation__
 *
 * To run a mutation, you first call `useForceVideoOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceVideoOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceVideoOffMutation, { data, loading, error }] = useForceVideoOffMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useForceVideoOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForceVideoOffMutation,
    ForceVideoOffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ForceVideoOffMutation,
    ForceVideoOffMutationVariables
  >(ForceVideoOffDocument, options)
}
export type ForceVideoOffMutationHookResult = ReturnType<
  typeof useForceVideoOffMutation
>
export type ForceVideoOffMutationOptions = Apollo.BaseMutationOptions<
  ForceVideoOffMutation,
  ForceVideoOffMutationVariables
>
export const ForceSoundOffDocument = gql`
  mutation ForceSoundOff($roomID: ID!, $userID: ID!, $value: Boolean!) {
    forceSoundOff(input: { roomID: $roomID, userID: $userID, value: $value })
  }
`
export type ForceSoundOffMutationFn = Apollo.MutationFunction<
  ForceSoundOffMutation,
  ForceSoundOffMutationVariables
>

/**
 * __useForceSoundOffMutation__
 *
 * To run a mutation, you first call `useForceSoundOffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceSoundOffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceSoundOffMutation, { data, loading, error }] = useForceSoundOffMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useForceSoundOffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForceSoundOffMutation,
    ForceSoundOffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ForceSoundOffMutation,
    ForceSoundOffMutationVariables
  >(ForceSoundOffDocument, options)
}
export type ForceSoundOffMutationHookResult = ReturnType<
  typeof useForceSoundOffMutation
>
export type ForceSoundOffMutationOptions = Apollo.BaseMutationOptions<
  ForceSoundOffMutation,
  ForceSoundOffMutationVariables
>
export const BanUserFromSessionDocument = gql`
  mutation BanUserFromSession($roomID: ID!, $userID: ID!, $value: Boolean!) {
    banUserFromSession(
      input: { roomID: $roomID, userID: $userID, value: $value }
    )
  }
`
export type BanUserFromSessionMutationFn = Apollo.MutationFunction<
  BanUserFromSessionMutation,
  BanUserFromSessionMutationVariables
>

/**
 * __useBanUserFromSessionMutation__
 *
 * To run a mutation, you first call `useBanUserFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserFromSessionMutation, { data, loading, error }] = useBanUserFromSessionMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      userID: // value for 'userID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useBanUserFromSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BanUserFromSessionMutation,
    BanUserFromSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BanUserFromSessionMutation,
    BanUserFromSessionMutationVariables
  >(BanUserFromSessionDocument, options)
}
export type BanUserFromSessionMutationHookResult = ReturnType<
  typeof useBanUserFromSessionMutation
>
export type BanUserFromSessionMutationOptions = Apollo.BaseMutationOptions<
  BanUserFromSessionMutation,
  BanUserFromSessionMutationVariables
>
export const ChangeVideoPreferenceDocument = gql`
  mutation ChangeVideoPreference($roomID: ID!, $value: VideoPreferenceEnum!) {
    changeVideoPreference(input: { roomID: $roomID, value: $value })
  }
`
export type ChangeVideoPreferenceMutationFn = Apollo.MutationFunction<
  ChangeVideoPreferenceMutation,
  ChangeVideoPreferenceMutationVariables
>

/**
 * __useChangeVideoPreferenceMutation__
 *
 * To run a mutation, you first call `useChangeVideoPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVideoPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVideoPreferenceMutation, { data, loading, error }] = useChangeVideoPreferenceMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useChangeVideoPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeVideoPreferenceMutation,
    ChangeVideoPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeVideoPreferenceMutation,
    ChangeVideoPreferenceMutationVariables
  >(ChangeVideoPreferenceDocument, options)
}
export type ChangeVideoPreferenceMutationHookResult = ReturnType<
  typeof useChangeVideoPreferenceMutation
>
export type ChangeVideoPreferenceMutationOptions = Apollo.BaseMutationOptions<
  ChangeVideoPreferenceMutation,
  ChangeVideoPreferenceMutationVariables
>
export const SaveVideoAnalyticsEventDocument = gql`
  mutation SaveVideoAnalyticsEvent(
    $idempotencyKey: String!
    $playedTime: Float!
    $libraryItemID: ID!
  ) {
    saveVideoAnalyticsEvent(
      input: {
        idempotencyKey: $idempotencyKey
        playedTime: $playedTime
        libraryItemID: $libraryItemID
      }
    )
  }
`
export type SaveVideoAnalyticsEventMutationFn = Apollo.MutationFunction<
  SaveVideoAnalyticsEventMutation,
  SaveVideoAnalyticsEventMutationVariables
>

/**
 * __useSaveVideoAnalyticsEventMutation__
 *
 * To run a mutation, you first call `useSaveVideoAnalyticsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveVideoAnalyticsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveVideoAnalyticsEventMutation, { data, loading, error }] = useSaveVideoAnalyticsEventMutation({
 *   variables: {
 *      idempotencyKey: // value for 'idempotencyKey'
 *      playedTime: // value for 'playedTime'
 *      libraryItemID: // value for 'libraryItemID'
 *   },
 * });
 */
export function useSaveVideoAnalyticsEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveVideoAnalyticsEventMutation,
    SaveVideoAnalyticsEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveVideoAnalyticsEventMutation,
    SaveVideoAnalyticsEventMutationVariables
  >(SaveVideoAnalyticsEventDocument, options)
}
export type SaveVideoAnalyticsEventMutationHookResult = ReturnType<
  typeof useSaveVideoAnalyticsEventMutation
>
export type SaveVideoAnalyticsEventMutationOptions = Apollo.BaseMutationOptions<
  SaveVideoAnalyticsEventMutation,
  SaveVideoAnalyticsEventMutationVariables
>
export const ChangeRoomStatusToPreparationDocument = gql`
  mutation ChangeRoomStatusToPreparation($roomID: ID!) {
    changeRoomStatusToPreparation(input: { roomID: $roomID })
  }
`
export type ChangeRoomStatusToPreparationMutationFn = Apollo.MutationFunction<
  ChangeRoomStatusToPreparationMutation,
  ChangeRoomStatusToPreparationMutationVariables
>

/**
 * __useChangeRoomStatusToPreparationMutation__
 *
 * To run a mutation, you first call `useChangeRoomStatusToPreparationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoomStatusToPreparationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoomStatusToPreparationMutation, { data, loading, error }] = useChangeRoomStatusToPreparationMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useChangeRoomStatusToPreparationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRoomStatusToPreparationMutation,
    ChangeRoomStatusToPreparationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeRoomStatusToPreparationMutation,
    ChangeRoomStatusToPreparationMutationVariables
  >(ChangeRoomStatusToPreparationDocument, options)
}
export type ChangeRoomStatusToPreparationMutationHookResult = ReturnType<
  typeof useChangeRoomStatusToPreparationMutation
>
export type ChangeRoomStatusToPreparationMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeRoomStatusToPreparationMutation,
    ChangeRoomStatusToPreparationMutationVariables
  >
export const ChangeRoomStatusToActiveDocument = gql`
  mutation ChangeRoomStatusToActive($roomID: ID!) {
    changeRoomStatusToActive(input: { roomID: $roomID })
  }
`
export type ChangeRoomStatusToActiveMutationFn = Apollo.MutationFunction<
  ChangeRoomStatusToActiveMutation,
  ChangeRoomStatusToActiveMutationVariables
>

/**
 * __useChangeRoomStatusToActiveMutation__
 *
 * To run a mutation, you first call `useChangeRoomStatusToActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRoomStatusToActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRoomStatusToActiveMutation, { data, loading, error }] = useChangeRoomStatusToActiveMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useChangeRoomStatusToActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRoomStatusToActiveMutation,
    ChangeRoomStatusToActiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ChangeRoomStatusToActiveMutation,
    ChangeRoomStatusToActiveMutationVariables
  >(ChangeRoomStatusToActiveDocument, options)
}
export type ChangeRoomStatusToActiveMutationHookResult = ReturnType<
  typeof useChangeRoomStatusToActiveMutation
>
export type ChangeRoomStatusToActiveMutationOptions =
  Apollo.BaseMutationOptions<
    ChangeRoomStatusToActiveMutation,
    ChangeRoomStatusToActiveMutationVariables
  >
export const DisconnectClientDocument = gql`
  mutation DisconnectClient($roomID: ID!) {
    disconnectClient(input: { roomID: $roomID })
  }
`
export type DisconnectClientMutationFn = Apollo.MutationFunction<
  DisconnectClientMutation,
  DisconnectClientMutationVariables
>

/**
 * __useDisconnectClientMutation__
 *
 * To run a mutation, you first call `useDisconnectClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectClientMutation, { data, loading, error }] = useDisconnectClientMutation({
 *   variables: {
 *      roomID: // value for 'roomID'
 *   },
 * });
 */
export function useDisconnectClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectClientMutation,
    DisconnectClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisconnectClientMutation,
    DisconnectClientMutationVariables
  >(DisconnectClientDocument, options)
}
export type DisconnectClientMutationHookResult = ReturnType<
  typeof useDisconnectClientMutation
>
export type DisconnectClientMutationOptions = Apollo.BaseMutationOptions<
  DisconnectClientMutation,
  DisconnectClientMutationVariables
>
export const CreateLiveSessionDocument = gql`
  mutation CreateLiveSession($classSessionID: String!) {
    createLiveSession(input: { classSessionID: $classSessionID }) {
      id
    }
  }
`
export type CreateLiveSessionMutationFn = Apollo.MutationFunction<
  CreateLiveSessionMutation,
  CreateLiveSessionMutationVariables
>

/**
 * __useCreateLiveSessionMutation__
 *
 * To run a mutation, you first call `useCreateLiveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLiveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLiveSessionMutation, { data, loading, error }] = useCreateLiveSessionMutation({
 *   variables: {
 *      classSessionID: // value for 'classSessionID'
 *   },
 * });
 */
export function useCreateLiveSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLiveSessionMutation,
    CreateLiveSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateLiveSessionMutation,
    CreateLiveSessionMutationVariables
  >(CreateLiveSessionDocument, options)
}
export type CreateLiveSessionMutationHookResult = ReturnType<
  typeof useCreateLiveSessionMutation
>
export type CreateLiveSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateLiveSessionMutation,
  CreateLiveSessionMutationVariables
>
export const CancelUserMembershipDocument = gql`
  mutation CancelUserMembership($input: CancelUserMembershipInput!) {
    cancelUserMembership: cancelUserMembership_v2(input: $input) {
      ...MembershipFields
    }
  }
  ${MembershipFieldsFragmentDoc}
`
export type CancelUserMembershipMutationFn = Apollo.MutationFunction<
  CancelUserMembershipMutation,
  CancelUserMembershipMutationVariables
>

/**
 * __useCancelUserMembershipMutation__
 *
 * To run a mutation, you first call `useCancelUserMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserMembershipMutation, { data, loading, error }] = useCancelUserMembershipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelUserMembershipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelUserMembershipMutation,
    CancelUserMembershipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelUserMembershipMutation,
    CancelUserMembershipMutationVariables
  >(CancelUserMembershipDocument, options)
}
export type CancelUserMembershipMutationHookResult = ReturnType<
  typeof useCancelUserMembershipMutation
>
export type CancelUserMembershipMutationOptions = Apollo.BaseMutationOptions<
  CancelUserMembershipMutation,
  CancelUserMembershipMutationVariables
>
export const MuteAllDocument = gql`
  mutation MuteAll($input: MuteAllInput!) {
    muteAll(input: $input)
  }
`
export type MuteAllMutationFn = Apollo.MutationFunction<
  MuteAllMutation,
  MuteAllMutationVariables
>

/**
 * __useMuteAllMutation__
 *
 * To run a mutation, you first call `useMuteAllMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMuteAllMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [muteAllMutation, { data, loading, error }] = useMuteAllMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMuteAllMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MuteAllMutation,
    MuteAllMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MuteAllMutation, MuteAllMutationVariables>(
    MuteAllDocument,
    options
  )
}
export type MuteAllMutationHookResult = ReturnType<typeof useMuteAllMutation>
export type MuteAllMutationOptions = Apollo.BaseMutationOptions<
  MuteAllMutation,
  MuteAllMutationVariables
>
export const StartMusicPlaybackDocument = gql`
  mutation StartMusicPlayback($input: StartMusicPlaybackInput!) {
    startMusicPlayback(input: $input) {
      isSuccessful
      message
    }
  }
`
export type StartMusicPlaybackMutationFn = Apollo.MutationFunction<
  StartMusicPlaybackMutation,
  StartMusicPlaybackMutationVariables
>

/**
 * __useStartMusicPlaybackMutation__
 *
 * To run a mutation, you first call `useStartMusicPlaybackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMusicPlaybackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMusicPlaybackMutation, { data, loading, error }] = useStartMusicPlaybackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartMusicPlaybackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartMusicPlaybackMutation,
    StartMusicPlaybackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StartMusicPlaybackMutation,
    StartMusicPlaybackMutationVariables
  >(StartMusicPlaybackDocument, options)
}
export type StartMusicPlaybackMutationHookResult = ReturnType<
  typeof useStartMusicPlaybackMutation
>
export type StartMusicPlaybackMutationOptions = Apollo.BaseMutationOptions<
  StartMusicPlaybackMutation,
  StartMusicPlaybackMutationVariables
>
export const UpdateMusicPlaybackTimesDocument = gql`
  mutation UpdateMusicPlaybackTimes($input: UpdateMusicPlaybackInput!) {
    updateMusicPlaybackTimes(input: $input) {
      isSuccessful
    }
  }
`
export type UpdateMusicPlaybackTimesMutationFn = Apollo.MutationFunction<
  UpdateMusicPlaybackTimesMutation,
  UpdateMusicPlaybackTimesMutationVariables
>

/**
 * __useUpdateMusicPlaybackTimesMutation__
 *
 * To run a mutation, you first call `useUpdateMusicPlaybackTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMusicPlaybackTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMusicPlaybackTimesMutation, { data, loading, error }] = useUpdateMusicPlaybackTimesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMusicPlaybackTimesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMusicPlaybackTimesMutation,
    UpdateMusicPlaybackTimesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateMusicPlaybackTimesMutation,
    UpdateMusicPlaybackTimesMutationVariables
  >(UpdateMusicPlaybackTimesDocument, options)
}
export type UpdateMusicPlaybackTimesMutationHookResult = ReturnType<
  typeof useUpdateMusicPlaybackTimesMutation
>
export type UpdateMusicPlaybackTimesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMusicPlaybackTimesMutation,
    UpdateMusicPlaybackTimesMutationVariables
  >
export const StoreDeviceInfoDocument = gql`
  mutation StoreDeviceInfo($input: StoreDeviceInfoInput!) {
    storeDeviceInfo(input: $input) {
      id
    }
  }
`
export type StoreDeviceInfoMutationFn = Apollo.MutationFunction<
  StoreDeviceInfoMutation,
  StoreDeviceInfoMutationVariables
>

/**
 * __useStoreDeviceInfoMutation__
 *
 * To run a mutation, you first call `useStoreDeviceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreDeviceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeDeviceInfoMutation, { data, loading, error }] = useStoreDeviceInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreDeviceInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StoreDeviceInfoMutation,
    StoreDeviceInfoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    StoreDeviceInfoMutation,
    StoreDeviceInfoMutationVariables
  >(StoreDeviceInfoDocument, options)
}
export type StoreDeviceInfoMutationHookResult = ReturnType<
  typeof useStoreDeviceInfoMutation
>
export type StoreDeviceInfoMutationOptions = Apollo.BaseMutationOptions<
  StoreDeviceInfoMutation,
  StoreDeviceInfoMutationVariables
>
export const CreateUserDocument = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        ...UserFields
      }
      session {
        ...SessionFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${SessionFieldsFragmentDoc}
`
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  )
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      user {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const ReportGympassEventDocument = gql`
  mutation ReportGympassEvent($eventType: GympassEventEnum!) {
    reportGympassEvent(eventType: $eventType)
  }
`
export type ReportGympassEventMutationFn = Apollo.MutationFunction<
  ReportGympassEventMutation,
  ReportGympassEventMutationVariables
>

/**
 * __useReportGympassEventMutation__
 *
 * To run a mutation, you first call `useReportGympassEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportGympassEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportGympassEventMutation, { data, loading, error }] = useReportGympassEventMutation({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useReportGympassEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportGympassEventMutation,
    ReportGympassEventMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ReportGympassEventMutation,
    ReportGympassEventMutationVariables
  >(ReportGympassEventDocument, options)
}
export type ReportGympassEventMutationHookResult = ReturnType<
  typeof useReportGympassEventMutation
>
export type ReportGympassEventMutationOptions = Apollo.BaseMutationOptions<
  ReportGympassEventMutation,
  ReportGympassEventMutationVariables
>
export const AcceptFriendshipDocument = gql`
  mutation AcceptFriendship($friendshipId: ID!) {
    acceptFriendship(friendshipID: $friendshipId) {
      ...FriendshipFields
    }
  }
  ${FriendshipFieldsFragmentDoc}
`
export type AcceptFriendshipMutationFn = Apollo.MutationFunction<
  AcceptFriendshipMutation,
  AcceptFriendshipMutationVariables
>

/**
 * __useAcceptFriendshipMutation__
 *
 * To run a mutation, you first call `useAcceptFriendshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFriendshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFriendshipMutation, { data, loading, error }] = useAcceptFriendshipMutation({
 *   variables: {
 *      friendshipId: // value for 'friendshipId'
 *   },
 * });
 */
export function useAcceptFriendshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptFriendshipMutation,
    AcceptFriendshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AcceptFriendshipMutation,
    AcceptFriendshipMutationVariables
  >(AcceptFriendshipDocument, options)
}
export type AcceptFriendshipMutationHookResult = ReturnType<
  typeof useAcceptFriendshipMutation
>
export type AcceptFriendshipMutationOptions = Apollo.BaseMutationOptions<
  AcceptFriendshipMutation,
  AcceptFriendshipMutationVariables
>
export const DeclineFriendshipDocument = gql`
  mutation DeclineFriendship($friendshipId: ID!) {
    declineFriendship(friendshipID: $friendshipId) {
      ...FriendshipFields
    }
  }
  ${FriendshipFieldsFragmentDoc}
`
export type DeclineFriendshipMutationFn = Apollo.MutationFunction<
  DeclineFriendshipMutation,
  DeclineFriendshipMutationVariables
>

/**
 * __useDeclineFriendshipMutation__
 *
 * To run a mutation, you first call `useDeclineFriendshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineFriendshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineFriendshipMutation, { data, loading, error }] = useDeclineFriendshipMutation({
 *   variables: {
 *      friendshipId: // value for 'friendshipId'
 *   },
 * });
 */
export function useDeclineFriendshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineFriendshipMutation,
    DeclineFriendshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeclineFriendshipMutation,
    DeclineFriendshipMutationVariables
  >(DeclineFriendshipDocument, options)
}
export type DeclineFriendshipMutationHookResult = ReturnType<
  typeof useDeclineFriendshipMutation
>
export type DeclineFriendshipMutationOptions = Apollo.BaseMutationOptions<
  DeclineFriendshipMutation,
  DeclineFriendshipMutationVariables
>
export const RequestFriendshipDocument = gql`
  mutation RequestFriendship($input: RequestFriendshipInput!) {
    requestFriendship(input: $input) {
      ...FriendshipFields
    }
  }
  ${FriendshipFieldsFragmentDoc}
`
export type RequestFriendshipMutationFn = Apollo.MutationFunction<
  RequestFriendshipMutation,
  RequestFriendshipMutationVariables
>

/**
 * __useRequestFriendshipMutation__
 *
 * To run a mutation, you first call `useRequestFriendshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFriendshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFriendshipMutation, { data, loading, error }] = useRequestFriendshipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestFriendshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestFriendshipMutation,
    RequestFriendshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RequestFriendshipMutation,
    RequestFriendshipMutationVariables
  >(RequestFriendshipDocument, options)
}
export type RequestFriendshipMutationHookResult = ReturnType<
  typeof useRequestFriendshipMutation
>
export type RequestFriendshipMutationOptions = Apollo.BaseMutationOptions<
  RequestFriendshipMutation,
  RequestFriendshipMutationVariables
>
export const RemoveFriendshipDocument = gql`
  mutation RemoveFriendship($friendshipId: ID!) {
    removeFriendship(friendshipID: $friendshipId)
  }
`
export type RemoveFriendshipMutationFn = Apollo.MutationFunction<
  RemoveFriendshipMutation,
  RemoveFriendshipMutationVariables
>

/**
 * __useRemoveFriendshipMutation__
 *
 * To run a mutation, you first call `useRemoveFriendshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFriendshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFriendshipMutation, { data, loading, error }] = useRemoveFriendshipMutation({
 *   variables: {
 *      friendshipId: // value for 'friendshipId'
 *   },
 * });
 */
export function useRemoveFriendshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFriendshipMutation,
    RemoveFriendshipMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveFriendshipMutation,
    RemoveFriendshipMutationVariables
  >(RemoveFriendshipDocument, options)
}
export type RemoveFriendshipMutationHookResult = ReturnType<
  typeof useRemoveFriendshipMutation
>
export type RemoveFriendshipMutationOptions = Apollo.BaseMutationOptions<
  RemoveFriendshipMutation,
  RemoveFriendshipMutationVariables
>
export const CancelFriendshipRequestDocument = gql`
  mutation CancelFriendshipRequest($input: RequestFriendshipInput!) {
    cancelFriendshipRequest(input: $input) {
      friend {
        id
        firstName
        lastName
        username
        pictureUrl
        friendship {
          id
          status
          requester {
            id
          }
        }
      }
    }
  }
`
export type CancelFriendshipRequestMutationFn = Apollo.MutationFunction<
  CancelFriendshipRequestMutation,
  CancelFriendshipRequestMutationVariables
>

/**
 * __useCancelFriendshipRequestMutation__
 *
 * To run a mutation, you first call `useCancelFriendshipRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelFriendshipRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelFriendshipRequestMutation, { data, loading, error }] = useCancelFriendshipRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelFriendshipRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelFriendshipRequestMutation,
    CancelFriendshipRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CancelFriendshipRequestMutation,
    CancelFriendshipRequestMutationVariables
  >(CancelFriendshipRequestDocument, options)
}
export type CancelFriendshipRequestMutationHookResult = ReturnType<
  typeof useCancelFriendshipRequestMutation
>
export type CancelFriendshipRequestMutationOptions = Apollo.BaseMutationOptions<
  CancelFriendshipRequestMutation,
  CancelFriendshipRequestMutationVariables
>
export const CreateReportDocument = gql`
  mutation CreateReport($input: CreateReportInput!) {
    createReport(input: $input) {
      id
    }
  }
`
export type CreateReportMutationFn = Apollo.MutationFunction<
  CreateReportMutation,
  CreateReportMutationVariables
>

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReportMutation,
    CreateReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReportMutation,
    CreateReportMutationVariables
  >(CreateReportDocument, options)
}
export type CreateReportMutationHookResult = ReturnType<
  typeof useCreateReportMutation
>
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<
  CreateReportMutation,
  CreateReportMutationVariables
>
export const GetUserDocument = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options
  )
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>
export const GetUserStatsDocument = gql`
  query GetUserStats($id: ID!) {
    getUserStats(userID: $id) {
      totalClasses
      upcomingClasses
    }
  }
`

/**
 * __useGetUserStatsQuery__
 *
 * To run a query within a React component, call `useGetUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserStatsQuery,
    GetUserStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetUserStatsQuery, GetUserStatsQueryVariables>(
    GetUserStatsDocument,
    options
  )
}
export function useGetUserStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStatsQuery,
    GetUserStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetUserStatsQuery, GetUserStatsQueryVariables>(
    GetUserStatsDocument,
    options
  )
}
export type GetUserStatsQueryHookResult = ReturnType<
  typeof useGetUserStatsQuery
>
export type GetUserStatsLazyQueryHookResult = ReturnType<
  typeof useGetUserStatsLazyQuery
>
export const ListClassSessionsDocument = gql`
  query ListClassSessions($input: ListClassSessionInput!) {
    listClassSessions(input: $input) {
      id
      name
      startDateTime
      openingDateTime
      duration
      participantsCount
      hasReservation
      participants {
        id
        pictureUrl
      }
      instructors {
        id
        pictureUrl
        firstName
        lastName
      }
      location {
        id
        name
      }
    }
  }
`

/**
 * __useListClassSessionsQuery__
 *
 * To run a query within a React component, call `useListClassSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClassSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClassSessionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListClassSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListClassSessionsQuery,
    ListClassSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListClassSessionsQuery,
    ListClassSessionsQueryVariables
  >(ListClassSessionsDocument, options)
}
export function useListClassSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListClassSessionsQuery,
    ListClassSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListClassSessionsQuery,
    ListClassSessionsQueryVariables
  >(ListClassSessionsDocument, options)
}
export type ListClassSessionsQueryHookResult = ReturnType<
  typeof useListClassSessionsQuery
>
export type ListClassSessionsLazyQueryHookResult = ReturnType<
  typeof useListClassSessionsLazyQuery
>
export const ListWorkoutSummariesDocument = gql`
  query ListWorkoutSummaries($input: ListWorkoutSummaryInput!) {
    listWorkoutSummaries(input: $input) {
      id
      createdAt
      totalTime
      activeCalories
      totalCalories
      heartRate
      maxHeartRate
      miles
      topMPH
      averageBPM
      bpmChart {
        timestamp
        value
      }
    }
  }
`

/**
 * __useListWorkoutSummariesQuery__
 *
 * To run a query within a React component, call `useListWorkoutSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkoutSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkoutSummariesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListWorkoutSummariesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListWorkoutSummariesQuery,
    ListWorkoutSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListWorkoutSummariesQuery,
    ListWorkoutSummariesQueryVariables
  >(ListWorkoutSummariesDocument, options)
}
export function useListWorkoutSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListWorkoutSummariesQuery,
    ListWorkoutSummariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListWorkoutSummariesQuery,
    ListWorkoutSummariesQueryVariables
  >(ListWorkoutSummariesDocument, options)
}
export type ListWorkoutSummariesQueryHookResult = ReturnType<
  typeof useListWorkoutSummariesQuery
>
export type ListWorkoutSummariesLazyQueryHookResult = ReturnType<
  typeof useListWorkoutSummariesLazyQuery
>
export const ListUserMembershipsDocument = gql`
  query ListUserMemberships($page: Int, $size: Int = 100) {
    listUserMemberships(input: { page: $page, size: $size }) {
      ...MembershipFields
    }
  }
  ${MembershipFieldsFragmentDoc}
`

/**
 * __useListUserMembershipsQuery__
 *
 * To run a query within a React component, call `useListUserMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserMembershipsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useListUserMembershipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserMembershipsQuery,
    ListUserMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListUserMembershipsQuery,
    ListUserMembershipsQueryVariables
  >(ListUserMembershipsDocument, options)
}
export function useListUserMembershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserMembershipsQuery,
    ListUserMembershipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListUserMembershipsQuery,
    ListUserMembershipsQueryVariables
  >(ListUserMembershipsDocument, options)
}
export type ListUserMembershipsQueryHookResult = ReturnType<
  typeof useListUserMembershipsQuery
>
export type ListUserMembershipsLazyQueryHookResult = ReturnType<
  typeof useListUserMembershipsLazyQuery
>
export const ListUserPurchasesDocument = gql`
  query ListUserPurchases(
    $type: PurchaseTypeEnum! = SUBSCRIPTION
    $platform: PlatformEnum
  ) {
    listUserPurchases(input: { type: $type, platform: $platform }) {
      platform
    }
  }
`

/**
 * __useListUserPurchasesQuery__
 *
 * To run a query within a React component, call `useListUserPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserPurchasesQuery({
 *   variables: {
 *      type: // value for 'type'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useListUserPurchasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserPurchasesQuery,
    ListUserPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListUserPurchasesQuery,
    ListUserPurchasesQueryVariables
  >(ListUserPurchasesDocument, options)
}
export function useListUserPurchasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserPurchasesQuery,
    ListUserPurchasesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListUserPurchasesQuery,
    ListUserPurchasesQueryVariables
  >(ListUserPurchasesDocument, options)
}
export type ListUserPurchasesQueryHookResult = ReturnType<
  typeof useListUserPurchasesQuery
>
export type ListUserPurchasesLazyQueryHookResult = ReturnType<
  typeof useListUserPurchasesLazyQuery
>
export const ListUserClassSessionsDocument = gql`
  query ListUserClassSessions(
    $page: Int
    $size: Int = 10
    $status: [ReservationStatusEnum!] = [CHECK_IN, PENDING, UPCOMING]
    $sortBy: ReservationSortingEnum! = CLASS_SESSION_START_DATETIME_ASC
  ) {
    listUserClassSessions(
      input: { page: $page, size: $size, status: $status, sortBy: $sortBy }
    ) {
      id
      name
      isDigital
      startDateTime
      openingDateTime
      duration
      participantsCount
      hasReservation
      activeReservation {
        roomIndex
      }
      participants {
        id
        pictureUrl
      }
      instructors {
        id
        pictureUrl
        firstName
        lastName
        region {
          id
          name
        }
      }
      location {
        id
        name
      }
    }
  }
`

/**
 * __useListUserClassSessionsQuery__
 *
 * To run a query within a React component, call `useListUserClassSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserClassSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserClassSessionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      size: // value for 'size'
 *      status: // value for 'status'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useListUserClassSessionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserClassSessionsQuery,
    ListUserClassSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListUserClassSessionsQuery,
    ListUserClassSessionsQueryVariables
  >(ListUserClassSessionsDocument, options)
}
export function useListUserClassSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserClassSessionsQuery,
    ListUserClassSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListUserClassSessionsQuery,
    ListUserClassSessionsQueryVariables
  >(ListUserClassSessionsDocument, options)
}
export type ListUserClassSessionsQueryHookResult = ReturnType<
  typeof useListUserClassSessionsQuery
>
export type ListUserClassSessionsLazyQueryHookResult = ReturnType<
  typeof useListUserClassSessionsLazyQuery
>
export const ListInstructorsDocument = gql`
  query ListInstructors($input: ListInstructorsInput_v2!) {
    listInstructors: listInstructors_v2(input: $input) {
      ...InstructorFields
    }
  }
  ${InstructorFieldsFragmentDoc}
`

/**
 * __useListInstructorsQuery__
 *
 * To run a query within a React component, call `useListInstructorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstructorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstructorsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListInstructorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListInstructorsQuery,
    ListInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListInstructorsQuery, ListInstructorsQueryVariables>(
    ListInstructorsDocument,
    options
  )
}
export function useListInstructorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListInstructorsQuery,
    ListInstructorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListInstructorsQuery,
    ListInstructorsQueryVariables
  >(ListInstructorsDocument, options)
}
export type ListInstructorsQueryHookResult = ReturnType<
  typeof useListInstructorsQuery
>
export type ListInstructorsLazyQueryHookResult = ReturnType<
  typeof useListInstructorsLazyQuery
>
export const GetInstructorDetailDocument = gql`
  query GetInstructorDetail($id: ID!, $input: ListInstructorVodFiltersInput!) {
    getInstructor(id: $id) {
      ...InstructorFields
    }
    listInstructorVodFilters(input: $input) {
      classTypeFilters
    }
  }
  ${InstructorFieldsFragmentDoc}
`

/**
 * __useGetInstructorDetailQuery__
 *
 * To run a query within a React component, call `useGetInstructorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstructorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstructorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetInstructorDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInstructorDetailQuery,
    GetInstructorDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetInstructorDetailQuery,
    GetInstructorDetailQueryVariables
  >(GetInstructorDetailDocument, options)
}
export function useGetInstructorDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInstructorDetailQuery,
    GetInstructorDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetInstructorDetailQuery,
    GetInstructorDetailQueryVariables
  >(GetInstructorDetailDocument, options)
}
export type GetInstructorDetailQueryHookResult = ReturnType<
  typeof useGetInstructorDetailQuery
>
export type GetInstructorDetailLazyQueryHookResult = ReturnType<
  typeof useGetInstructorDetailLazyQuery
>
export const GetLiveSessionDocument = gql`
  query GetLiveSession($id: ID!) {
    getLiveSession(input: { id: $id }) {
      id
      url
      status
      rooms {
        name
        roomIndex
      }
      classSession {
        id
      }
    }
  }
`

/**
 * __useGetLiveSessionQuery__
 *
 * To run a query within a React component, call `useGetLiveSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLiveSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveSessionQuery,
    GetLiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLiveSessionQuery, GetLiveSessionQueryVariables>(
    GetLiveSessionDocument,
    options
  )
}
export function useGetLiveSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveSessionQuery,
    GetLiveSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLiveSessionQuery, GetLiveSessionQueryVariables>(
    GetLiveSessionDocument,
    options
  )
}
export type GetLiveSessionQueryHookResult = ReturnType<
  typeof useGetLiveSessionQuery
>
export type GetLiveSessionLazyQueryHookResult = ReturnType<
  typeof useGetLiveSessionLazyQuery
>
export const GetClassSessionDocument = gql`
  query GetClassSession($id: ID!) {
    getClassSession(id: $id) {
      id
      name
      startDateTime
      endDateTime
      spotsCount
      duration
      participantsCount
      hasReservation
      userRated
      activeReservationID
      moderatorID
      classroom {
        id
        name
      }
      region {
        id
        name
      }
      location {
        id
        name
      }
      instructors {
        ...InstructorFields
      }
      participants {
        ...UserFields
      }
      rooms {
        name
        roomIndex
        participants {
          id
        }
      }
      spotifyPlaylistIDs {
        playlistID
        imageURL
        title
      }
      appleMusicPlaylistIDs
    }
  }
  ${InstructorFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`

/**
 * __useGetClassSessionQuery__
 *
 * To run a query within a React component, call `useGetClassSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClassSessionQuery,
    GetClassSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetClassSessionQuery, GetClassSessionQueryVariables>(
    GetClassSessionDocument,
    options
  )
}
export function useGetClassSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClassSessionQuery,
    GetClassSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetClassSessionQuery,
    GetClassSessionQueryVariables
  >(GetClassSessionDocument, options)
}
export type GetClassSessionQueryHookResult = ReturnType<
  typeof useGetClassSessionQuery
>
export type GetClassSessionLazyQueryHookResult = ReturnType<
  typeof useGetClassSessionLazyQuery
>
export const ListVodFiltersDocument = gql`
  query ListVodFilters($category: LibraryItemCategoryEnum!) {
    listVodFilters(input: { category: $category }) {
      bodyParts {
        name
        type
      }
      bodyPartsFilters {
        name
        type
      }
      equipment {
        name
        type
        image
      }
      equipmentFilters_v2 {
        name
        type
        image
      }
      classTypeFilters
      labels {
        name
        type
      }
      types {
        name
        type
      }
      durations {
        name
        type
      }
      statuses {
        name
        type
      }
    }
  }
`

/**
 * __useListVodFiltersQuery__
 *
 * To run a query within a React component, call `useListVodFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVodFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVodFiltersQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useListVodFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListVodFiltersQuery,
    ListVodFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListVodFiltersQuery, ListVodFiltersQueryVariables>(
    ListVodFiltersDocument,
    options
  )
}
export function useListVodFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListVodFiltersQuery,
    ListVodFiltersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListVodFiltersQuery, ListVodFiltersQueryVariables>(
    ListVodFiltersDocument,
    options
  )
}
export type ListVodFiltersQueryHookResult = ReturnType<
  typeof useListVodFiltersQuery
>
export type ListVodFiltersLazyQueryHookResult = ReturnType<
  typeof useListVodFiltersLazyQuery
>
export const CountLibraryItemsDocument = gql`
  query CountLibraryItems($input: ListLibraryItemsInput) {
    countLibraryItems(input: $input)
  }
`

/**
 * __useCountLibraryItemsQuery__
 *
 * To run a query within a React component, call `useCountLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountLibraryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountLibraryItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountLibraryItemsQuery,
    CountLibraryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CountLibraryItemsQuery,
    CountLibraryItemsQueryVariables
  >(CountLibraryItemsDocument, options)
}
export function useCountLibraryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountLibraryItemsQuery,
    CountLibraryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CountLibraryItemsQuery,
    CountLibraryItemsQueryVariables
  >(CountLibraryItemsDocument, options)
}
export type CountLibraryItemsQueryHookResult = ReturnType<
  typeof useCountLibraryItemsQuery
>
export type CountLibraryItemsLazyQueryHookResult = ReturnType<
  typeof useCountLibraryItemsLazyQuery
>
export const ListLibraryItemsDocument = gql`
  query ListLibraryItems($input: ListLibraryItemsInput) {
    listLibraryItems(input: $input) {
      ...LibraryItems
      instructor {
        id
        firstName
        lastName
        pictureUrl
      }
    }
  }
  ${LibraryItemsFragmentDoc}
`

/**
 * __useListLibraryItemsQuery__
 *
 * To run a query within a React component, call `useListLibraryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibraryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibraryItemsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListLibraryItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListLibraryItemsQuery,
    ListLibraryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListLibraryItemsQuery, ListLibraryItemsQueryVariables>(
    ListLibraryItemsDocument,
    options
  )
}
export function useListLibraryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLibraryItemsQuery,
    ListLibraryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListLibraryItemsQuery,
    ListLibraryItemsQueryVariables
  >(ListLibraryItemsDocument, options)
}
export type ListLibraryItemsQueryHookResult = ReturnType<
  typeof useListLibraryItemsQuery
>
export type ListLibraryItemsLazyQueryHookResult = ReturnType<
  typeof useListLibraryItemsLazyQuery
>
export const ListLibraryItemCategoriesDocument = gql`
  query ListLibraryItemCategories(
    $input: GetLibraryItemCategoriesTotalInput
    $totalInput: GetLibraryItemCategoriesTotalInput
  ) {
    listLibraryItemCategories(input: $input) {
      name
      type
      verticalImage
      total: total_v2(input: $totalInput)
    }
  }
`

/**
 * __useListLibraryItemCategoriesQuery__
 *
 * To run a query within a React component, call `useListLibraryItemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibraryItemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibraryItemCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      totalInput: // value for 'totalInput'
 *   },
 * });
 */
export function useListLibraryItemCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListLibraryItemCategoriesQuery,
    ListLibraryItemCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListLibraryItemCategoriesQuery,
    ListLibraryItemCategoriesQueryVariables
  >(ListLibraryItemCategoriesDocument, options)
}
export function useListLibraryItemCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLibraryItemCategoriesQuery,
    ListLibraryItemCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListLibraryItemCategoriesQuery,
    ListLibraryItemCategoriesQueryVariables
  >(ListLibraryItemCategoriesDocument, options)
}
export type ListLibraryItemCategoriesQueryHookResult = ReturnType<
  typeof useListLibraryItemCategoriesQuery
>
export type ListLibraryItemCategoriesLazyQueryHookResult = ReturnType<
  typeof useListLibraryItemCategoriesLazyQuery
>
export const GetLibraryItemDocument = gql`
  query GetLibraryItem($id: ID!) {
    getLibraryItem(id: $id) {
      id
      createdAt
      title
      description
      equipment: equipment_v2 {
        name
        type
        image
      }
      labels {
        name
        type
      }
      duration {
        name
        type
      }
      imageURL
      type
      isBookmarked
      instructor {
        ...InstructorFields
      }
      additionalInstructor {
        ...InstructorFields
      }
      spotifyPlaylistIDs {
        playlistID
        imageURL
        title
      }
      appleMusicPlaylistIDs
      viewed
      bodyweight
      userRated
      hasWorkoutSummary
    }
  }
  ${InstructorFieldsFragmentDoc}
`

/**
 * __useGetLibraryItemQuery__
 *
 * To run a query within a React component, call `useGetLibraryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLibraryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLibraryItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLibraryItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLibraryItemQuery,
    GetLibraryItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetLibraryItemQuery, GetLibraryItemQueryVariables>(
    GetLibraryItemDocument,
    options
  )
}
export function useGetLibraryItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLibraryItemQuery,
    GetLibraryItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetLibraryItemQuery, GetLibraryItemQueryVariables>(
    GetLibraryItemDocument,
    options
  )
}
export type GetLibraryItemQueryHookResult = ReturnType<
  typeof useGetLibraryItemQuery
>
export type GetLibraryItemLazyQueryHookResult = ReturnType<
  typeof useGetLibraryItemLazyQuery
>
export const GetFileUrlDocument = gql`
  query GetFileURL($input: GetFileURLInput_v3!) {
    getFileURL_v3(input: $input) {
      main
      instructor
      music
    }
  }
`

/**
 * __useGetFileUrlQuery__
 *
 * To run a query within a React component, call `useGetFileUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileUrlQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetFileUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFileUrlQuery,
    GetFileUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFileUrlQuery, GetFileUrlQueryVariables>(
    GetFileUrlDocument,
    options
  )
}
export function useGetFileUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileUrlQuery,
    GetFileUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFileUrlQuery, GetFileUrlQueryVariables>(
    GetFileUrlDocument,
    options
  )
}
export type GetFileUrlQueryHookResult = ReturnType<typeof useGetFileUrlQuery>
export type GetFileUrlLazyQueryHookResult = ReturnType<
  typeof useGetFileUrlLazyQuery
>
export const ListOnDemandCollectionSectionsDocument = gql`
  query ListOnDemandCollectionSections($input: PaginationInput) {
    listOnDemandCollectionSections {
      name
      order
      collections(input: $input) {
        id
        name
        imageURL
        collectionItemsCount
      }
    }
  }
`

/**
 * __useListOnDemandCollectionSectionsQuery__
 *
 * To run a query within a React component, call `useListOnDemandCollectionSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOnDemandCollectionSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOnDemandCollectionSectionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListOnDemandCollectionSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListOnDemandCollectionSectionsQuery,
    ListOnDemandCollectionSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListOnDemandCollectionSectionsQuery,
    ListOnDemandCollectionSectionsQueryVariables
  >(ListOnDemandCollectionSectionsDocument, options)
}
export function useListOnDemandCollectionSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListOnDemandCollectionSectionsQuery,
    ListOnDemandCollectionSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListOnDemandCollectionSectionsQuery,
    ListOnDemandCollectionSectionsQueryVariables
  >(ListOnDemandCollectionSectionsDocument, options)
}
export type ListOnDemandCollectionSectionsQueryHookResult = ReturnType<
  typeof useListOnDemandCollectionSectionsQuery
>
export type ListOnDemandCollectionSectionsLazyQueryHookResult = ReturnType<
  typeof useListOnDemandCollectionSectionsLazyQuery
>
export const GetOnDemandCollectionDocument = gql`
  query GetOnDemandCollection($id: ID!, $input: PaginationInput) {
    getOnDemandCollection(id: $id) {
      id
      name
      collectionItemsCount
      collectionItems(input: $input) {
        libraryItem {
          ...LibraryItems
        }
      }
    }
  }
  ${LibraryItemsFragmentDoc}
`

/**
 * __useGetOnDemandCollectionQuery__
 *
 * To run a query within a React component, call `useGetOnDemandCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnDemandCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnDemandCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOnDemandCollectionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOnDemandCollectionQuery,
    GetOnDemandCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetOnDemandCollectionQuery,
    GetOnDemandCollectionQueryVariables
  >(GetOnDemandCollectionDocument, options)
}
export function useGetOnDemandCollectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOnDemandCollectionQuery,
    GetOnDemandCollectionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetOnDemandCollectionQuery,
    GetOnDemandCollectionQueryVariables
  >(GetOnDemandCollectionDocument, options)
}
export type GetOnDemandCollectionQueryHookResult = ReturnType<
  typeof useGetOnDemandCollectionQuery
>
export type GetOnDemandCollectionLazyQueryHookResult = ReturnType<
  typeof useGetOnDemandCollectionLazyQuery
>
export const ListRegionsDocument = gql`
  query ListRegions($input: ListRegionsInput) {
    listRegions(input: $input) {
      id
      name
      country {
        code
        name
      }
      locations {
        id
        name
      }
    }
  }
`

/**
 * __useListRegionsQuery__
 *
 * To run a query within a React component, call `useListRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRegionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListRegionsQuery,
    ListRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListRegionsQuery, ListRegionsQueryVariables>(
    ListRegionsDocument,
    options
  )
}
export function useListRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListRegionsQuery,
    ListRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListRegionsQuery, ListRegionsQueryVariables>(
    ListRegionsDocument,
    options
  )
}
export type ListRegionsQueryHookResult = ReturnType<typeof useListRegionsQuery>
export type ListRegionsLazyQueryHookResult = ReturnType<
  typeof useListRegionsLazyQuery
>
export const ListOnDemandSectionsDocument = gql`
  query ListOnDemandSections(
    $filter: OnDemandSectionFilter
    $input: PaginationInput
  ) {
    listOnDemandSections(input: { filter: $filter }) {
      id
      name
      category {
        name
        type
      }
      items(input: $input) {
        id
        libraryItem {
          ...LibraryItems
        }
      }
    }
  }
  ${LibraryItemsFragmentDoc}
`

/**
 * __useListOnDemandSectionsQuery__
 *
 * To run a query within a React component, call `useListOnDemandSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOnDemandSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOnDemandSectionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListOnDemandSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListOnDemandSectionsQuery,
    ListOnDemandSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ListOnDemandSectionsQuery,
    ListOnDemandSectionsQueryVariables
  >(ListOnDemandSectionsDocument, options)
}
export function useListOnDemandSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListOnDemandSectionsQuery,
    ListOnDemandSectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListOnDemandSectionsQuery,
    ListOnDemandSectionsQueryVariables
  >(ListOnDemandSectionsDocument, options)
}
export type ListOnDemandSectionsQueryHookResult = ReturnType<
  typeof useListOnDemandSectionsQuery
>
export type ListOnDemandSectionsLazyQueryHookResult = ReturnType<
  typeof useListOnDemandSectionsLazyQuery
>
export const ListBookmarksDocument = gql`
  query ListBookmarks($input: ListUserBookmarksInput) {
    listBookmarks(input: $input) {
      id
      createdAt
      libraryItem {
        ...LibraryItems
      }
      collection {
        id
        name
      }
    }
  }
  ${LibraryItemsFragmentDoc}
`

/**
 * __useListBookmarksQuery__
 *
 * To run a query within a React component, call `useListBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBookmarksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListBookmarksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListBookmarksQuery,
    ListBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListBookmarksQuery, ListBookmarksQueryVariables>(
    ListBookmarksDocument,
    options
  )
}
export function useListBookmarksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBookmarksQuery,
    ListBookmarksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ListBookmarksQuery, ListBookmarksQueryVariables>(
    ListBookmarksDocument,
    options
  )
}
export type ListBookmarksQueryHookResult = ReturnType<
  typeof useListBookmarksQuery
>
export type ListBookmarksLazyQueryHookResult = ReturnType<
  typeof useListBookmarksLazyQuery
>
export const HasUserUnlimitedMembershipDocument = gql`
  query HasUserUnlimitedMembership {
    hasUserUnlimitedMembership
  }
`

/**
 * __useHasUserUnlimitedMembershipQuery__
 *
 * To run a query within a React component, call `useHasUserUnlimitedMembershipQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasUserUnlimitedMembershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasUserUnlimitedMembershipQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasUserUnlimitedMembershipQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasUserUnlimitedMembershipQuery,
    HasUserUnlimitedMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HasUserUnlimitedMembershipQuery,
    HasUserUnlimitedMembershipQueryVariables
  >(HasUserUnlimitedMembershipDocument, options)
}
export function useHasUserUnlimitedMembershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasUserUnlimitedMembershipQuery,
    HasUserUnlimitedMembershipQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HasUserUnlimitedMembershipQuery,
    HasUserUnlimitedMembershipQueryVariables
  >(HasUserUnlimitedMembershipDocument, options)
}
export type HasUserUnlimitedMembershipQueryHookResult = ReturnType<
  typeof useHasUserUnlimitedMembershipQuery
>
export type HasUserUnlimitedMembershipLazyQueryHookResult = ReturnType<
  typeof useHasUserUnlimitedMembershipLazyQuery
>
export const UsernameExistsDocument = gql`
  query UsernameExists($username: String!) {
    usernameExists(username: $username)
  }
`

/**
 * __useUsernameExistsQuery__
 *
 * To run a query within a React component, call `useUsernameExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameExistsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUsernameExistsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UsernameExistsQuery,
    UsernameExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(
    UsernameExistsDocument,
    options
  )
}
export function useUsernameExistsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsernameExistsQuery,
    UsernameExistsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UsernameExistsQuery, UsernameExistsQueryVariables>(
    UsernameExistsDocument,
    options
  )
}
export type UsernameExistsQueryHookResult = ReturnType<
  typeof useUsernameExistsQuery
>
export type UsernameExistsLazyQueryHookResult = ReturnType<
  typeof useUsernameExistsLazyQuery
>
export const GetSignedUrlDocument = gql`
  query GetSignedUrl($options: GetSignedUrlInput!) {
    getSignedUrl(options: $options) {
      url
      publicUrl
      expiresAt
    }
  }
`

/**
 * __useGetSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSignedUrlQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSignedUrlQuery,
    GetSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(
    GetSignedUrlDocument,
    options
  )
}
export function useGetSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSignedUrlQuery,
    GetSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSignedUrlQuery, GetSignedUrlQueryVariables>(
    GetSignedUrlDocument,
    options
  )
}
export type GetSignedUrlQueryHookResult = ReturnType<
  typeof useGetSignedUrlQuery
>
export type GetSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetSignedUrlLazyQuery
>
export const GetGympassUserDataDocument = gql`
  query GetGympassUserData($gympassUID: String!) {
    getGympassUserData(gympassUID: $gympassUID) {
      id
      createdAt
      gympassUID
      email
      firstName
      lastName
      origin
      userStatus
      countryCode
    }
  }
`

/**
 * __useGetGympassUserDataQuery__
 *
 * To run a query within a React component, call `useGetGympassUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGympassUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGympassUserDataQuery({
 *   variables: {
 *      gympassUID: // value for 'gympassUID'
 *   },
 * });
 */
export function useGetGympassUserDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGympassUserDataQuery,
    GetGympassUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetGympassUserDataQuery,
    GetGympassUserDataQueryVariables
  >(GetGympassUserDataDocument, options)
}
export function useGetGympassUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGympassUserDataQuery,
    GetGympassUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetGympassUserDataQuery,
    GetGympassUserDataQueryVariables
  >(GetGympassUserDataDocument, options)
}
export type GetGympassUserDataQueryHookResult = ReturnType<
  typeof useGetGympassUserDataQuery
>
export type GetGympassUserDataLazyQueryHookResult = ReturnType<
  typeof useGetGympassUserDataLazyQuery
>
export const GetUserAttendanceDocument = gql`
  query GetUserAttendance($userID: ID!, $format: String!) {
    getUserAttendance(userID: $userID, format: $format) {
      type
      value
      entries {
        value
        count
      }
    }
  }
`

/**
 * __useGetUserAttendanceQuery__
 *
 * To run a query within a React component, call `useGetUserAttendanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAttendanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAttendanceQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      format: // value for 'format'
 *   },
 * });
 */
export function useGetUserAttendanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserAttendanceQuery,
    GetUserAttendanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserAttendanceQuery,
    GetUserAttendanceQueryVariables
  >(GetUserAttendanceDocument, options)
}
export function useGetUserAttendanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserAttendanceQuery,
    GetUserAttendanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserAttendanceQuery,
    GetUserAttendanceQueryVariables
  >(GetUserAttendanceDocument, options)
}
export type GetUserAttendanceQueryHookResult = ReturnType<
  typeof useGetUserAttendanceQuery
>
export type GetUserAttendanceLazyQueryHookResult = ReturnType<
  typeof useGetUserAttendanceLazyQuery
>
export const GetProfileUserDataDocument = gql`
  query GetProfileUserData($userId: ID!) {
    getUser(id: $userId) {
      id
      firstName
      lastName
      username
      pictureUrl
      loyaltyTier
      bio
      friendsCount
      friendship {
        ...FriendshipFields
      }
      memberSince
      homeLocation {
        id
        name
      }
    }
    getUserStats(userID: $userId) {
      spentAtHome
      totalClasses
      upcomingClasses
      digitalClasses
      redRoomClasses
      studiosVisited {
        numVisited
        region {
          name
          location {
            name
          }
        }
      }
      favStudio {
        numVisited
        region {
          name
          location {
            name
          }
        }
      }
      longestStreak
      bestWeek
      favoriteDay
      favoriteTime
      vodClasses
      cardioVODClasses
      strengthVODClasses
      stretchVODClasses
      strengthCardioVODClasses
      workouts {
        name
        count
      }
      instructors {
        id
        firstName
        lastName
        pictureUrl
        lessonsCount
        location {
          id
          name
        }
      }
    }
    getUserAttendance(userID: $userId, format: "year") {
      type
      value
      entries {
        value
        count
      }
    }
  }
  ${FriendshipFieldsFragmentDoc}
`

/**
 * __useGetProfileUserDataQuery__
 *
 * To run a query within a React component, call `useGetProfileUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileUserDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetProfileUserDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProfileUserDataQuery,
    GetProfileUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetProfileUserDataQuery,
    GetProfileUserDataQueryVariables
  >(GetProfileUserDataDocument, options)
}
export function useGetProfileUserDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileUserDataQuery,
    GetProfileUserDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetProfileUserDataQuery,
    GetProfileUserDataQueryVariables
  >(GetProfileUserDataDocument, options)
}
export type GetProfileUserDataQueryHookResult = ReturnType<
  typeof useGetProfileUserDataQuery
>
export type GetProfileUserDataLazyQueryHookResult = ReturnType<
  typeof useGetProfileUserDataLazyQuery
>
export const ListFriendshipsDocument = gql`
  query ListFriendships($input: ListFriendshipInput) {
    listFriendships_v2(input: $input) {
      ...FriendshipFields
    }
  }
  ${FriendshipFieldsFragmentDoc}
`

/**
 * __useListFriendshipsQuery__
 *
 * To run a query within a React component, call `useListFriendshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFriendshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFriendshipsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useListFriendshipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFriendshipsQuery,
    ListFriendshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ListFriendshipsQuery, ListFriendshipsQueryVariables>(
    ListFriendshipsDocument,
    options
  )
}
export function useListFriendshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFriendshipsQuery,
    ListFriendshipsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ListFriendshipsQuery,
    ListFriendshipsQueryVariables
  >(ListFriendshipsDocument, options)
}
export type ListFriendshipsQueryHookResult = ReturnType<
  typeof useListFriendshipsQuery
>
export type ListFriendshipsLazyQueryHookResult = ReturnType<
  typeof useListFriendshipsLazyQuery
>
