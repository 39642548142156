import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useResetPasswordMutation } from '~/@types/schemas'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Input } from '~/components/form-field/input'
import { strings } from '~/lang/en'
import {
  ForgotPasswordLink,
  Heading,
  Inner,
  Description,
  Form,
} from '../styled'

type Props = {
  onCancel: () => void
  onSuccess: () => void
}

type Values = {
  email: string
}

export const ResetPasswordForm = ({ onCancel, onSuccess }: Props) => {
  const [isLoading, setLoading] = useState(false)

  const { register, formState, handleSubmit, setError } = useForm<Values>({
    mode: 'onChange',
  })

  const [resetPassword] = useResetPasswordMutation()

  const onSubmit = async ({ email }: Values) => {
    setLoading(true)

    try {
      await resetPassword({
        variables: {
          email,
        },
      })

      onSuccess()
    } catch (err) {
      setError('email', {
        message: (err.message || '').includes('MarianaTek')
          ? 'Invalid email'
          : err.message,
      })
    }

    setLoading(false)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Forgot password</Heading>

      <Inner>
        <Description>
          <p>{strings.login.forgot_password.form_title}</p>
        </Description>
        <Input
          label="Email"
          type="email"
          error={formState.errors.email?.message}
          {...register('email', { required: true })}
        />
        <ForgotPasswordLink type="button" onClick={onCancel}>
          {strings.login.forgot_password.back}
        </ForgotPasswordLink>
      </Inner>

      <ButtonWithLoading
        inverted
        block
        isLoading={isLoading}
        disabled={!formState.isValid}
      >
        Reset password
      </ButtonWithLoading>
    </Form>
  )
}
