import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Overlay } from '~/styles/misc'

const DarkOverlay = styled.div`
  ${Overlay}
  position: fixed;
  pointer-events: none;
  z-index: 10000;
`

export const TrueDarkMode = () => {
  const [isActive, setActive] = useState(false)
  const [coords, setCoords] = useState({ x: -1, y: -1 })
  const [isBlured, setBlured] = useState(false)
  const [size, setSize] = useState(8)

  const handleMouseMove = (evt: MouseEvent) => {
    setCoords({ x: evt.clientX, y: evt.clientY })
  }

  const handleBlur = () => {
    setBlured(true)
  }

  const handleFocus = () => {
    setBlured(false)
  }

  const handleSize = (evt: KeyboardEvent) => {
    if (
      evt.code === 'KeyB' &&
      evt.ctrlKey &&
      !evt.metaKey &&
      !evt.altKey &&
      !evt.shiftKey
    ) {
      setSize(s => (s > 3 ? s - 3 : s))
      evt.preventDefault()
    }

    if (
      evt.code === 'KeyN' &&
      evt.ctrlKey &&
      !evt.metaKey &&
      !evt.altKey &&
      !evt.shiftKey
    ) {
      setSize(s => (s < 50 ? s + 3 : s))
      evt.preventDefault()
    }
  }

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (
      evt.ctrlKey &&
      evt.shiftKey &&
      !evt.metaKey &&
      !evt.altKey &&
      evt.code === 'KeyQ' &&
      evt.repeat
    ) {
      setActive(true)
      document.addEventListener('keydown', handleSize)
      evt.preventDefault()
    }
  }

  useEffect(() => {
    document.body.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('keydown', handleKeyDown)
    window.addEventListener('blur', handleBlur)
    window.addEventListener('focus', handleFocus)

    return () => {
      document.body.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keydown', handleSize)
      window.removeEventListener('blur', handleBlur)
      window.removeEventListener('focus', handleFocus)
    }
  }, [])

  return isActive ? (
    <DarkOverlay
      style={{
        background:
          isBlured || coords.x < 0
            ? `rgba(0, 0, 0, 0.98)`
            : `radial-gradient(
    circle at ${coords.x}px ${coords.y}px,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.98) ${size}vmax
  )`,
      }}
    />
  ) : null
}
