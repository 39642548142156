import styled, { css } from 'styled-components'
import { styleReset } from '~/styles/misc'
import { theme } from '~/styles/theme'
import { label } from '~/styles/typography'
import { ErrorMessage as ErrorMessageBase } from '~/components/form-elements/error-message'

export type InputProps = {
  hasError?: boolean
  hasRightAddon?: boolean
  variant?: 'inverted' | 'light' | 'default'
}

const inputStyles = css<InputProps>`
  ${styleReset}
  flex-shrink: 0;
  width: 100%;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2rem;
  padding: 3.3rem ${props => (props.hasRightAddon ? 6.2 : 1.6)}rem 1.5rem 1.6rem;
  color: #ddd;
  border: 1px solid transparent;
  caret-color: ${theme.color.accent.primary};
  background: ${theme.color.neutral[900]};

  ::placeholder {
    opacity: 0;
  }

  &:hover {
    border: 1px solid ${theme.color.neutral[600]};
  }

  &:focus {
    border: 1px solid ${theme.color.neutral[200]};
    outline: none;
    box-shadow: none;
  }

  ${props =>
    props.variant === 'default' &&
    css`
      color-scheme: dark;
    `}

  ${props =>
    props.variant === 'inverted' &&
    css`
      color: ${theme.color.neutral[800]};
      border-color: ${theme.color.neutral[800]};
      background: ${theme.color.white};

      &:focus {
        border-color: ${theme.color.neutral[800]};
      }
    `}

  ${props =>
    props.variant === 'light' &&
    css`
      color: ${theme.color.neutral[800]};
      border: 1px solid transparent;
      background: #f4f4f5;

      &:hover,
      &:focus {
        border-color: ${theme.color.neutral[400]};
      }

      ${props.hasError &&
      css`
        &:hover,
        &:focus {
          outline: 3px solid ${theme.color.neutral[200]}88;
        }
      `}
    `}

  ${props =>
    props.hasError &&
    css`
      &,
      &:hover,
      &:focus {
        border-color: ${theme.color.accent.error};
      }
    `}
`

export const InputElement = styled.input<InputProps>`
  ${inputStyles};
`

export const Label = styled.label`
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;

  span {
    ${label.normal}
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: ${theme.color.neutral[200]};
    padding: 0 1.6rem;
    transform: translateY(2.8rem);
    transition: transform 0.1s, color 0.1s;
  }

  select + span,
  input:focus + span,
  input:not(:placeholder-shown) + span {
    transform: translateY(1.4rem);
    color: ${theme.color.neutral[400]};
    transition: transform 0.2s, color 0.2s;
  }
`

export const ErrorMessage = styled(ErrorMessageBase)`
  ${label.normal};
  text-transform: none;
  display: block;
  position: relative;
  padding-left: 2rem;
  margin: -0.8rem 0 2rem;
  line-height: 1.4;

  svg {
    position: absolute;
    display: block;
    left: 0;
    font-size: 1.6rem;
  }
`

export const SelectElement = styled.select<InputProps>`
  ${inputStyles};
`

export const Toggle = styled.button`
  ${styleReset};
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2.4rem;
  cursor: pointer;
`
