import { FC } from 'react'
import styled, { keyframes } from 'styled-components'
import { Svg } from '~/components/svg'
import { theme } from '~/styles/theme'

const AnimatedSvg = styled(Svg)`
  overflow: visible;
`

const XZoomAnimation = keyframes`
  from { transform: translate3d(0, 0, 0); }
  33%  { transform: scale(1.4); }
  to   { transform: translate3d(0, 0, 0); }
`

const XGroup = styled.g`
  transform: translate3d(0, 0, 0);
  transform-origin: 72% 20%;

  a:hover &,
  a:focus & {
    animation: 0.4s ${XZoomAnimation};
  }
`

export const BarrysXChavron: FC = props => (
  <AnimatedSvg {...props} viewBox="0 0 37 47">
    <g fill="currentColor">
      <path d="M14.8931 40.4988L13.7066 36.8474L12.5193 40.4988H8.68225L11.7885 42.7536L10.6012 46.4058L13.7066 44.1501L16.8129 46.4058L15.6256 42.7536L18.7327 40.4988H14.8931Z" />
      <path d="M11.2518 17.3865L19.0205 10.5066L13.5885 5.69067L0.556641 17.2296L0.558328 26.8259L11.2518 17.3865Z" />
      <path d="M19.0205 26.1128L13.5885 21.2969L0.556641 32.8358L0.557484 42.4321L11.2518 32.9919L19.0205 26.1128Z" />
      <path d="M14.2086 15.8802L26.7856 26.9795L26.7848 17.389L19.649 11.0635L14.2086 15.8802Z" />
      <path d="M19.6483 26.6687L14.2079 31.4863L26.7857 42.5864L26.784 32.9951L19.6483 26.6687Z" />
    </g>

    <XGroup fill={theme.color.accent.primary}>
      <path d="M27.6995 12.6026L30.1847 9.17655L24.2295 0.956543L19.2777 0.957387L24.1494 7.70233L27.6995 12.6026Z" />
      <path d="M27.7013 5.78931L30.1874 9.21536L24.2322 17.4354L19.2795 17.4345L24.1512 10.6896L27.7013 5.78931Z" />
      <path d="M27.7293 5.78931L25.2441 9.21536L31.1993 17.4354L36.1511 17.4345L31.2795 10.6896L27.7293 5.78931Z" />
      <path d="M27.7274 12.6026L25.2413 9.17655L31.1965 0.956543L36.1492 0.957387L31.2775 7.70233L27.7274 12.6026Z" />
    </XGroup>
  </AnimatedSvg>
)
