import { forwardRef, useMemo, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { useListRegionsQuery } from '~/@types/schemas'
import { HomeStudioModal } from './home-studio-modal'
import { Label, InputElement } from '~/components/form-field/styled'

type Props = ControllerRenderProps

export const HomeStudioPicker = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...props }, ref) => {
    const [isOpen, setOpen] = useState(false)
    const { data } = useListRegionsQuery()

    const displayValue = useMemo(() => {
      if (data) {
        const studios = data.listRegions.flatMap(r => r.locations)

        return studios.find(s => s.id === value)?.name
      }

      return ''
    }, [value, data])

    const handleChange = (newValue: string) => {
      onChange(newValue)
      setOpen(false)
    }

    return (
      <>
        <input
          type="hidden"
          {...props}
          value={value}
          onChange={onChange}
          ref={ref}
        />
        <Label onClick={() => setOpen(true)}>
          <InputElement
            readOnly
            value={displayValue ?? ''}
            placeholder="Home Studio"
          />
          <span>Home studio</span>
        </Label>
        <HomeStudioModal
          isOpen={isOpen}
          onChange={handleChange}
          onClose={() => setOpen(false)}
          data={data?.listRegions || []}
        />
      </>
    )
  }
)
