import { gql } from '@apollo/client'
import { Fragments } from './fragments'

export const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      ...UserFields
    }
  }
  ${Fragments.user}
`

export const GET_USER_STATS = gql`
  query GetUserStats($id: ID!) {
    getUserStats(userID: $id) {
      totalClasses
      upcomingClasses
    }
  }
`

export const LIST_CLASS_SESSIONS = gql`
  query ListClassSessions($input: ListClassSessionInput!) {
    listClassSessions(input: $input) {
      id
      name
      startDateTime
      openingDateTime
      duration
      participantsCount
      hasReservation
      participants {
        id
        pictureUrl
      }
      instructors {
        id
        pictureUrl
        firstName
        lastName
      }
      location {
        id
        name
      }
    }
  }
`

export const LIST_WORKOUT_SUMMARIES = gql`
  query ListWorkoutSummaries($input: ListWorkoutSummaryInput!) {
    listWorkoutSummaries(input: $input) {
      id
      createdAt
      totalTime
      activeCalories
      totalCalories
      heartRate
      maxHeartRate
      miles
      topMPH
      averageBPM
      bpmChart {
        timestamp
        value
      }
    }
  }
`

export const LIST_USER_MEMBERSHIPS = gql`
  query ListUserMemberships($page: Int, $size: Int = 100) {
    listUserMemberships(input: { page: $page, size: $size }) {
      ...MembershipFields
    }
  }
  ${Fragments.membership}
`

export const LIST_USER_PURCHASES = gql`
  query ListUserPurchases(
    $type: PurchaseTypeEnum! = SUBSCRIPTION
    $platform: PlatformEnum
  ) {
    listUserPurchases(input: { type: $type, platform: $platform }) {
      platform
    }
  }
`

export const LIST_USER_CLASS_SESSIONS = gql`
  query ListUserClassSessions(
    $page: Int
    $size: Int = 10
    $status: [ReservationStatusEnum!] = [CHECK_IN, PENDING, UPCOMING]
    $sortBy: ReservationSortingEnum! = CLASS_SESSION_START_DATETIME_ASC
  ) {
    listUserClassSessions(
      input: { page: $page, size: $size, status: $status, sortBy: $sortBy }
    ) {
      id
      name
      isDigital
      startDateTime
      openingDateTime
      duration
      participantsCount
      hasReservation
      activeReservation {
        roomIndex
      }
      participants {
        id
        pictureUrl
      }
      instructors {
        id
        pictureUrl
        firstName
        lastName
        region {
          id
          name
        }
      }
      location {
        id
        name
      }
    }
  }
`

export const LIST_INSTRUCTORS = gql`
  query ListInstructors($input: ListInstructorsInput_v2!) {
    listInstructors: listInstructors_v2(input: $input) {
      ...InstructorFields
    }
  }
  ${Fragments.instructor}
`

export const GET_INSTRUCTOR_DETAIL = gql`
  query GetInstructorDetail($id: ID!, $input: ListInstructorVodFiltersInput!) {
    getInstructor(id: $id) {
      ...InstructorFields
    }
    listInstructorVodFilters(input: $input) {
      classTypeFilters
    }
  }
  ${Fragments.instructor}
`

export const GET_LIVE_SESSION = gql`
  query GetLiveSession($id: ID!) {
    getLiveSession(input: { id: $id }) {
      id
      url
      status
      rooms {
        name
        roomIndex
      }
      classSession {
        id
      }
    }
  }
  ${Fragments.classSession}
  ${Fragments.user}
`

export const GET_CLASS_SESSION = gql`
  query GetClassSession($id: ID!) {
    getClassSession(id: $id) {
      id
      name
      startDateTime
      endDateTime
      spotsCount
      duration
      participantsCount
      hasReservation
      userRated
      activeReservationID
      moderatorID
      classroom {
        id
        name
      }
      region {
        id
        name
      }
      location {
        id
        name
      }
      instructors {
        ...InstructorFields
      }
      participants {
        ...UserFields
      }
      rooms {
        name
        roomIndex
        participants {
          id
        }
      }
      spotifyPlaylistIDs {
        playlistID
        imageURL
        title
      }
      appleMusicPlaylistIDs
    }
  }
  ${Fragments.instructor}
  ${Fragments.user}
`

export const LIST_VOD_FILTERS = gql`
  query ListVodFilters($category: LibraryItemCategoryEnum!) {
    listVodFilters(input: { category: $category }) {
      bodyParts {
        name
        type
      }
      bodyPartsFilters {
        name
        type
      }
      equipment {
        name
        type
        image
      }
      equipmentFilters_v2 {
        name
        type
        image
      }
      classTypeFilters
      labels {
        name
        type
      }
      types {
        name
        type
      }
      durations {
        name
        type
      }
      statuses {
        name
        type
      }
    }
  }
`

export const COUNT_LIBRARY_ITEMS = gql`
  query CountLibraryItems($input: ListLibraryItemsInput) {
    countLibraryItems(input: $input)
  }
`

export const LIST_LIBRARY_ITEMS = gql`
  query ListLibraryItems($input: ListLibraryItemsInput) {
    listLibraryItems(input: $input) {
      ...LibraryItems
      instructor {
        id
        firstName
        lastName
        pictureUrl
      }
    }
  }
  ${Fragments.library}
`

export const LIST_LIBRARY_ITEM_CATEGORIES = gql`
  query ListLibraryItemCategories(
    $input: GetLibraryItemCategoriesTotalInput
    $totalInput: GetLibraryItemCategoriesTotalInput
  ) {
    listLibraryItemCategories(input: $input) {
      name
      type
      verticalImage
      total: total_v2(input: $totalInput)
    }
  }
`

export const GET_LIBRARY_ITEM = gql`
  query GetLibraryItem($id: ID!) {
    getLibraryItem(id: $id) {
      id
      createdAt
      title
      description
      equipment: equipment_v2 {
        name
        type
        image
      }
      labels {
        name
        type
      }
      duration {
        name
        type
      }
      imageURL
      type
      isBookmarked
      instructor {
        ...InstructorFields
      }
      additionalInstructor {
        ...InstructorFields
      }
      spotifyPlaylistIDs {
        playlistID
        imageURL
        title
      }
      appleMusicPlaylistIDs
      viewed
      bodyweight
      userRated
      hasWorkoutSummary
    }
  }
  ${Fragments.instructor}
`

export const GET_FILE_URL = gql`
  query GetFileURL($input: GetFileURLInput_v3!) {
    getFileURL_v3(input: $input) {
      main
      instructor
      music
    }
  }
`

export const LIST_ON_DEMAND_COLLECTION_SECTIONS = gql`
  query ListOnDemandCollectionSections($input: PaginationInput) {
    listOnDemandCollectionSections {
      name
      order
      collections(input: $input) {
        id
        name
        imageURL
        collectionItemsCount
      }
    }
  }
`

export const GET_ON_DEMAND_COLLECTION = gql`
  query GetOnDemandCollection($id: ID!, $input: PaginationInput) {
    getOnDemandCollection(id: $id) {
      id
      name
      collectionItemsCount
      collectionItems(input: $input) {
        libraryItem {
          ...LibraryItems
        }
      }
    }
  }
  ${Fragments.library}
`

export const LIST_REGIONS = gql`
  query ListRegions($input: ListRegionsInput) {
    listRegions(input: $input) {
      id
      name
      country {
        code
        name
      }
      locations {
        id
        name
      }
    }
  }
`

export const LIST_ON_DEMAND_SECTIONS = gql`
  query ListOnDemandSections(
    $filter: OnDemandSectionFilter
    $input: PaginationInput
  ) {
    listOnDemandSections(input: { filter: $filter }) {
      id
      name
      category {
        name
        type
      }
      items(input: $input) {
        id
        libraryItem {
          ...LibraryItems
        }
      }
    }
  }
  ${Fragments.library}
`

export const LIST_BOOKMARKS = gql`
  query ListBookmarks($input: ListUserBookmarksInput) {
    listBookmarks(input: $input) {
      id
      createdAt
      libraryItem {
        ...LibraryItems
      }
      collection {
        id
        name
      }
    }
  }
  ${Fragments.library}
`

export const HAS_USER_UNLIMITED_MEMBERSHIP = gql`
  query HasUserUnlimitedMembership {
    hasUserUnlimitedMembership
  }
`

export const USERNAME_EXISTS = gql`
  query UsernameExists($username: String!) {
    usernameExists(username: $username)
  }
`

export const GET_SIGNED_URL = gql`
  query GetSignedUrl($options: GetSignedUrlInput!) {
    getSignedUrl(options: $options) {
      url
      publicUrl
      expiresAt
    }
  }
`

export const GET_GYMPASS_USER = gql`
  query GetGympassUserData($gympassUID: String!) {
    getGympassUserData(gympassUID: $gympassUID) {
      id
      createdAt
      gympassUID
      email
      firstName
      lastName
      origin
      userStatus
      countryCode
    }
  }
`

export const GET_USER_ATTENDANCE = gql`
  query GetUserAttendance($userID: ID!, $format: String!) {
    getUserAttendance(userID: $userID, format: $format) {
      type
      value
      entries {
        value
        count
      }
    }
  }
`

export const GET_PROFILE_USER_DATA = gql`
  query GetProfileUserData($userId: ID!) {
    getUser(id: $userId) {
      id
      firstName
      lastName
      username
      pictureUrl
      loyaltyTier
      bio
      friendsCount
      friendship {
        ...FriendshipFields
      }
      memberSince
      homeLocation {
        id
        name
      }
    }

    getUserStats(userID: $userId) {
      spentAtHome
      totalClasses
      upcomingClasses
      digitalClasses
      redRoomClasses
      studiosVisited {
        numVisited
        region {
          name
          location {
            name
          }
        }
      }
      favStudio {
        numVisited
        region {
          name
          location {
            name
          }
        }
      }
      longestStreak
      bestWeek
      favoriteDay
      favoriteTime
      vodClasses
      cardioVODClasses
      strengthVODClasses
      stretchVODClasses
      strengthCardioVODClasses
      workouts {
        name
        count
      }
      instructors {
        id
        firstName
        lastName
        pictureUrl
        lessonsCount
        location {
          id
          name
        }
      }
    }

    getUserAttendance(userID: $userId, format: "year") {
      type
      value
      entries {
        value
        count
      }
    }
  }
  ${Fragments.friendship}
`

export const LIST_FRIENDSHIPS = gql`
  query ListFriendships($input: ListFriendshipInput) {
    listFriendships_v2(input: $input) {
      ...FriendshipFields
    }
  }
  ${Fragments.friendship}
`
