import { FC } from 'react'
import { Layout } from '~/components/layout'
import { SEO } from '~/components/seo'
import { Container, Title, Details, Message } from './styled'

type Props = {
  message: string
}

export const AppError: FC<Props> = ({ message }) => (
  <Layout>
    <SEO title="Error" />
    <Container>
      <Title>Houston, we&rsquo;ve had a problem</Title>
      <Details>
        We have been notified about the error and will fix it asap.
      </Details>
      <Message>{message}</Message>
    </Container>
  </Layout>
)
