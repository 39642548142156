import { useMemo } from 'react'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  fromPromise,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { concatPagination } from '@apollo/client/utilities'
import { config } from '~/utils/config'
import {
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
} from '~/@types/schemas'
import { REFRESH_TOKEN } from './mutations'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

const getNewToken = async (tokenManager: TokenManager, refreshToken) => {
  const apolloClient: ApolloClient<NormalizedCacheObject> = initializeApollo(
    tokenManager,
    null
  )

  const response = await apolloClient.mutate<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >({
    mutation: REFRESH_TOKEN,
    variables: { refreshToken },
  })

  const { session } = response.data.refreshToken

  tokenManager.setAccessToken(session.accessToken)
  tokenManager.setRefreshToken(session.refreshToken)

  return session.accessToken
}

function createApolloClient(
  tokenManager: TokenManager
): ApolloClient<NormalizedCacheObject> {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions?.exception?.status) {
            case 401:
              {
                const { refreshToken } = tokenManager.get()

                if (refreshToken) {
                  return fromPromise(
                    getNewToken(tokenManager, refreshToken).catch(error => {
                      console.log('[Get new token error]:', error)
                      tokenManager.setAccessToken('')
                      tokenManager.setRefreshToken('')
                    })
                  ).flatMap(value => {
                    const oldHeaders = operation.getContext().headers

                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${value}`,
                      },
                    })

                    return forward(operation)
                  })
                }
              }

              break
            default:
              console.log('[Error]:', err.extensions)
          }
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        // if you would also like to retry automatically on
        // network errors, we recommend that you use
        // apollo-link-retry
      }
    }
  )

  const authLink = setContext((_, { headers }) => {
    const accessToken = tokenManager.get().accessToken

    if (!accessToken || headers?.['Authorization']) {
      return { headers }
    }

    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : ``,
      },
    }
  })

  const httpLink = createHttpLink({
    uri: config.apiUrl,
  })

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
            listReservations: concatPagination(),
            listFriendships_v2: concatPagination(),
          },
        },
      },
    }),

    link: ApolloLink.from([errorLink, authLink, httpLink]),
    ssrMode: typeof window === 'undefined',
  })
}

export function initializeApollo(
  tokenManager: TokenManager,
  initialState = null
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = createApolloClient(tokenManager)

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  return _apolloClient
}

export function useApollo(
  initialState: NormalizedCacheObject,
  tokenManager: TokenManager
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => {
    return initializeApollo(tokenManager, initialState)
  }, [initialState])

  return store
}

export function addApolloState(client, props) {
  if (props) {
    props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return props
}
