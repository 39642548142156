import { FC, SVGAttributes } from 'react'
import { Svg } from '~/components/svg'

export const IconError: FC<SVGAttributes<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      d="M2 20H22L12 3L2 20ZM12.9091 17.3158H11.0909V15.5263H12.9091V17.3158ZM12.9091 13.7368H11.0909V10.1579H12.9091V13.7368Z"
      fill="currentColor"
    />
  </Svg>
)
