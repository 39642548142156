import styled, { css } from 'styled-components'
import { theme } from '~/styles/theme'
import { mq } from '~/styles/mq'
import { Container } from '~/components/container'

export const Footer = styled.footer<{ inverted?: boolean; fluid?: boolean }>`
  margin-top: auto;
  color: ${theme.color.neutral[500]};
  font-size: 1.3rem;
  background: ${theme.color.background.light};

  ${props =>
    props.inverted &&
    css`
      color: #999;
      background-color: transparent;
    `}

  ${props =>
    props.fluid &&
    css`
      ${Container} {
        max-width: none;
      }
    `}
`

export const Nav = styled.nav`
  display: flex;
  position: relative;
  padding: 4.5rem 0;

  ${mq.smallOnly} {
    flex-direction: column;
    padding-left: 4rem;
  }

  svg {
    color: ${theme.color.text.base};
    font-size: 2rem;
    margin-right: 0.8rem;

    ${mq.smallOnly} {
      position: absolute;
      top: 50%;
      left: 0;
      font-size: 2.6rem;
      margin-top: -0.5em;
    }
  }

  ul {
    display: flex;

    ${mq.medium} {
      margin-left: auto;
    }
  }

  li {
    ${mq.smallOnly} {
      margin-right: 1.5em;
    }
    ${mq.medium} {
      margin-left: 2.5em;
    }
  }
`
