import { FC } from 'react'
import Head from 'next/head'
import { StructuredData } from '~/@types/data'

type Props = {
  description?: string
  keywords?: readonly string[]
  title?: string
  type?: string
  url?: string
  image?: string
  structuredData?: readonly StructuredData[]
}

export const SEO: FC<Props> = ({
  title,
  keywords = [],
  structuredData = [],
  type = 'website',
  image,
  // url,
  children,
}) => {
  const websiteSD: StructuredData = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    // url: config.siteUrl,
    name: title,
    alternateName: title,
  }

  return (
    <Head>
      <title>{title ? `${title} | Barry's X` : "Barry's X"}</title>
      <script type="application/ld+json">
        {JSON.stringify([...structuredData, websiteSD])}
      </script>
      <meta name="keywords" content={(keywords || []).join(', ')} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      {/* <meta property="og:url" content={url || config.siteUrl} /> */}
      <meta property="og:image" content={image} />
      {children}
    </Head>
  )
}
