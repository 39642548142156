import { FC, SVGAttributes } from 'react'
import { Svg } from '~/components/svg'

export const IconBarrysX: FC<
  SVGAttributes<SVGSVGElement> & { outline?: boolean }
> = ({ outline, ...props }) => (
  <Svg {...props} viewBox="0 0 910.502 163.447">
    <g transform="translate(-5.255)">
      <g transform="translate(-66 -314.156)">
        <path
          fill="currentColor"
          d="M71.255,472.866V319.971h85.16l20.378,20.888v41.993l-15.231,13.566,15.231,13.567v42.208l-20.378,20.673Zm29.388-90.66H147.19V348.828H100.643Zm0,61.8H147.19V410.631H100.643ZM205.536,399V360.456l52.34-46.3,21.451,19.382L248.652,361.1Zm43.116,24.55-43.116,37.9V423.121l52.34-46.3L279.327,396.2Zm29.6,30.148-12.227,9.045,4.505,14.858L258.3,468.558,245.649,477.6l4.934-14.858L238.141,453.7h15.445l4.719-14.643,4.719,14.643ZM281.9,335.476,310.43,361.1l.214,38.547-50.408-44.792Zm28.529,88.291V462.1l-50.194-44.36L281.9,398.357Zm113.474-11.2,20.807,60.3H414.895l-18.662-58.143h-26.6v58.143h-29.6V319.971H423.26l20.808,20.888V391.9Zm-9.224-63.741H369.634v37.254H414.68Zm142.432,63.741,20.593,60.3H547.889l-18.877-58.143h-26.6v58.143H473.026V319.971h83.229l20.807,20.888V391.9Zm-9.653-63.741H502.413v37.254h45.046Zm91.809,63.957L592.72,319.971h31.319l29.817,59.22,30.031-59.22h31.747l-46.763,92.383v60.512h-29.6Zm99.1-58.574H717.992l16.946-34.24H758.1Zm46.763,118.655-20.593-20.673V424.844h29.6V444.01h44.831V416.23L781.7,403.31l-17.161-18.52V340.859l20.593-20.888h62.635l20.807,20.888v26.919h-29.6v-18.95H794.143v27.994l57.487,13.136,16.946,18.089v44.146l-20.807,20.673Z"
        />
      </g>
      <g
        transform="translate(-66 -314.156)"
        {...(outline
          ? {
              fill: 'none',
              stroke: 'currentColor',
              strokeWidth: '5',
            }
          : {
              fill: 'currentColor',
            })}
      >
        <path
          d="M102.8,366.049l-15.19,20.968-21.6,0L87.254,357.6l4.714-6.506-4.724-6.52L66,315.159l21.6,0,15.19,20.968,15.19-20.968,21.6,0-21.244,29.414-4.714,6.507,4.724,6.52,21.244,29.415-21.6,0Z"
          transform="translate(842.174 44.793)"
        />
      </g>
    </g>
  </Svg>
)
