import { InputHTMLAttributes, forwardRef } from 'react'
import { Label, UI } from './styled'

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ className, type = 'radio', ...props }, ref) => (
    <Label className={className}>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <input type={type} {...props} ref={ref} />
      <UI />
    </Label>
  )
)
