import { FC } from 'react'
import { PageHeader } from './header'
import { Footer } from './footer'

export const Layout: FC<{
  invertedHeader?: boolean
  invertedFooter?: boolean
  isMenuHidden?: boolean
}> = ({ invertedHeader, invertedFooter, isMenuHidden, children }) => (
  <>
    <PageHeader inverted={invertedHeader} isMenuHidden={isMenuHidden} />

    {children}

    <Footer inverted={invertedFooter} />
  </>
)
