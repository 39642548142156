import { FC } from 'react'
import { Svg } from '~/components/svg'

export const EyeClosed: FC = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      d="M5.671 7.333 3.17 4.832 4.302 3.7 20.14 19.54l-1.132 1.131-2.648-2.648a8.758 8.758 0 0 1-4.706 1.363c-4.313 0-7.902-3.104-8.655-7.2a8.784 8.784 0 0 1 2.671-4.853Zm8.19 8.19-1.171-1.171a2.4 2.4 0 0 1-3.201-3.2L8.318 9.98a4 4 0 0 0 5.543 5.543Zm-5.427-9.93a8.791 8.791 0 0 1 3.221-.607c4.314 0 7.903 3.104 8.655 7.2a8.757 8.757 0 0 1-1.61 3.673l-3.087-3.088a4 4 0 0 0-4.544-4.544L8.434 5.594Z"
      fill="#B9B9C0"
    />
  </Svg>
)
