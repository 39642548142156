import { useRouter } from 'next/router'
import { FC, useState } from 'react'
import Link from 'next/link'
import { IconUser } from '~/components/icons/user'
import { useAppContext } from '~/context/app-context'
import { config } from '~/utils/config'
import { Container, Avatar, UserButton, Backdrop, Menu, Email } from './styled'

type Props = {
  inverted?: boolean
}

export const UserMenu: FC<Props> = ({ inverted }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { user, logout } = useAppContext()
  const router = useRouter()
  const name = `${user?.firstName} ${user?.lastName}`
  const toggleOpen = () => setIsOpen(v => !v)

  const handleLogout = () => {
    logout()
    router.asPath !== '/' && router.push('/')
  }

  return (
    <Container>
      <UserButton onClick={toggleOpen}>
        {user.pictureUrl ? (
          <Avatar src={user.pictureUrl} alt={name} />
        ) : (
          <IconUser />
        )}{' '}
        <span>
          {name}
          <Email inverted={inverted}>{user.email}</Email>
        </span>
      </UserButton>

      {isOpen && (
        <>
          <Menu>
            <li>
              <Link href="/profile/me" passHref legacyBehavior>
                <a>My Profile</a>
              </Link>
            </li>
            <li>
              <a href={config.membershipsLink} target="_blank" rel="noreferrer">
                Buy a membership
              </a>
            </li>
            <li>
              <Link href="/memberships" passHref legacyBehavior>
                <a>My memberships</a>
              </Link>
            </li>
            {config.settingsVisible && (
              <li>
                <Link href="/settings" passHref legacyBehavior>
                  <a>Settings</a>
                </Link>
              </li>
            )}
            <li>
              <button onClick={handleLogout}>Log out</button>
            </li>
          </Menu>
          <Backdrop onClick={() => setIsOpen(false)} />
        </>
      )}
    </Container>
  )
}
