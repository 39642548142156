import styled, { keyframes } from 'styled-components'
import { Svg } from 'components/svg'

const rotate = keyframes`
  to { transform: rotateZ(360deg); }
`

export const Loading = styled(Svg)`
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin: -0.5em;
  overflow: visible;
  animation: 1s ${rotate} linear infinite;
  font-size: 1.4em;
`

export const ContentWrapper = styled.span`
  display: block;
  position: relative;
`
