import { FC } from 'react'
import { Svg } from '~/components/svg'

export const ChevronRight: FC = props => (
  <Svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m10.207 17.71-1.414-1.414 4.293-4.293L8.793 7.71l1.414-1.414 5.707 5.707-5.707 5.707Z"
      fill="currentColor"
    />
  </Svg>
)
