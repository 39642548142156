import styled, { css } from 'styled-components'
import { mq, ScreenSize } from '~/styles/mq'

export const Container = styled.div<{ narrow?: boolean }>`
  width: 100%;
  max-width: ${ScreenSize.LG / 10}rem;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  ${mq.medium} {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  ${props =>
    props.narrow &&
    css`
      max-width: 83rem;
    `}
`
