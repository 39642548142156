import styled, { css } from 'styled-components'
import { Container } from '~/components/container'
import { BarrysXChavron as LogoBase } from '~/components/icons/barrysx-chevron'
import { mq } from '~/styles/mq'

export const Header = styled.header<{ inverted?: boolean }>`
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${mq.medium} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  ${Container} {
    display: flex;
    align-items: center;

    ${mq.smallOnly} {
      flex-wrap: wrap;
    }
  }

  ${props =>
    props.inverted &&
    css`
      color: white;
      background-color: black;
    `}
`

export const Logo = styled(LogoBase)`
  font-size: 3.5rem;
  height: auto;

  ${mq.medium} {
    font-size: 3.5rem;
  }
`

export const Nav = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;

  ul {
    margin-left: auto;
  }

  ${mq.smallOnly} {
    display: contents;

    ul {
      text-align: center;
      margin-top: 2rem;
      width: 100%;
      order: 10;
    }
  }
`

export const MenuItem = styled.li<{ isActive?: boolean }>`
  display: inline-block;
  margin: 0 4rem;

  ${mq.medium} {
    margin: 0 8rem 0 0;
  }

  ${mq.large} {
    margin-right: 12rem;
  }

  a {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 0.4em 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      width: 0;
      border-bottom: 2px solid currentColor;
      transition: width 0.4s;
    }

    &:hover::before {
      width: 100%;
      left: 0;
      right: auto;
    }

    ${props =>
      props.isActive &&
      css`
        &::before {
          width: 100%;
          left: 0;
          right: auto;
        }
      `}
  }
`
