import { FC, useEffect, useRef, useState } from 'react'
import { GetGympassUserDataQuery } from '~/@types/schemas'
import { SEO } from '~/components/seo'
import { DarkPageStyle } from '~/styles/misc'
import { delay } from '~/utils/helpers'
import { Header } from './header'
import { AuthContextProvider } from './auth-context'
import { Actions } from './actions'
import { AuthState } from './types'
import { Logo, Barrys, View, BAHIcon, Video, AtHome, Footer } from './styled'

type Props = {
  gympassData?: GetGympassUserDataQuery
  defaultForm?: AuthState
}

export const Home: FC<Props> = ({ gympassData, defaultForm }) => {
  const [mounted, setMounted] = useState(false)
  const [hasAnimationEnded, setAnimationEnded] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const [isLoginVisible, setIsLoginVisible] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const isVideoVisible = hasAnimationEnded && canPlay
  const handleCanPlay = () => setCanPlay(true)

  const videoLoadTimeout = async () => {
    await delay(5000)

    if (!canPlay) {
      setCanPlay(true)
    }
  }

  const handleAnimationEnd = () => setAnimationEnded(true)

  useEffect(() => {
    setMounted(true)
    void videoLoadTimeout()
  }, [])

  useEffect(() => {
    if (isVideoVisible) {
      videoRef.current.currentTime = 0
      void videoRef?.current.play()

      setTimeout(() => {
        setIsLoginVisible(true)
      }, 1300)
    }
  }, [isVideoVisible])

  return (
    <AuthContextProvider gympassData={gympassData} defaultForm={defaultForm}>
      {mounted && (
        <Video
          ref={videoRef}
          isVisible={isVideoVisible}
          onCanPlayThrough={handleCanPlay}
          preload="auto"
          autoPlay
          playsInline
          muted
          loop
        >
          <source src="/media/home-intro.mp4" />
        </Video>
      )}

      <View isLoginVisible={isLoginVisible}>
        <DarkPageStyle />
        <SEO />

        {isLoginVisible && <Header isGympass={!!gympassData} />}

        <Logo isLoginVisible={isLoginVisible} isVideoVisible={isVideoVisible}>
          <Barrys>
            <BAHIcon aria-label="Barry's X logo" />
          </Barrys>
          <AtHome onAnimationEnd={handleAnimationEnd}>
            <BAHIcon aria-hidden />
          </AtHome>
        </Logo>

        {isLoginVisible && (
          <>
            <Actions />
            <Footer inverted fluid />
          </>
        )}
      </View>
    </AuthContextProvider>
  )
}
