import { FC, MouseEventHandler } from 'react'
import { CSSTransition } from 'react-transition-group'
import { ModalBody, CloseButton, CloseIcon, Container, Overlay } from './styled'

export type ModalProps = {
  isOpen: boolean
  fullscreen?: boolean
  onClose?: MouseEventHandler<HTMLButtonElement>
  ModalBodyComponent?: typeof ModalBody
  containerProps?: {
    animation: 'slide' | 'grow'
  }
}

export const Modal: FC<ModalProps> = ({
  children,
  isOpen,
  onClose,
  fullscreen,
  ModalBodyComponent = ModalBody,
  containerProps = {},
  ...props
}) => {
  return (
    <>
      <CSSTransition timeout={600} in={isOpen} unmountOnExit>
        <Overlay {...props} />
      </CSSTransition>

      <CSSTransition timeout={600} in={isOpen} unmountOnExit>
        <Container {...props} {...containerProps} role="dialog">
          <ModalBodyComponent fullscreen={fullscreen}>
            {!!onClose && (
              <CloseButton onClick={onClose}>
                <CloseIcon />
              </CloseButton>
            )}
            {children}
          </ModalBodyComponent>
        </Container>
      </CSSTransition>
    </>
  )
}
