import { useState } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import {
  GetGympassUserDataQuery,
  GympassEventEnum,
  useGetUserLazyQuery,
  useSignInMutation,
} from '~/@types/schemas'
import { strings } from '~/lang/en'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Input } from '~/components/form-field/input'
import { useAppContext } from '~/context/app-context'
import { useGympassReporting } from '~/hooks/useGympassReporting'
import {
  Form,
  FormWrapper,
  Heading,
  Inner,
  Description,
  ForgotPasswordLink,
  ButtonLink,
} from '../styled'

type Values = {
  email: string
  password: string
}

type Props = {
  onForgotPassword: () => void
  onSignup: () => void
  gympassData?: GetGympassUserDataQuery
}

export const LoginForm = ({
  onForgotPassword,
  onSignup,
  gympassData,
}: Props) => {
  const { login } = useAppContext()
  const { reportEvent } = useGympassReporting()
  const router = useRouter()

  const { register, handleSubmit, formState, setError } = useForm<Values>({
    mode: 'onChange',
    defaultValues: {
      email: gympassData?.getGympassUserData.email || '',
      password: '',
    },
  })

  const [isLoading, setIsLoading] = useState(false)
  const [signIn] = useSignInMutation()
  const [getUserData] = useGetUserLazyQuery({ fetchPolicy: 'network-only' })

  const onSubmit = async ({ email, password }: Values) => {
    setIsLoading(true)

    try {
      const {
        data: {
          signIn: { session, ...data },
        },
      } = await signIn({
        variables: { username: email, password },
      })

      let user = data.user

      if (!user.email) {
        try {
          const {
            data: { getUser },
          } = await getUserData({
            variables: { id: user.id },
            context: {
              headers: {
                Authorization: `Bearer ${session.accessToken}`,
              },
            },
          })

          user = getUser
        } catch (_err) {
          console.log('error fetching additional user information')
        }
      }

      login({ session, user })

      reportEvent(
        GympassEventEnum.Signin,
        !!user?.gympassUID,
        session.accessToken
      )

      user?.username
        ? void router.push('/dashboard')
        : void router.push('/onboarding')
    } catch (err) {
      setError('email', { message: err.message })
      setError('password', { message: err.message })
    }

    setIsLoading(false)
  }

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Heading>{strings.login.form_title}</Heading>

        <Inner>
          <Description>
            <p>
              Log in using the email address attached to your existing Barry’s
              account.
            </p>
            <p>
              Not part of the Barry’s Fam yet?
              <br />
              <ButtonLink type="button" onClick={onSignup}>
                Sign up here
              </ButtonLink>
            </p>
          </Description>

          <Input
            label="Email"
            type="email"
            error={formState.errors.email?.message}
            {...register('email', { required: true })}
            hideErrorMessage
          />
          <Input
            label="Password"
            type="password"
            error={formState.errors.password?.message}
            {...register('password', { required: true })}
          />

          <ForgotPasswordLink type="button" onClick={onForgotPassword}>
            {strings.login.forgot_password.cta}
          </ForgotPasswordLink>
        </Inner>

        <ButtonWithLoading
          inverted
          block
          isLoading={isLoading}
          disabled={!formState.isValid}
        >
          {strings.login.login_button}
        </ButtonWithLoading>
      </Form>
    </FormWrapper>
  )
}
