import { useForm } from 'react-hook-form'
import { Input } from '~/components/form-field/input'
import { Checkbox } from '~/components/form-elements/checkbox'
import { ButtonWithLoading } from '~/components/button/with-loading'
import { Button } from '~/components/button'
import { IconArrowLeft } from '~/components/icons/arrow-left'
import { strings } from '~/lang/en'
import { Form, Heading, Title, CheckboxLabel } from '../styled'
import { ButtonWrapper } from './styled'
import { AccountValues } from './types'

type Props = {
  onSubmit: (values: AccountValues) => void
  initialValues?: AccountValues
  onBack: (values: AccountValues) => void
  isLoading: boolean
}

export const AccountForm = ({
  onSubmit,
  initialValues,
  onBack,
  isLoading,
}: Props) => {
  const { register, formState, handleSubmit, getValues } =
    useForm<AccountValues>({
      mode: 'onChange',
      defaultValues: initialValues,
    })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Sign Up</Heading>
      <Title>Account</Title>
      <Input
        label="Email"
        type="email"
        autoComplete="email"
        error={formState.errors.email?.message}
        {...register('email', { required: true })}
      />
      <Input
        label="Password (8+ characters)"
        type="password"
        autoComplete="new-password"
        error={formState.errors.password?.message}
        {...register('password', { required: true, minLength: 8 })}
      />
      <CheckboxLabel>
        <Checkbox {...register('isMarketingOptIn')} />
        <span>Subscribe to our newsletter and promotions</span>
      </CheckboxLabel>
      <CheckboxLabel>
        <Checkbox {...register('isWaiverSigned', { required: true })} />
        <span>
          I agree to the{' '}
          <a
            href="https://www.barrys.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {strings.app.footer.terms}
          </a>{' '}
          &{' '}
          <a
            href="https://barrys.brandbot.io/waivers/covid-19-waiver-KvErVN.html?email=shayme@gmail.com&mc_cid=1cd1793ad8&mc_eid=05e734682e"
            target="_blank"
            rel="noopener noreferrer"
          >
            Waiver
          </a>
        </span>
      </CheckboxLabel>
      <ButtonWrapper>
        <Button type="button" outline onClick={() => onBack(getValues())}>
          <IconArrowLeft />
        </Button>
        <ButtonWithLoading
          inverted
          block
          isLoading={isLoading}
          disabled={!formState.isValid}
        >
          Create account
        </ButtonWithLoading>
      </ButtonWrapper>
    </Form>
  )
}
